import React, { Component } from 'react'
import useFeatureFlag from '../../../../hooks/useFeatureFlag'
import CallToActionBtns from './CallToActionBtns'
import ArrowRightIcon from '../../../../components/icon/ArrowRightIcon'

interface ActionBtnProp {
  title: string
  isProcessing?: boolean
  disabled?: boolean
  onPress: Function
  testID?: string
  icon?: Component
  iconContainerStyle?: object
  iconRight?: boolean
  animated?: boolean
}
interface CallToActionProp {
  primaryBtn: ActionBtnProp
  secondaryBtn: ActionBtnProp
  hasIcon: boolean
}

const CustomCallToActionBtns = ({
  secondaryBtn,
  primaryBtn,
  hasIcon
}: CallToActionProp) => {
  const rebrand = useFeatureFlag('innovationRebrandPlatform')

  const rebrandPrimaryBtn = {
    ...primaryBtn,
    icon: hasIcon && <ArrowRightIcon size={13} />,
    iconContainerStyle: hasIcon && {
      marginLeft: 10,
      position: 'initial'
    },
    iconRight: hasIcon,
    animated: primaryBtn.animated
  }

  return rebrand ? (
    <CallToActionBtns
      secondaryBtn={rebrandPrimaryBtn}
      primaryBtn={secondaryBtn}
    />
  ) : (
    <CallToActionBtns secondaryBtn={secondaryBtn} primaryBtn={primaryBtn} />
  )
}

export default CustomCallToActionBtns
