import React, { useState, useEffect, useRef } from 'react'

import styled from 'styled-components/native'
// Hooks
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'
// Components
import { DynamicTableComponentProps } from './constants'
import DynamicTableHeader from './DynamicTableHeader'
import DynamicTableItem from './DynamicTableItem'
import { FlatTableContainer, StyledFlatList } from './SharedStyledComponents'
import { LoadingIndicator } from '../../../common/LoadingIndicator'

// Styled components
const InterceptorContainer = styled.View``

function DynamicTableComponent<T>({
  width: tableWidth,
  data,
  keyExtractor,
  config,
  loading,
  leftTable,
  singleTable,
  pageSize,
  selectCurrent,
  setSelectAll,
  setSelectCurrent,
  handleSelectAll,
  handleSelectCurrent,
  fetchMoreResults,
  ...rest
}: DynamicTableComponentProps<T>) {
  // Refs
  const observer = useRef(
    new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setAllowFetchMore(true)
      }
    })
  )

  // States
  const [lastElement, setLastElement] = useState<HTMLElement | null>(null)
  const [allowFetchMore, setAllowFetchMore] = useState<boolean>(true)

  // Hooks
  const isSmallScreen = useIsSmallScreen()

  // Effects
  useEffect(() => {
    const currentElement = lastElement
    const currentObserver = observer.current

    if (currentElement) {
      currentObserver.observe(currentElement)
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement)
      }
    }
  }, [lastElement])

  useEffect(() => {
    if (allowFetchMore) {
      fetchMoreResults?.()
      setAllowFetchMore(false)
    }
  }, [allowFetchMore])

  // Render
  let { name, isSticky, enableSelectAllElements = false } = config

  const dynamicTableHeaderProps = {
    tableWidth,
    config,
    leftTable,
    isSticky,
    singleTable,
    enableSelectAllElements,
    selectCurrent,
    handleSelectAll,
    handleSelectCurrent,
    ...rest
  }

  const dynamicTableItemProps = {
    tableWidth,
    leftTable,
    config,
    setSelectAll,
    setSelectCurrent,
    ...rest
  }

  const headerComponentStyles = isSticky
    ? {
        position: 'sticky',
        top: 0,
        zIndex: 2,
        background: '#FFFFFF'
      }
    : {}

  return (
    <FlatTableContainer width={tableWidth}>
      <StyledFlatList
        key={name}
        data={data}
        isSticky={isSticky}
        keyExtractor={keyExtractor}
        ListEmptyComponent={loading && leftTable && <LoadingIndicator />}
        ListHeaderComponent={
          !isSmallScreen && <DynamicTableHeader {...dynamicTableHeaderProps} />
        }
        ListHeaderComponentStyle={headerComponentStyles}
        renderItem={({ item, index }) => {
          const defaultProps = {
            item,
            index,
            ...dynamicTableItemProps
          }
          if (data && index === data?.length - 1 && !loading) {
            return (
              <InterceptorContainer index={index} ref={setLastElement}>
                <DynamicTableItem {...defaultProps} />
              </InterceptorContainer>
            )
          }
          return <DynamicTableItem {...defaultProps} />
        }}
        initialNumToRender={pageSize - 1}
      />
    </FlatTableContainer>
  )
}

export default DynamicTableComponent
