import { atom } from 'recoil'

// Types
export interface UnsavedAdvisorChangesAtomData {
  dialog?: boolean
  shouldInitForm?: boolean
  unsavedValues?: any | null
  onNavigate?: Function | null
}

export const unsavedAdvisorChangesAtom = atom<UnsavedAdvisorChangesAtomData>({
  key: 'unsavedAdvisorChangesDataState',
  default: {
    dialog: false,
    unsavedValues: null,
    onNavigate: null,
    shouldInitForm: false
  }
})

export const hasUnsavedChangesAtom = atom<boolean>({
  key: 'hasUnsavedAdvisorChangesBoolState',
  default: false
})
