import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { ThemeContext } from 'styled-components/native'
import { isEmpty, clone } from 'ramda'

import useTranslation from '../../../hooks/useTranslation'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import useMixpanel, { EVENT_MAP } from '../../../hooks/useMixpanel'
import useLinkToScreen from '../../../hooks/useLinkToScreen'
import { ButtonConfig } from '../layout'
import SubmitDialog from '../../common/Dialog/SubmitDialog'
import { FormikValues } from 'formik'
import { Flex } from '../../FlexBox'
import GroupButtons from './GroupButtons'
import Button from '../../../ui-library/Button'
import { DynamicFormHelpers } from '../../../hooks/useDynamicForm'
import { ValidationPhaseEnum } from '../../../types/form'
import useToast from '../../../hooks/useToast'
import useSubmissionQuery, {
  submissionQuery
} from '../../../screens/Submission/hooks/useSubmissionQuery'
import useUpdateSubmissionStatusMutation from '../../../screens/Submission/hooks/useUpdateSubmissionStatusMutation'
import { InnovationSubmissionStatusEnum } from '../../../types'
import DeleteDialog from '../../common/Dialog/DeleteDialog'
import EditDialog from '../../common/Dialog/EditDialog'
import { SubmissionClosingInfo } from '../../common/SubmissionClosinInfo'
import useUpsertInnovatorProductPublishLog from '../../../screens/InnovatorDirectory/hooks/useUpsertInnovatorProductPublishLog'
import useUpsertInnovatorProduct from '../../../screens/Submission/hooks/useUpsertInnovatorProduct'

const AUTO_SAVE_TIMEOUT = 700

interface FormButtonsProps {
  form: FormikValues
  formHelpers: DynamicFormHelpers
  innovationSubmissionId: string
  innovatorProductId?: string
  delegationId?: string
  isSubmission: boolean
  showCloseBtn?: boolean
  onClose?: () => void
  onCountdownStop?: () => void
  keepCountdown: boolean
  isSmallScreen: boolean
  disableSubmitButton?: boolean
  hasBeenSubmitted: boolean
  hasBeenPublished?: boolean
  canEdit?: boolean
  canSubmit?: boolean
  canDelete?: boolean
  canPublish?: boolean
  setIsProductPublished?: React.Dispatch<React.SetStateAction<boolean>>
}

let debouncedSendRequest

const FormButtons: FC<FormButtonsProps> = ({
  form,
  formHelpers,
  innovationSubmissionId,
  innovatorProductId,
  delegationId,
  isSubmission,
  showCloseBtn = false,
  disableSubmitButton = false,
  onClose = undefined,
  isSmallScreen,
  hasBeenSubmitted,
  hasBeenPublished,
  canEdit,
  canSubmit,
  canDelete,
  canPublish,
  onCountdownStop,
  keepCountdown,
  setIsProductPublished
}) => {
  const { t } = useTranslation()

  const { updateInnovatorProduct } = useUpsertInnovatorProduct()
  const { trackWithProperties } = useMixpanel()
  const { setToastErrorMessage, setToastMessage } = useToast()
  const { submission, hasChanged } = useSubmissionQuery(innovationSubmissionId)
  const {
    upsertInnovatorProductPublishLog,
    loading: loadingUpsertInnovatorProductPublishLog
  } = useUpsertInnovatorProductPublishLog()

  const theme = useContext(ThemeContext) as any
  const linkToScreen = useLinkToScreen()
  const isCountdownClockEnabled = useFeatureFlag(
    'innovationSubmissionCountdownClock'
  )
  const rebrand = useFeatureFlag('innovationRebrandPlatform')

  const errorMessage = t('error:save:validation')

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isAutoSaving, setIsAutoSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)

  const [
    updateInnovationSubmissionStatus,
    loadingUpdateInnovationSubmissionStatus
  ] = useUpdateSubmissionStatusMutation({
    refetchQueries: ['innovationSubmission'],
    update: (cache, { data }) => {
      const queryVariables = {
        id: innovationSubmissionId,
        withSubmissionReviewsAverages: false
      }
      const query: any = cache.readQuery({
        query: submissionQuery,
        variables: queryVariables
      })
      const queryData = clone(query)
      cache.writeQuery({
        query: submissionQuery,
        variables: queryVariables,
        data: {
          ...queryData,
          innovationSubmission: {
            ...queryData?.innovationSubmission,
            hasBeenSubmitted:
              data?.updateInnovationSubmissionStatus?.hasBeenSubmitted,
            step: data?.updateInnovationSubmissionStatus?.step,
            status: data?.updateInnovationSubmissionStatus?.status
          }
        }
      })
    }
  })

  const closeDialog = useCallback(() => {
    setIsSubmitting(false)
    setOpenDialog(false)
  }, [])

  const validateForm = async (
    phase,
    autoSave = false,
    field = '',
    resetAllTouched = false
  ) => {
    if (resetAllTouched) {
      formHelpers.setAllFieldsTouched()
    }
    formHelpers.setCurrentPhase(phase)

    const values = autoSave
      ? { questions: { [field]: form.values.questions[field] } }
      : { ...form.values }

    const errors = await form.validateForm({ phase, autoSave, ...values })

    if (!isEmpty(errors) && !autoSave) {
      setToastErrorMessage(errorMessage)
    }

    return errors
  }

  const validateFormByFields = useCallback(
    async (phase, autoSave = false, fields: string[]) => {
      if (!autoSave) {
        formHelpers.setAllFieldsTouched()
      }
      formHelpers.setCurrentPhase(phase)

      let values = {}

      if (autoSave) {
        values['questions'] = {}
        for (const field of fields) {
          values['questions'] = {
            ...values['questions'],
            [field]: form.values.questions[field]
          }
        }
      } else {
        values = { ...form.values }
      }

      const errors = await form.validateForm({ phase, autoSave, ...values })

      if (!isEmpty(errors) && !autoSave) {
        setToastErrorMessage(errorMessage)
      }

      return errors
    },
    [form, form.values, formHelpers]
  )

  const saveForm = async (validate = true) => {
    clearTimeout(debouncedSendRequest)
    setIsSaving(true)

    trackWithProperties(EVENT_MAP.click.button, {
      category: 'submission',
      action: 'save'
    })

    const touchedFields: string[] = Object.keys(form.touched?.questions ?? {})

    if (form?.dirty && touchedFields.length > 0) {
      const errors = validate
        ? await validateForm(ValidationPhaseEnum.Save, false, '', true)
        : {}

      if (isEmpty(errors)) {
        await form.submitForm()
      }
    }
    setIsSaving(false)
  }

  const submitForm = async () => {
    clearTimeout(debouncedSendRequest)
    setIsSubmitting(true)

    await saveForm()

    const errors = await validateForm(ValidationPhaseEnum.Submit)

    setIsSubmitting(false)

    if (isEmpty(errors)) {
      trackWithProperties(EVENT_MAP.click.button, {
        category: 'submission',
        action: 'submit'
      })

      if (!openDialog) {
        setOpenDialog(true)
      }
    }
  }

  const publishProduct = async () => {
    clearTimeout(debouncedSendRequest)
    if (!innovatorProductId) return
    if (!hasBeenPublished) {
      setIsSubmitting(true)
      const errors = await validateForm(ValidationPhaseEnum.Publish)
      setIsSubmitting(false)
      if (!isEmpty(errors)) return
      await saveForm(false)
      trackWithProperties(
        !hasBeenPublished
          ? `${EVENT_MAP.click.button}: Publish Product`
          : `${EVENT_MAP.click.button}: Un-Publish Product`,
        {
          category: 'innovatorProduct',
          action: !hasBeenPublished ? 'Publish Product' : 'Un-Publish Product'
        }
      )
    }
    const { errors } = await upsertInnovatorProductPublishLog(
      innovationSubmissionId,
      innovatorProductId,
      !hasBeenPublished
    )
    if (errors) {
      setToastErrorMessage(t('innovatorDirectory:actions:error'))
    } else {
      formHelpers.resetAllTouchFields()
      await updateInnovatorProduct(submission.id, false, ['company'])
      setIsProductPublished && setIsProductPublished(!hasBeenPublished)
      setToastMessage(
        t(
          !hasBeenPublished
            ? 'innovatorDirectory:actions:success'
            : 'innovatorDirectory:actions:successUnPublish'
        )
      )
    }
  }

  const handleEditSubmission = async () => {
    trackWithProperties(EVENT_MAP.click.button, {
      category: isSubmission ? 'submission' : 'product',
      action: 'edit'
    })
    setIsSubmitting(true)
    await updateInnovationSubmissionStatus(
      innovationSubmissionId,
      InnovationSubmissionStatusEnum.inProgress,
      true
    )
    setIsSubmitting(false)
  }

  const editSubmission = async () => {
    setOpenEditDialog(!openEditDialog)
  }

  const deleteSubmission = async () => {
    setOpenDeleteDialog(!openDeleteDialog)
  }

  const touchedFields = useMemo(
    () => Object.keys(form.touched?.questions ?? {}),
    [form?.touched]
  )

  useEffect(() => {
    const sendRequest = async () => {
      const touchedFields: string[] = Object.keys(form.touched?.questions ?? {})
      if (touchedFields.length > 0 && form?.dirty && canEdit) {
        setIsAutoSaving(false)
        const errors = await validateFormByFields(
          ValidationPhaseEnum.Save,
          true,
          touchedFields
        )
        if (isEmpty(errors)) {
          let values = {
            questions: {}
          }

          for (const field of touchedFields) {
            values['questions'] = {
              ...values['questions'],
              [field]: form.values.questions[field]
            }
          }

          await formHelpers.saveFields(touchedFields, values)
          setIsAutoSaving(true)
        }
        formHelpers.resetAllTouchFields()
      }
    }

    if (form?.dirty && touchedFields.length > 0 && form?.values) {
      debouncedSendRequest = setTimeout(sendRequest, AUTO_SAVE_TIMEOUT)
    }

    return () => clearTimeout(debouncedSendRequest)
  }, [
    form?.dirty,
    touchedFields,
    form?.values,
    form.initialValues,
    form.values,
    canEdit
  ])

  const saveBtnConfig = {
    title: isSaving
      ? t('product:general:saving')
      : isSmallScreen
      ? t('product:general:button')
      : isAutoSaving
      ? t('product:general:autoSaveProgress')
      : t('product:general:saveProgress'),
    onPress: saveForm,
    isProcessing: isSaving,
    isDisabled: isSaving || hasChanged || isAutoSaving,
    type: rebrand ? 'solid' : 'outline',
    iconStyle: { color: '#006890' }
  }

  const submitBtnConfig = {
    title: t('product:general:submit'),
    onPress: submitForm,
    isProcessing: isSubmitting,
    isDisabled: isSubmitting || hasChanged || disableSubmitButton
  }

  const publishBtnConfig = {
    title: t(
      hasBeenPublished
        ? 'innovatorDirectory:actions:unPublishProduct'
        : 'innovatorDirectory:actions:publishProduct'
    ),
    onPress: publishProduct,
    loading: loadingUpsertInnovatorProductPublishLog,
    isProcessing: isSubmitting || loadingUpsertInnovatorProductPublishLog,
    isDisabled: isSubmitting || loadingUpsertInnovatorProductPublishLog,
    buttonStyle: {
      borderWidth: 1,
      borderColor: hasBeenPublished
        ? theme.colors.cta.primary
        : theme.colors.success,
      backgroundColor: rebrand
        ? theme.colors.containerBackground
        : 'transparent'
    },
    hoverStyles: {
      iconStyle: {
        color: hasBeenPublished
          ? theme.colors.cta.primary
          : rebrand
          ? theme.colors.successHover
          : theme.colors.primaryPalette.white
      },
      buttonStyle: {
        backgroundColor: hasBeenPublished
          ? theme.colors.primaryPalette.black
          : theme.colors.success,
        borderColor: hasBeenPublished
          ? theme.colors.primaryPalette.black
          : theme.colors.success
      },
      titleStyle: {
        color: theme.colors.primaryPalette.white
      }
    },
    titleStyle: {
      textTransform: 'capitalize',
      color: hasBeenPublished ? theme.colors.cta.primary : theme.colors.success
    }
  }

  const handleClose = useCallback(() => {
    trackWithProperties(EVENT_MAP.click.button, {
      category: isSubmission ? 'submission' : 'product',
      action: 'close'
    })
    linkToScreen('SubmissionList')
  }, [])

  const editButton = {
    title: t('product:general:editSubmission'),
    onPress: editSubmission,
    isDisabled:
      (isSubmitting || loadingUpdateInnovationSubmissionStatus) ?? false,
    type: 'outline' as 'outline'
  }

  const deleteButton = {
    title: t('product:general:deleteSubmission'),
    onPress: deleteSubmission,
    isProcessing:
      (isSubmitting ||
        loadingUpdateInnovationSubmissionStatus ||
        loadingUpsertInnovatorProductPublishLog) ??
      false,
    isDisabled:
      (isSubmitting ||
        loadingUpdateInnovationSubmissionStatus ||
        loadingUpsertInnovatorProductPublishLog) ??
      false,
    type: 'solid' as 'solid',
    buttonStyle: { backgroundColor: theme.colors.danger }
  }

  const submittedButton = {
    title: hasBeenSubmitted
      ? t('submissions:status:submitted')
      : t('product:general:submit'),
    onPress: () => {},
    isDisabled: true
  }

  const footerButtons: ButtonConfig[] = []

  if (hasBeenSubmitted) {
    footerButtons.push(submittedButton)

    if (canEdit) {
      footerButtons.push(editButton)
    }
  } else {
    if (canEdit) {
      footerButtons.push(saveBtnConfig as any)
    }
    if (canSubmit) {
      footerButtons.push(submitBtnConfig)
    }
  }
  if (canDelete) {
    footerButtons.push(deleteButton)
  }
  if (canPublish && innovatorProductId) {
    footerButtons.push(publishBtnConfig)
  }

  let buttonRowProps = {
    buttons: footerButtons
  }
  let leftButtonProps

  if (showCloseBtn || isSubmission) {
    leftButtonProps = {
      title: t('common:buttons:close'),
      onPress: onClose || handleClose,
      isProcessing: false,
      disabled: false,
      containerStyle: {
        width: isSmallScreen ? '100px' : '178px',
        ...(isSmallScreen ? { padding: 1 } : {}),
        marginTop: isSmallScreen ? 0 : 15,
        marginRight: isSmallScreen ? 10 : 0
      },
      buttonStyle: { width: '100%', borderColor: theme.colors.buttonVariant },
      titleStyle: {
        color: theme.colors.buttonVariant
      }
    }
  }

  return (
    <>
      <Flex
        flexDirection={isSmallScreen ? 'row' : 'column'}
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="center"
        w="100%"
      >
        {isSmallScreen ? (
          <>
            {showCloseBtn || isSubmission ? (
              <Button
                type="outline"
                {...leftButtonProps}
                accessibilityLabel={'endOfScreen'}
              />
            ) : null}
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              flex={1}
              justifyContent="space-between"
            >
              <GroupButtons {...buttonRowProps} />
            </Flex>
          </>
        ) : (
          <GroupButtons {...buttonRowProps} />
        )}
        {isCountdownClockEnabled && (
          <SubmissionClosingInfo
            onCountdownStop={onCountdownStop}
            cfs={submission?.callForSubmission}
            keepCountdown={keepCountdown}
          />
        )}
      </Flex>
      {(showCloseBtn || isSubmission) && !isSmallScreen && (
        <Flex
          flexDirection="row"
          marginTop="auto"
          marginLeft="auto"
          marginRight="auto"
        >
          <Button
            type="outline"
            {...leftButtonProps}
            accessibilityLabel={'endOfScreen'}
          />
        </Flex>
      )}
      {canEdit && (
        <EditDialog
          handleEditSubmission={handleEditSubmission}
          isSubmission={isSubmission}
          loading={
            loadingUpdateInnovationSubmissionStatus ||
            loadingUpsertInnovatorProductPublishLog
          }
          openDialog={openEditDialog}
          triggerClose={() => setOpenEditDialog(false)}
        />
      )}
      {canSubmit && (
        <SubmitDialog
          innovationSubmissionId={innovationSubmissionId}
          delegationId={delegationId}
          callForSubmissionId={submission?.callForSubmissionId}
          openDialog={openDialog}
          triggerClose={closeDialog}
        />
      )}
      {canDelete && (
        <DeleteDialog
          innovationSubmissionId={innovationSubmissionId}
          callForSubmissionId={submission?.callForSubmissionId}
          openDialog={openDeleteDialog}
          triggerClose={() => setOpenDeleteDialog(false)}
        />
      )}
    </>
  )
}

export default FormButtons
