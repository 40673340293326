import React, { useEffect, useState, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components/native'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import useTranslation from '../../hooks/useTranslation'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import useHasPermission from '../../hooks/useHasPermission'
import { CellRendererProps, TableConfig } from '../../components/Table'
import TitleCell, {
  LabelAndTitleCell,
  SubtitleCell
} from '../../components/DynamicTable/components/Table/Item/common/TitleCellContent'
import { AlternatingHoverableRow } from '../../components/Table/components/DynamicTable/SharedStyledComponents'
import { SmallRow } from '../../components/Table/components/SharedStyledComponents'
import Button from '../../ui-library/Button'
import { localTimezone } from '../../ui-library/DateInput/utils'
import {
  CallForSubmission,
  CallForSubmissionStatusEnum,
  CallForSubmissionStepEnum,
  CallForSubmissionStepStatusEnum,
  RoleEnum
} from '../../types'
import ArrowLeftIcon from '../../components/icon/ArrowLeftIcon'
import useMixpanel, { EVENT_MAP } from '../../hooks/useMixpanel'
import { CURATION_PERMISSIONS } from '../../constants/roles'
import TouchableIcon from '../../components/icon/TouchableIcon'
import useMarketSegmentsQuery from '../Product/hooks/useMarketSegmentsQuery'
import useDelegationsQuery from '../Product/hooks/useDelegationsQuery'
import Theme from '../../constants/Theme'
import { Flex } from '../../components/FlexBox'
import CheckBox from '../../ui-library/CheckBox'
import {
  ActivityIndicatorComponent,
  ViewComponent
} from './components/SharedComponents'
import CallsForSubmissionFieldsFragment from './graphql/CallsForSubmissionQuery'
import useCurrentUser from '../../hooks/useCurrentUser'
import { curationStepFilterOptions } from '../../components/DynamicTable/components/SearchBar/constants'
import {
  getRemainingTimeString,
  useCountdown
} from '../../components/common/SubmissionClosinInfo'
import useFeatureFlag from '../../hooks/useFeatureFlag'

export const SHORTLISTED_DEFAULT_LIMIT = 10
export const FAVORITES_DEFAULT_LIMIT = 3

const getFormattedDate = (date: Date): string => {
  const addZero = (str: string) => str.padStart(3 - str.length, '0')
  const month = (date.getMonth() + 1).toString()
  const day = date.getDate().toString()
  const year = date.getFullYear().toString()
  return `${addZero(month)}/${addZero(day)}/${year}`
}

const getOpenCloseDates = (cfs: CallForSubmission) => {
  const formatter = (step: any) => {
    const openAtDate = new Date(step.openAt)
    const closeAtDate = new Date(step.closeAt)
    const value = `${getFormattedDate(openAtDate)} - ${getFormattedDate(
      closeAtDate
    )}`
    return value ?? ``
  }

  if (cfs && cfs?.currentStep) {
    const currentStep = cfs.currentStep
    if (currentStep.openAt && currentStep.closeAt) {
      return formatter(currentStep as any)
    } else if (
      cfs.status === CallForSubmissionStatusEnum.upcoming &&
      cfs.stepDateRanges?.length
    ) {
      const submissionStep = cfs.stepDateRanges.find(
        step => step.curationStep === CallForSubmissionStepEnum.submission
      )
      if (!submissionStep) return ``
      return formatter(submissionStep)
    } else if (
      cfs.status === CallForSubmissionStatusEnum.closed &&
      cfs.stepDateRanges?.length
    ) {
      const eventStep = cfs.stepDateRanges.find(
        step => step.curationStep === CallForSubmissionStepEnum.event
      )
      if (!eventStep) return ``
      return formatter(eventStep)
    }
  }
  return ``
}

export enum EngineEnum {
  InnovationSubmission = 'innovationSubmissionEngine',
  InnovationCallForSubmission = 'innovationCallForSubmissionEngine',
  InnovationInnovatorDirectoryEngine = 'innovationInnovatorDirectoryEngine'
}

const curationScreens = {
  submission: 'CurationSubmissions',
  preCurate: 'CurationPreCuration',
  advising: 'CurationSubmissions',
  cohort: 'CurationCohort',
  selection: 'CurationPresentations',
  voting: 'CurationPresentations'
}

export const headerStyle = {
  fontWeight: 'bold',
  fontSize: `${Theme.fontSizes[6] - Theme.fontSizes[0]}px`,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'inline-block'
}

const headerContainerStyle = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 10
}

export const appSearchColumnMap = {
  averageFavorites: 'average_total_fav',
  averageTotalAcademy: 'average_total_academy',
  callForSubmissionName: 'call_for_submission_name',
  marketSegmentNames: 'market_segment_names',
  delegationNames: 'delegation_names',
  curationStep: 'curation_step',
  submissionStatus: 'submission_status',
  closeAt: 'close_at',
  id: 'id',
  submissionName: 'submission_name',
  companyId: 'company_id',
  companyName: 'company_name',
  callForSubmissionId: 'call_for_submission_id',
  marketSegmentIds: 'market_segment_ids',
  delegationIds: 'delegation_ids',
  category: 'category',
  categoryLabel: 'category_label',
  wass: 'wass',
  submissionDate: 'submission_date',
  advisors: 'advisors',
  advisorsWhoAdvised: 'advisors_who_advised',
  advisorsWhoSetFavorite: 'advisors_who_set_favorite',
  advisorsWhoSetAcademy: 'advisors_who_set_academy',
  type: 'type',
  openAt: 'open_at',
  productName: 'product_name',
  submissionGroupIds: 'submission_group_ids',
  submissionStep: 'submission_step',
  passOrCutText: 'precuration_pass_or_cut_text',
  isSubmissionStatusComplete: 'is_submission_status_complete',
  hasBeenFastPassed: 'has_been_fast_passed',
  hasBeenSubmitted: 'has_been_submitted',
  favorites: 'submission_favorite_count',
  shortlisted: 'submission_shortlist_count',
  innovatorScore: 'innovator_score',
  newnessScore: 'newness_score',
  interestVotes: 'interest_votes',
  interestScore: 'interest_score',
  cohortPassGroupId: 'cohort_pass_group_id',
  cohortCutGroupId: 'cohort_cut_group_id',
  invites: 'invites',
  spotlights: 'spotlights',
  openCloseDates: 'open_close_dates',
  reviewScore: function (strings, name) {
    return `review_score_${name}`
  }
}

const defaultDir = 'asc'

const ArrowRightIcon = styled(ArrowLeftIcon).attrs(props => ({
  color: props.theme.colors.buttonText,
  size: props.theme.sizes[3]
}))`
  transform: rotate(180deg);
`

export enum TablesEnum {
  CurrentOpenCalls = 'currentOpenCalls',
  Curate = 'curate',
  Advise = 'advise'
}

enum ColumnsEnum {
  Name = 'name',
  AdviseName = 'adviseName',
  MarketSegments = 'marketSegments',
  Delegations = 'delegations',
  CFSStep = 'cfsStep',
  CFSStepCloseAt = 'cfsStepCloseAt',
  CFSCFSStatus = 'cfsCFSStatus',
  CFSSubmissionStatus = 'cfsSubmissionStatus',
  CFSStepOpenCloseAt = 'cfsStepOpenCloseAt',
  CFSSubmissions = 'cfsSubmissions',
  CFSSubmissionsToReview = 'cfsSumissionsToReview',
  ArrowAction = 'arrowAction',
  RemainingSubmissionTime = 'remainingSubmissionTime',
  EditAction = 'editAction',
  Dashboard = 'dashboard'
}

export interface RowWrapperProps {
  children: any
  item: CallForSubmission
}

const handleSelectCFS = async (item, linkToScreen) => {
  if (!item?.status || item?.status !== CallForSubmissionStatusEnum.open) {
    return
  }

  linkToScreen('SubmissionPrefill', { id: item.id })
}

const getCurationScreenName = (
  currentStep: CallForSubmissionStepEnum,
  role?: RoleEnum
) => {
  if (role === RoleEnum.InnovationAdvisor) {
    return 'AdviseSubmissions'
  } else if (role === RoleEnum.InnovationStaff) {
    return curationScreens[currentStep] || 'CurationSubmissions'
  }
  return null
}

const handleCurationCFS = async (
  screenName,
  item,
  linkToScreen,
  trackWithProperties
) => {
  linkToScreen(screenName, { id: item?.id })
  trackWithProperties(EVENT_MAP.click.button, {
    category: 'curate',
    action: 'navigate'
  })
}

export const IconButton = ({ handlePress, icon, disabled }) => {
  const theme = useContext(ThemeContext) as any
  return (
    <Button
      onPress={handlePress}
      containerStyle={{
        borderRadius: 20,
        height: '38px',
        width: '38px',
        padding: 0,
        marginTop: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      buttonStyle={{
        height: '38px',
        width: '38px',
        borderRadius: 20,
        padding: 0,
        margin: 0,
        backgroundColor: disabled
          ? theme.colors.lightBlueBackground
          : theme.colors.buttonBackground
      }}
      iconContainerStyle={{
        position: 'absolute',
        left: '10px',
        top: '10px'
      }}
      icon={icon}
      animated
    />
  )
}

export const Content = ({ isSmallScreen, label, title, style = {} }) => {
  return isSmallScreen ? (
    <LabelAndTitleCell label={label} title={title} style={style} />
  ) : (
    <SubtitleCell text={title} style={style} />
  )
}

export const newSelectItemColumn = {
  id: 'selectionItemColumn',
  width: 7,
  emptyMessage: '',
  isEditable: false,
  fixed: true,
  isSelectable: true,
  Cell: ({
    item,
    isSmallScreen,
    label,
    selectItemProps
  }: CellRendererProps<any>) => {
    const onPress = () => {
      selectItemProps?.onSelect && selectItemProps?.onSelect(item.id)
    }
    const isDisabled = !!selectItemProps?.isDisabled

    const CheckBoxComponent = () => {
      return (
        <CheckBox
          checked={selectItemProps?.isSelected ?? false}
          onPress={onPress}
          isReadOnly={isDisabled}
          flexContainerStyles={{
            paddingLeft: 0,
            marginBottom: 0
          }}
        />
      )
    }
    return isSmallScreen ? (
      <Flex>
        <Content isSmallScreen={isSmallScreen} label={label} title="" />
        {selectItemProps?.isLoading ? (
          <ActivityIndicatorComponent
            style={{ alignItems: 'baseline' }}
            size="small"
          />
        ) : (
          <CheckBoxComponent />
        )}
      </Flex>
    ) : selectItemProps?.isLoading ? (
      <ActivityIndicatorComponent
        style={{ alignItems: 'baseline' }}
        size="small"
      />
    ) : (
      <CheckBoxComponent />
    )
  }
}

export const selectItemColumn = {
  id: 'selectionItemColumn',
  width: 8,
  emptyMessage: '',
  isEditable: false,
  fixed: true,
  isSelectable: true,
  Cell: ({
    item,
    isSmallScreen,
    label,
    selectItemProps
  }: CellRendererProps<any>) => {
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
      setIsSelected(!!selectItemProps?.isSelected)
    }, [selectItemProps?.isSelected])

    useEffect(() => {
      setIsSelected(!!selectItemProps?.selectedItems[item?.id])
    }, [selectItemProps?.selectedItems[item?.id]])

    const onToggle = selectItemProps?.onToggle
      ? selectItemProps?.onToggle
      : undefined
    const onPress = () => {
      setIsSelected(!isSelected)
      onToggle && onToggle(item?.id)
    }
    const isDisabled = !!selectItemProps?.isDisabled

    const CheckBoxComponent = () => {
      return (
        <CheckBox
          checked={isSelected}
          onPress={onPress}
          isReadOnly={isDisabled}
          flexContainerStyles={{
            paddingLeft: 0,
            marginBottom: 0
          }}
        />
      )
    }
    return isSmallScreen ? (
      <Flex>
        <Content isSmallScreen={isSmallScreen} label={label} title="" />
        {selectItemProps?.isLoading ? (
          <ActivityIndicatorComponent
            style={{ alignItems: 'baseline' }}
            size="small"
          />
        ) : (
          <CheckBoxComponent />
        )}
      </Flex>
    ) : selectItemProps?.isLoading ? (
      <ActivityIndicatorComponent
        style={{ alignItems: 'baseline' }}
        size="small"
      />
    ) : (
      <CheckBoxComponent />
    )
  }
}

const nameColumn = {
  id: ColumnsEnum.Name,
  filter: {
    type: 'search',
    key: appSearchColumnMap['callForSubmissionName'],
    defaultValue: ''
  },
  sort: {
    key: appSearchColumnMap['callForSubmissionName'],
    defaultDir,
    isDefault: false
  },
  header: 'callsForSubmission:table:name',
  headerStyle,
  width: 20,
  minWidth: 150,
  emptyMessage: 'callsForSubmission:table:emptyMessage',
  isEditable: false,
  role: null,
  fixed: true,
  Cell: ({ item, label, isSmallScreen, role }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const { trackWithProperties } = useMixpanel()
    const labelTranslated = label ? t(label) : ''
    const shouldCurate = useHasPermission(CURATION_PERMISSIONS)
    const linkToScreen = useLinkToScreen()

    const handlePress = () => {
      if (shouldCurate) {
        handleCurationCFS(
          getCurationScreenName(item.currentStep?.name, role),
          item,
          linkToScreen,
          trackWithProperties
        )
      } else {
        handleSelectCFS(item, linkToScreen)
        trackWithProperties(EVENT_MAP.click.button, {
          category: 'submission',
          action: 'navigate'
        })
      }
    }

    const linkDisabled = shouldCurate
      ? false
      : !item?.status || item?.status !== CallForSubmissionStatusEnum.open

    const titleObj = {
      title: item?.name
    }

    return isSmallScreen ? (
      <LabelAndTitleCell
        label={labelTranslated}
        title={item?.name}
        onPress={handlePress}
        isValueHighlighted={!linkDisabled}
        containerStyles={{
          borderTopWidth: 0
        }}
      />
    ) : (
      <TitleCell
        item={titleObj}
        onPress={handlePress}
        isValueHighlighted={!linkDisabled}
      />
    )
  }
}

const adviseNameColumn = {
  id: ColumnsEnum.AdviseName,
  filter: {
    type: 'search',
    key: appSearchColumnMap['callForSubmissionName'],
    defaultValue: ''
  },
  sort: {
    key: appSearchColumnMap['callForSubmissionName'],
    defaultDir,
    isDefault: false
  },
  header: 'callsForSubmission:table:name',
  headerStyle,
  width: 20,
  emptyMessage: 'callsForSubmission:table:emptyMessage',
  isEditable: false,
  role: null,
  Cell: ({ item, label, isSmallScreen, role }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const { trackWithProperties } = useMixpanel()
    const labelTranslated = label ? t(label) : ''
    const shouldCurate = useHasPermission(CURATION_PERMISSIONS)
    const linkToScreen = useLinkToScreen()

    const handlePress = () => {
      if (shouldCurate) {
        handleCurationCFS(
          getCurationScreenName(item.currentStep?.name, role),
          item,
          linkToScreen,
          trackWithProperties
        )
      } else {
        handleSelectCFS(item, linkToScreen)
        trackWithProperties(EVENT_MAP.click.button, {
          category: 'submission',
          action: 'navigate'
        })
      }
    }

    const linkEnabled =
      shouldCurate &&
      ([CallForSubmissionStatusEnum.closed].includes(item?.status) ||
        ([CallForSubmissionStatusEnum.open].includes(item?.status) &&
          [CallForSubmissionStepEnum.advising].includes(
            item?.currentStep?.name
          )))

    const handlePressLink = linkEnabled ? handlePress : () => {}

    const titleObj = {
      title: item?.name
    }

    return isSmallScreen ? (
      <LabelAndTitleCell
        label={labelTranslated}
        title={item?.name}
        onPress={handlePressLink}
        isValueHighlighted={linkEnabled}
        containerStyles={{
          borderTopWidth: 0
        }}
      />
    ) : (
      <TitleCell
        item={titleObj}
        onPress={handlePressLink}
        isValueHighlighted={linkEnabled}
      />
    )
  }
}

const marketSegmentsColumn = {
  id: ColumnsEnum.MarketSegments,
  filter: {
    type: 'filter',
    key: appSearchColumnMap['marketSegmentIds'],
    isFilterArray: true,
    isHook: true,
    hookOptions: {
      useHookQuery: useMarketSegmentsQuery,
      hookParams: {},
      hookKey: 'marketSegments',
      labelKey: 'name',
      valueKey: 'id'
    },
    defaultValue: ''
  },
  sort: {
    key: appSearchColumnMap['marketSegmentNames'],
    defaultDir,
    isDefault: false
  },
  header: 'callsForSubmission:table:marketSegments',
  headerStyle,
  width: 10,
  isEditable: false,
  fixed: true,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    const marketSegmentNames = item?.marketSegments.map(ms => ms.name)
    const text = marketSegmentNames?.join(', ')

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={text}
      />
    )
  }
}

const delegationsColumn = {
  id: ColumnsEnum.Delegations,
  filter: {
    type: 'filter',
    key: appSearchColumnMap['delegationIds'],
    isFilterArray: true,
    isHook: true,
    hookOptions: {
      useHookQuery: useDelegationsQuery,
      hookParams: {},
      hookKey: 'delegations',
      labelKey: 'name',
      valueKey: 'id'
    },
    defaultValue: ''
  },
  sort: {
    key: appSearchColumnMap['delegationNames'],
    defaultDir,
    isDefault: false
  },
  header: 'callsForSubmission:table:delegations',
  headerStyle,
  width: 10,
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    const delegationNames = item?.delegations.map(ms => ms.name)
    const text = delegationNames?.join(', ')

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={text}
      />
    )
  }
}

const cfsStepColumn = {
  id: ColumnsEnum.CFSStep,
  filter: {
    type: 'filter',
    key: appSearchColumnMap['curationStep'],
    isFilterArray: true,
    isHook: false,
    options: curationStepFilterOptions,
    defaultValue: '',
    disabledFilterBySelection: currentOptionSelection =>
      currentOptionSelection?.status !== CallForSubmissionStatusEnum.open
  },
  sort: {
    key: appSearchColumnMap['curationStep'],
    defaultDir,
    isDefault: false
  },
  header: 'callsForSubmission:table:step',
  headerStyle,
  width: 10,
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    const value = item?.currentStep.name
      ? t(`callsForSubmission:step:name:${item?.currentStep.name}`)
      : item?.status === CallForSubmissionStatusEnum.closed &&
        item?.stepDateRanges?.length
      ? t(`callsForSubmission:step:name:event`)
      : '-'

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={value}
      />
    )
  }
}

const cfsStepCloseAtColumn = {
  id: ColumnsEnum.CFSStepCloseAt,
  sort: {
    key: appSearchColumnMap['closeAt'],
    defaultDir,
    isDefault: false
  },
  header: 'callsForSubmission:table:closeAt',
  headerStyle,
  itemMap: 'currentStep.closeAt',
  width: 20,
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    let submissionStep = item?.stepDateRanges?.find(
      s => s?.curationStep === CallForSubmissionStepEnum.submission
    )

    let closeAtDate = submissionStep?.closeAt

    if (!closeAtDate && !item.currentStep.name) {
      closeAtDate = item.stepDateRanges[item.stepDateRanges.length - 1].closeAt
    }

    const value = format(
      utcToZonedTime(new Date(closeAtDate), localTimezone),
      'MM/dd/yyyy H:mm zzz'
    )
    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={value}
      />
    )
  }
}

const cfsCFSStatusColumn = {
  id: ColumnsEnum.CFSCFSStatus,
  sort: {
    key: appSearchColumnMap[''],
    defaultDir,
    isDefault: false
  },
  header: 'callsForSubmission:status:title',
  headerStyle,
  itemMap: '',
  width: 8,
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    let { status, currentStep } = item

    let textColor = Theme.colors.text
    let labelContent = 'callsForSubmission:status:open'
    if (
      currentStep?.status === CallForSubmissionStepStatusEnum.closed &&
      status === CallForSubmissionStatusEnum.upcoming
    ) {
      labelContent = 'callsForSubmission:status:upcoming'
      textColor = Theme.colors.tints.grays.g400
    }

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={t(labelContent)}
        style={{
          color: textColor
        }}
      />
    )
  }
}

const cfsSubmissionStatusColumn = {
  id: ColumnsEnum.CFSSubmissionStatus,
  sort: {
    key: appSearchColumnMap[''],
    defaultDir,
    isDefault: false
  },
  header: 'callsForSubmission:table:submissionStatus',
  headerStyle,
  itemMap: '',
  width: 10,
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    let submission = item?.innovationSubmissions
      ? item?.innovationSubmissions[0]
      : null

    let textColor = Theme.colors.tints.grays.g400
    let status = 'notStarted'
    if (submission) {
      if (submission?.hasBeenSubmitted) {
        status = 'submitted'
        textColor = Theme.colors.success
      } else {
        status = 'incomplete'
        textColor = Theme.colors.alert
      }
    }

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={t(`callsForSubmission:innovatorSubmissionStatus:${status}`)}
        style={{
          color: textColor
        }}
      />
    )
  }
}

const cfsStepOpenCloseAtColumn = {
  id: ColumnsEnum.CFSStepOpenCloseAt,
  header: 'callsForSubmission:table:openClose',
  headerStyle,
  width: 15,
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={getOpenCloseDates(item)}
      />
    )
  }
}

const newCfsStepOpenCloseAtColumn = {
  id: ColumnsEnum.CFSStepOpenCloseAt,
  header: 'callsForSubmission:table:openClose',
  sort: {
    key: appSearchColumnMap['openCloseDates'],
    defaultDir,
    isDefault: false
  },
  headerStyle,
  width: 15,
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={getOpenCloseDates(item)}
      />
    )
  }
}

const cfsSubmissionsColumn = {
  id: ColumnsEnum.CFSSubmissions,
  header: 'callsForSubmission:table:submissions',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 12,
  isEditable: false,
  Cell: ({ item, isSmallScreen, label }: CellRendererProps<any>) => {
    const { t } = useTranslation()

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        label={labelTranslated}
        isSmallScreen={isSmallScreen}
        title={item?.totalSubmissions || '-'}
      />
    )
  }
}

const newCfsSubmissionsColumn = {
  id: ColumnsEnum.CFSSubmissions,
  header: 'callsForSubmission:table:submissions',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 12,
  isEditable: false,
  Cell: ({ item, isSmallScreen, label }: CellRendererProps<any>) => {
    const { t } = useTranslation()

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        label={labelTranslated}
        isSmallScreen={isSmallScreen}
        title={item?.totalSubmissions || '-'}
        style={{ textAlign: 'center' }}
      />
    )
  }
}

const submissionsToReviewColumn = {
  id: ColumnsEnum.CFSSubmissionsToReview,
  header: 'callsForSubmission:table:submissions',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 12,
  isEditable: false,
  Cell: ({ item, isSmallScreen, label }: CellRendererProps<any>) => {
    const { t } = useTranslation()

    const { currentUserId } = useCurrentUser()
    const submissions = item?.advisorsInfo?.find(
      advisor => advisor.user?.id === currentUserId
    )?.reviews

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        label={labelTranslated}
        isSmallScreen={isSmallScreen}
        title={submissions || '-'}
        style={{ textAlign: 'center' }}
      />
    )
  }
}

const arrowActionColumn = {
  id: ColumnsEnum.ArrowAction,
  header: null,
  width: 10,
  isEditable: false,
  Cell: ({ item, isSmallScreen }: CellRendererProps<any>) => {
    const { trackWithProperties } = useMixpanel()
    const linkToScreen = useLinkToScreen()

    const handlePress = () => {
      handleSelectCFS(item, linkToScreen)
      trackWithProperties(EVENT_MAP.click.button, {
        category: 'submission',
        action: 'navigate'
      })
    }

    if (!item?.status || item?.status !== CallForSubmissionStatusEnum.open) {
      return null
    }
    return (
      <ViewComponent
        style={{
          alignItems: 'flex-end',
          width: isSmallScreen ? '100%' : '80%',
          minWidth: '38px'
        }}
      >
        <IconButton
          handlePress={handlePress}
          icon={<ArrowRightIcon />}
          disabled={undefined}
        />
      </ViewComponent>
    )
  }
}

const submissionRemainingTimeColumn = {
  id: ColumnsEnum.RemainingSubmissionTime,
  header: null,
  width: 15,
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const currentStep = item.currentStep
    const timeDeltas = useCountdown(currentStep.closeAt)
    const isInSubmissionWindow = currentStep?.name === 'submission'
    // If is not in submission window hide component
    if (!isInSubmissionWindow) {
      return null
    }
    const title = t('submissions:countdownClock:remainingTime', {
      remainingTime: getRemainingTimeString(timeDeltas)
    })
    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={label}
        title={title}
        style={{
          color: Theme.colors.alert
        }}
      />
    )
  }
}

const editActionColumn = {
  id: ColumnsEnum.EditAction,
  header: null,
  width: 5,
  isEditable: false,
  Cell: ({ item, isSmallScreen }: CellRendererProps<any>) => {
    const shouldCurate = useHasPermission(CURATION_PERMISSIONS)
    const isAdmin = useHasPermission([RoleEnum.Admin])
    const linkToScreen = useLinkToScreen()
    const isInnovationAnalyticsSuiteEnabled = useFeatureFlag(
      'innovationAnalyticsSuite'
    )
    return (
      <ViewComponent
        style={{
          alignItems: 'flex-start',
          width: isSmallScreen ? '100%' : '80%',
          minWidth: '38px',
          flexDirection: 'row'
        }}
      >
        <TouchableIcon
          name="cog"
          color={Theme.colors.tints.grays.g300}
          width={18}
          height={18}
          onPress={() => {
            if (shouldCurate) {
              linkToScreen('GeneralSettings', { cfsId: item?.id })
            }
          }}
        />
        {isAdmin && isInnovationAnalyticsSuiteEnabled ? (
          <TouchableIcon
            name="dashboard"
            color={Theme.colors.tints.grays.g300}
            width={18}
            height={18}
            style={{ marginLeft: 10 }}
            onPress={() => {
              if (shouldCurate) {
                linkToScreen('Dashboard', { cfsId: item?.id })
              }
            }}
          />
        ) : null}
      </ViewComponent>
    )
  }
}

const commonColsMap = {
  selectItem: selectItemColumn,
  name: nameColumn,
  marketSegments: marketSegmentsColumn,
  delegations: delegationsColumn,
  adviseName: adviseNameColumn
}

const getCommonCols = (cols, decoratorsProps = {}) =>
  cols.map(colName => ({
    ...commonColsMap[colName],
    ...(decoratorsProps[colName] || {})
  }))

const commonCols = ['selectItem', 'name', 'marketSegments', 'delegations']
const commonAdviseCols = ['adviseName', 'marketSegments', 'delegations']

const columnsRoleMap = {
  [RoleEnum.InnovatorTeamAdmin]: [
    ...getCommonCols(commonCols),
    cfsCFSStatusColumn,
    newCfsStepOpenCloseAtColumn,
    submissionRemainingTimeColumn,
    arrowActionColumn
  ],
  [RoleEnum.InnovatorTeamMember]: [
    ...getCommonCols(commonCols),
    cfsCFSStatusColumn,
    cfsStepCloseAtColumn,
    submissionRemainingTimeColumn,
    arrowActionColumn
  ],
  [RoleEnum.InnovationStaff]: [
    ...getCommonCols(commonCols, {
      name: {
        role: RoleEnum.InnovationStaff,
        width: 20
      },
      marketSegments: {
        width: 15
      },
      delegations: {
        width: 10
      }
    }),
    cfsStepOpenCloseAtColumn,
    cfsStepColumn,
    {
      ...cfsSubmissionsColumn,
      ...{ role: RoleEnum.InnovationStaff }
    }
  ],
  [RoleEnum.InnovationAdvisor]: [
    ...getCommonCols(commonCols, {
      name: {
        role: RoleEnum.InnovationAdvisor
      },
      delegations: {
        width: 15
      }
    }),
    cfsStepOpenCloseAtColumn,
    {
      ...submissionsToReviewColumn,
      ...{ role: RoleEnum.InnovationAdvisor }
    }
  ]
}

const openCfs = (isAdvise?: boolean) => ({
  open_at: {
    to: new Date()
  },
  close_at: {
    from: new Date()
  },
  curation_step: [
    CallForSubmissionStepEnum.advising,
    ...(!isAdvise
      ? [
          CallForSubmissionStepEnum.submission,
          CallForSubmissionStepEnum.preCurate,
          CallForSubmissionStepEnum.cohort,
          CallForSubmissionStepEnum.voting,
          CallForSubmissionStepEnum.selection,
          CallForSubmissionStepEnum.finalSelections,
          CallForSubmissionStepEnum.event
        ]
      : [])
  ]
})

const upcomingCfs = (isAdvise?: boolean) => ({
  open_at: {
    from: new Date()
  },
  close_at: {
    from: new Date()
  },
  curation_step: isAdvise
    ? CallForSubmissionStepEnum.advising
    : CallForSubmissionStepEnum.submission
})

const closeCfs = (isAdvise?: boolean) => ({
  open_at: {
    to: new Date()
  },
  close_at: {
    to: new Date()
  },
  curation_step: isAdvise
    ? CallForSubmissionStepEnum.advising
    : CallForSubmissionStepEnum.event
})

export const statusMap = {
  [CallForSubmissionStatusEnum.open]: openCfs(),
  [CallForSubmissionStatusEnum.upcoming]: upcomingCfs(),
  [CallForSubmissionStatusEnum.closed]: closeCfs()
}

export const statusMapAdvise = {
  [CallForSubmissionStatusEnum.open]: openCfs(true),
  [CallForSubmissionStatusEnum.upcoming]: upcomingCfs(true),
  [CallForSubmissionStatusEnum.closed]: closeCfs(true)
}

const CFS_TABLE_CONFIG: TableConfig<any> | any = {
  name: 'CallsForSubmissionsTable',
  headerContainerStyle,
  LargeRow: AlternatingHoverableRow,
  SmallRow: SmallRow
}

const commonFilter = {
  call_for_submission_has_been_deleted: 'false'
}

export const getQueryFiltersByRole = (
  role: RoleEnum,
  advisorId?: string,
  tableId?: TablesEnum
): any => {
  let filters = {}
  switch (role) {
    case RoleEnum.InnovationStaff:
    case RoleEnum.Admin:
      filters = {
        ...statusMap[CallForSubmissionStatusEnum.open],
        ...commonFilter
      }
      return filters
    case RoleEnum.InnovationAdvisor:
      filters = {
        ...(tableId === TablesEnum.Advise
          ? { [appSearchColumnMap['advisors']]: advisorId }
          : {}),
        ...statusMapAdvise[CallForSubmissionStatusEnum.open],
        ...commonFilter
      }
      return filters
    default:
      return filters
  }
}

export const filtersRoleMap = (role: RoleEnum) => {
  switch (role) {
    case RoleEnum.Admin:
      return true
    case RoleEnum.InnovationStaff:
      return true
    case RoleEnum.InnovationAdvisor:
      return true
    default:
      return false
  }
}

export const getEngineHitKey = (engine: EngineEnum | undefined) => {
  switch (engine) {
    case EngineEnum.InnovationSubmission:
      return undefined
    case EngineEnum.InnovationCallForSubmission:
      return undefined
  }
}

export const getCFSTableConfig = (
  role: RoleEnum,
  engine: EngineEnum | undefined,
  isCountdownClockEnabled: Boolean,
  canEdit?: boolean,
  advisorId?: string,
  tableId?: TablesEnum,
  isInnovationNewDynamicTable?: boolean
) => {
  const table = Object.assign({}, CFS_TABLE_CONFIG)
  table.queryDynamicName = 'callForSubmissionsSearchInnovationEngine'
  table.enableFilters = filtersRoleMap(role)
  table.engine = engine
  table.hitKey = getEngineHitKey(engine)
  table.filters = getQueryFiltersByRole(role, advisorId, tableId)
  table.isSticky = true

  // @ts-ignore
  if (tableId === TablesEnum.Advise) {
    table.columns = [
      ...getCommonCols(commonAdviseCols, {
        adviseName: {
          role: RoleEnum.InnovationAdvisor
        },
        delegations: {
          width: 15
        }
      }),
      cfsStepOpenCloseAtColumn,
      {
        ...submissionsToReviewColumn,
        ...{ role: RoleEnum.InnovationAdvisor }
      }
    ]
  } else {
    table.columns = columnsRoleMap[role]
  }

  if (isInnovationNewDynamicTable) {
    table.columns = table.columns.map(col => {
      if (col?.id === ColumnsEnum.CFSStepOpenCloseAt) {
        return newCfsStepOpenCloseAtColumn
      }
      if (col?.id === ColumnsEnum.CFSSubmissions) {
        return newCfsSubmissionsColumn
      }
      return col
    })
  }

  table.tableId = tableId
  table.columns = table.columns.filter(
    col =>
      col.id !== 'selectionItemColumn' &&
      col.id !== (isCountdownClockEnabled ? '' : 'remainingSubmissionTime')
  )
  table.showCsvButton = false
  table.showPDFButton = false
  table.showStatusFilter = true
  table.isAlternativeView = true

  if (canEdit) {
    table.columns.push(editActionColumn)
  }

  table.customQueryFields = CallsForSubmissionFieldsFragment(
    tableId === TablesEnum.Advise
  )

  return table
}

export const getInnovatorHomePageCFSTableConfig = (
  role: RoleEnum,
  engine: EngineEnum | undefined,
  isCountdownClockEnabled: Boolean
) => {
  const table = Object.assign({}, CFS_TABLE_CONFIG)
  table.enableFilters = filtersRoleMap(role)
  table.engine = engine
  table.hitKey = getEngineHitKey(engine)
  table.filters = getQueryFiltersByRole(role)
  table.isSticky = true

  // @ts-ignore
  table.columns = [
    ...getCommonCols(commonCols),
    cfsStepCloseAtColumn,
    cfsSubmissionStatusColumn,
    submissionRemainingTimeColumn,
    arrowActionColumn
  ]

  table.columns = table.columns.filter(
    col =>
      col.id !== 'selectionItemColumn' &&
      col.id !== (isCountdownClockEnabled ? '' : 'remainingSubmissionTime')
  )

  return table
}
