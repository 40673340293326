import React from 'react'
import VerifyEmailRegisterScreen from '../screens/authentication/VerifyEmailRegisterScreen'
import AuthCodeSentScreen from '../screens/authentication/AuthCodeSentScreen'
import RegistrationScreen from '../screens/authentication/RegistrationScreen'
import RegistrationUserToCompany from '../screens/authentication/RegistrationUserToCompany'

export interface RegisterCheckSubStateProps {
  send: (props: any) => void
  payload: any
  firebaseToken: string
  firebaseInitialized: boolean
  state: any
}

const RegisterCheck = ({
  send,
  state,
  payload,
  firebaseToken,
  firebaseInitialized
}: any) => {
  const substate = state.registerCheck

  const props: RegisterCheckSubStateProps = {
    send,
    state,
    payload,
    firebaseToken,
    firebaseInitialized
  }

  switch (substate) {
    case 'verifyEmail':
      return <VerifyEmailRegisterScreen {...props} />
    case 'authCodeCheck':
      return <AuthCodeSentScreen {...props} />
    case 'registerNewUser':
      return <RegistrationScreen {...(props as any)} />
    case 'registerUserToCompany':
      return <RegistrationUserToCompany {...props} />
    default:
      return null
  }
}

export default RegisterCheck
