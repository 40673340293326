import React, { useState } from 'react'
import { ActivityIndicator, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

import useTranslation from '../../../../hooks/useTranslation'

import Modal from '../../../../components/common/Modal'
import TouchableIcon from '../../../../components/icon/TouchableIcon'

import { User } from '../../../../types'
import TextInput from '../../../../ui-library/TextInput'
import Button from '../../../../ui-library/Button'
import { Message, MessageStatus } from '../../../../recoil/toastMessageAtom'
import ModalToast from '../../../Settings/components/ModalToast'
import { Title } from '../../../Settings/components/SharedStyledComponents'
import useUpsertCallForSubmissionAdvisorsMutation from '../../../Curation/hooks/useUpsertCallForSubmissionAdvisorsMutation'
import sentry from '../../../../utils/sentry'

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`

interface SendEmailDialogProps {
  title: string
  user: User
  isOpen: boolean
  onClose: () => void
  callForSubmissionId: string
}

const SendEmailDialog = ({
  title,
  isOpen,
  onClose,
  user,
  callForSubmissionId
}: SendEmailDialogProps) => {
  const { t } = useTranslation()
  const { colors, space, breakpoints } = useTheme()
  const [toastMessage, setToastMessage] = useState<Message | null>(null)

  const {
    upsertCallForSubmissionAdvisors,
    loading: isLoading
  } = useUpsertCallForSubmissionAdvisorsMutation(callForSubmissionId)

  const handleSave = async () => {
    try {
      const results = await upsertCallForSubmissionAdvisors({
        variables: {
          callForSubmissionId,
          toAddAdvisorsIds: [user.id],
          toRemoveAdvisorsIds: []
        }
      })
      if (results.data) {
        setToastMessage({
          message: t('settings:advisors:emailSent'),
          status: MessageStatus.Success
        })
      }
      if (results.errors) {
        setToastMessage({
          message: t('error:defaultMutation'),
          status: MessageStatus.Error
        })
      }
    } catch (error) {
      console.error(error)
      sentry.captureException(error)
      setToastMessage({
        message: t('error:defaultMutation'),
        status: MessageStatus.Error
      })
    }
  }

  const buttonPadding = {
    vertical: space[1] + space[2],
    horizontal: space[2] + space[3]
  }
  const formFieldStyles = { maxWidth: breakpoints.phone, width: '100%' }
  const formFieldContainerStyles = { marginBottom: '2%' }

  const btnContainerStyles = {
    height: space[4],
    marginTop: space[1] + space[3]
  }
  const btnStyles = {
    width: 'fit-content',
    paddingTop: buttonPadding.vertical,
    paddingBottom: buttonPadding.vertical,
    paddingLeft: buttonPadding.horizontal,
    paddingRight: buttonPadding.horizontal,
    height: space[4]
  }
  return (
    <Modal
      close={onClose}
      open={isOpen}
      styles={{
        minWidth: '550px',
        padding: '15px 24px'
      }}
    >
      <Title>{title}</Title>
      <CloseIcon onPress={onClose} />

      {isLoading ? (
        <View>
          <ActivityIndicator size="large" />
        </View>
      ) : (
        <div style={{ marginTop: space[3], maxWidth: breakpoints.tablet }}>
          <ModalToast message={toastMessage} />
          <TextInput
            containerStyles={formFieldContainerStyles}
            label={t('settings:members:form:email')}
            style={formFieldStyles}
            name="email"
            value={
              user?.person?.emailAddresses &&
              user?.person?.emailAddresses[0]?.email
            }
            isReadOnly
          />

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              maxWidth: 640
            }}
          >
            <TextInput
              label={t('settings:members:form:firstName')}
              name="firstName"
              value={user?.person?.firstName}
              containerStyles={{ width: '49%' }}
              style={{ width: '100% ' }}
              isReadOnly
            />
            <TextInput
              label={t('settings:members:form:lastName')}
              name="lastName"
              value={user?.person?.lastName}
              containerStyles={{ width: '49%' }}
              style={{ width: '100% ' }}
              isReadOnly
            />
          </View>

          <div
            style={{
              display: 'flex',
              marginTop: space[3],
              justifyContent: 'flex-end'
            }}
          >
            <Button
              title={t('settings:advisors:buttons:cancel')}
              type="outline"
              buttonStyle={{
                ...btnStyles,
                borderColor: colors.buttonVariant
              }}
              titleStyle={{
                color: colors.buttonVariant
              }}
              containerStyle={{
                ...btnContainerStyles,
                marginRight: space[3]
              }}
              onPress={onClose}
            />
            <Button
              title={t('settings:advisors:buttons:resendInvite')}
              buttonStyle={btnStyles}
              containerStyle={btnContainerStyles}
              onPress={handleSave}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}

export default SendEmailDialog
