import React, { FC } from 'react'
import { ScrollView } from 'react-native'
import styled from 'styled-components/native'
import { Flex } from '../../../../../components/FlexBox'
import {
  CallForSubmission,
  CallForSubmissionStepEnum,
  InnovationSubmission,
  RoleEnum,
  SubmissionReview
} from '../../../../../types'

import Ratings from './Advisor/Ratings'
import StaffReview from './Staff'
import SkeletonContainer from '../../../../../components/skeletonLoadings/SkeletonContainer'
import { ReviewSkeleton } from '../Skeleton'

const StyledScrollView = styled(ScrollView)`
  height: 90%;
  flex-grow: initial;
`

interface ReviewProps {
  role: RoleEnum
  reviewFormId?: string
  submission: InnovationSubmission
  callForSubmission: CallForSubmission
  submissionReview?: SubmissionReview
  displayOnlyIcons?: boolean
  isLoading: boolean
  onDirty?: (dirty: boolean) => any
}

const Review: FC<ReviewProps> = ({
  role,
  callForSubmission,
  submissionReview,
  submission,
  displayOnlyIcons = false,
  isLoading,
  onDirty
}) => {
  const currentCFSStep = submission?.callForSubmission?.currentStep
  const isCFSInSubmission =
    currentCFSStep &&
    CallForSubmissionStepEnum.submission === currentCFSStep?.name
  const isEditable = submission?.canSubmitAdvisorFeedback
  const isInnovatorAlumni = submission?.company?.isAlumni

  return (
    <Flex
      style={
        !displayOnlyIcons
          ? {
              zIndex: 1000,
              flexBasis: '25%',
              paddingTop: 5,
              paddingLeft: 5,
              paddingRight: 5
            }
          : undefined
      }
    >
      <SkeletonContainer isLoading={isLoading} Skeleton={ReviewSkeleton}>
        <StyledScrollView
          style={{
            zIndex: 1000,
            overflow: 'scroll'
          }}
        >
          {role === RoleEnum.InnovationAdvisor && submissionReview ? (
            <Ratings
              isReadOnly={!isEditable}
              callForSubmission={callForSubmission}
              reviewFormId={callForSubmission?.reviewFormId}
              submissionReview={submissionReview}
              isInnovatorAlumni={isInnovatorAlumni}
              onDirty={onDirty}
            />
          ) : null}
          {role === RoleEnum.InnovationStaff && !isCFSInSubmission ? (
            <StaffReview submission={submission} />
          ) : null}
        </StyledScrollView>
      </SkeletonContainer>
    </Flex>
  )
}

export default Review
