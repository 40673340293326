import React, { Component } from 'react'

import { Flex } from '../../../../components/FlexBox'
import Button from '../../../../ui-library/Button'

import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'
import useFeatureFlag from '../../../../hooks/useFeatureFlag'
import { withTheme } from 'styled-components/native'

interface ActionBtnProp {
  title: string
  isProcessing?: boolean
  disabled?: boolean
  onPress: Function
  testID?: string
  icon?: Component
  iconContainerStyle?: object
  iconRight?: boolean
  animated?: boolean
}
interface CallToActionProp {
  primaryBtn: ActionBtnProp
  secondaryBtn: ActionBtnProp
  theme: any
}

const CallToActionBtns = ({
  secondaryBtn,
  primaryBtn,
  theme
}: CallToActionProp) => {
  const isSmallScreen = useIsSmallScreen()
  const rebrand = useFeatureFlag('innovationRebrandPlatform')
  const rebrandButtonStyles = rebrand
    ? { paddingHorizontal: 14, paddingVertical: 10, borderRadius: 50 }
    : {}

  const disabledStyle = rebrand ? { opacity: 0.3 } : {}

  return (
    <Flex
      flexDirection="row"
      flexWrap="wrap"
      justifyContent={rebrand ? 'flex-start' : 'space-between'}
      width="100%"
      marginTop="30px"
    >
      <Button
        testID={secondaryBtn.testID}
        title={secondaryBtn.title}
        onPress={secondaryBtn.onPress}
        isProcessing={secondaryBtn.isProcessing}
        disabled={secondaryBtn.disabled}
        containerStyle={
          rebrand
            ? { marginRight: theme.space[3] }
            : { flexBasis: isSmallScreen ? '50%' : '40%' }
        }
        buttonStyle={{
          width: '100%',
          borderWidth: 0,
          ...rebrandButtonStyles
        }}
        titleStyle={{
          fontSize: rebrand ? theme.sizes[3] : 14,
          textTransform: rebrand ? 'none' : 'uppercase'
        }}
        icon={secondaryBtn.icon}
        iconContainerStyle={secondaryBtn.iconContainerStyle}
        iconRight={secondaryBtn.iconRight}
        disabledStyle={disabledStyle}
        animated={secondaryBtn.animated}
      />
      <Button
        testID={primaryBtn.testID}
        title={primaryBtn.title}
        onPress={primaryBtn.onPress}
        isProcessing={primaryBtn.isProcessing}
        disabled={primaryBtn.disabled}
        type={rebrand ? 'outline' : 'solid'}
        containerStyle={
          rebrand
            ? {}
            : {
                flexGrow: 1
              }
        }
        buttonStyle={{
          width: isSmallScreen || rebrand ? '100%' : '80%',
          alignSelf: 'flex-end',
          ...rebrandButtonStyles
        }}
        titleStyle={{
          ...(rebrand
            ? { fontSize: theme.sizes[3], textTransform: 'none' }
            : {})
        }}
        icon={primaryBtn.icon}
        iconContainerStyle={primaryBtn.iconContainerStyle}
        iconRight={primaryBtn.iconRight}
        disabledStyle={disabledStyle}
        animated={primaryBtn.animated}
      />
    </Flex>
  )
}

export default withTheme(CallToActionBtns)
