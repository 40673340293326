import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { FormikProvider } from 'formik'
import styled from 'styled-components/native'
// Hooks
import useTranslation from '../../../../../../hooks/useTranslation'
import useCurrentUser from '../../../../../../hooks/useCurrentUser'
import useDynamicForm from '../../../../../../hooks/useDynamicForm'
import useIsSmallScreen from '../../../../../../hooks/useIsSmallScreen'
import useSubmissionAverageMutation from '../../../../hooks/useSubmissionAverageMutation'
import { useSetUnsavedAdvisorChanges } from '../../../../../../hooks/unsavedAdvisorChanges/unsavedAdvisorChangesManager'
// Components
import Button from '../../../../../../ui-library/Button'
import { Flex } from '../../../../../../components/FlexBox'
import UnsavedAdvisorChangesModal from '../../../../../../components/UnsavedAdvisorChangesModal'
// Types
import {
  CallForSubmission,
  OriginSourceEnum,
  SubmissionReview
} from '../../../../../../types'
import {
  AnswerEntityTypeEnum,
  QuestionTypeEnum,
  ValidationPhaseEnum
} from '../../../../../../types/form'
// Styled components
const Container = styled(Flex).attrs(() => ({
  w: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start'
}))`
  min-height: 15vh;
`

const ButtonContainer = styled(Flex).attrs(({ isSmallScreen }) => ({
  w: '100%',
  flexWrap: 'wrap',
  position: 'sticky',
  flexDirection: 'row',
  alignItems: isSmallScreen ? 'flex-end' : 'flex-start'
}))`
  bottom: 0px;
  justify-self: flex-end;
  ${({ theme: { colors } }) => `
    background-color: ${colors.appBackground};
  `}
`
const StyledButton = styled(Button).attrs(({ theme: { sizes } }) => ({
  type: 'solid',
  buttonStyle: { width: '100%' },
  accessibilityLabel: 'endOfScreen',
  containerStyle: {
    marginBottom: 0,
    width: sizes[6] + sizes[4]
  }
}))``

const FormContainer = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch'
}))``

interface RatingsProps {
  callForSubmission: CallForSubmission
  reviewFormId?: string
  isReadOnly: boolean
  submissionReview: SubmissionReview
  isInnovatorAlumni?: boolean
  onDirty?: (dirty: boolean) => any
}

const RatingsForm: FC<RatingsProps> = ({
  callForSubmission,
  reviewFormId,
  submissionReview,
  isReadOnly,
  onDirty,
  isInnovatorAlumni
}) => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const { currentUserId } = useCurrentUser()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { resetState } = useSetUnsavedAdvisorChanges()
  const { updateAverage } = useSubmissionAverageMutation(callForSubmission?.id)

  // Memo
  const favQuestion = useMemo(() => {
    return callForSubmission?.reviewFormQuestions?.find(
      question =>
        question.type === QuestionTypeEnum.Checkbox &&
        question.questionText?.toLowerCase().includes('favorite')
    )
  }, [callForSubmission?.reviewFormQuestions])

  const feedbackQuestions = useMemo(() => {
    return callForSubmission?.reviewFormQuestions?.filter(
      question =>
        question.type === QuestionTypeEnum.Textarea &&
        question.questionText?.toLowerCase().includes('feedback')
    )
  }, [callForSubmission?.reviewFormQuestions])

  const advisorFav = useMemo(() => {
    return callForSubmission?.advisorsFavCount?.find(
      ac => ac.id === currentUserId
    )
  }, [callForSubmission])

  const submissionBelongsToTheFavorites: boolean = useMemo(
    () =>
      advisorFav?.favAnswers?.length && submissionReview?.formAnswerId
        ? advisorFav?.favAnswers?.some(
            formAnswer =>
              formAnswer.formSubmissionId === submissionReview?.formAnswerId
          )
        : false,
    [advisorFav, submissionReview?.formAnswerId]
  )

  const initialFavCount = useMemo(() => advisorFav?.favCount ?? 0, [
    advisorFav?.favCount
  ])

  // States
  const [advisorFavCount, setAdvisorFavCount] = useState(initialFavCount)

  // Effects
  useEffect(() => {
    setAdvisorFavCount(advisorFav?.favCount ?? 0)
  }, [initialFavCount])

  // Events
  const shouldDisableFavQuestion = useCallback(() => {
    return !submissionBelongsToTheFavorites
      ? advisorFavCount >= callForSubmission.advisorMaxFav
      : false
  }, [
    callForSubmission?.advisorMaxFav,
    advisorFavCount,
    submissionBelongsToTheFavorites
  ])

  const updateScore = useCallback(
    async (_, formData) => {
      if (formData) {
        await updateAverage({
          variables: {
            submissionId: submissionReview?.submissionId
          }
        })
        resetState()
      }
    },
    [updateAverage, resetState, submissionReview?.submissionId]
  )

  const onCheckboxChange = useCallback(
    ({ initialValue, newValue }) => {
      let newFavCount

      if (initialValue === newValue) {
        newFavCount = initialFavCount
      } else {
        if (newValue && !initialValue) {
          newFavCount = initialFavCount + 1
        } else {
          newFavCount = initialFavCount - 1
        }
      }

      setAdvisorFavCount(newFavCount)
    },
    [initialFavCount, advisorFavCount]
  )

  // Formik
  const formQuestionConditions = useMemo(() => {
    let conditions = {}

    if (favQuestion) {
      conditions[favQuestion.id] = {
        isDisabled: shouldDisableFavQuestion,
        customMsg: `${advisorFavCount}/${callForSubmission?.advisorMaxFav}`,
        onChange: onCheckboxChange
      }
    }

    if (feedbackQuestions?.length && isInnovatorAlumni) {
      feedbackQuestions.forEach(question => {
        conditions[question.id] = {
          helperTextOverride: t(
            'curation:advisors:advise:alumniHelperTextOverride',
            {
              helperText: question.configData?.helperText
            }
          )
        }
      })
    }
    return conditions
  }, [
    advisorFavCount,
    submissionBelongsToTheFavorites,
    feedbackQuestions,
    favQuestion,
    isInnovatorAlumni
  ])

  const {
    form,
    loadingFormData,
    helpers,
    component: dynamicForm
  } = useDynamicForm({
    isReadOnly,
    onDirty,
    formId: reviewFormId,
    formSubmissionId: submissionReview?.formAnswerId,
    formPhase: ValidationPhaseEnum.Review,
    answerEntityType: AnswerEntityTypeEnum.InnovationReview,
    originSource: OriginSourceEnum.Innovation,
    onSubmit: updateScore,
    callForSubmission,
    beforeRemoveDisabled: true,
    isInnovatorAlumni,
    ...(favQuestion || feedbackQuestions ? { formQuestionConditions } : {})
  })

  const validateForm = useCallback(async () => {
    const errors = await form.validateForm()
    helpers.setAllFieldsTouched()

    return helpers.getFormErrorCount(errors)
  }, [form, helpers])

  const submitReview = useCallback(async () => {
    setIsSubmitting(true)

    try {
      const errors = await validateForm()
      if (!errors) {
        await form.submitForm()
        helpers.resetAllTouchFields()
      }
    } catch (submitReviewError) {
      console.log({ submitReviewError })
    } finally {
      setIsSubmitting(false)
    }
  }, [form, validateForm])

  return (
    <Container>
      <FormContainer>
        <FormikProvider value={form}>{dynamicForm}</FormikProvider>
        <UnsavedAdvisorChangesModal
          formik={form}
          isReadOnly={isReadOnly}
          loadingFormData={loadingFormData}
        />
      </FormContainer>
      {!loadingFormData ? (
        <ButtonContainer isSmallScreen={isSmallScreen}>
          <StyledButton
            onPress={submitReview}
            isProcessing={isSubmitting}
            title={t('curation:buttons:submit')}
            disabled={loadingFormData || isSubmitting}
          />
        </ButtonContainer>
      ) : null}
    </Container>
  )
}

export default RatingsForm
