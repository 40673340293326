import React from 'react'
import { ActivityIndicator } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

const Container = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 50px;
`

const Spinner = styled(ActivityIndicator).attrs(({ theme, spinnerSize }) => ({
  size: spinnerSize || 'small',
  color: theme.colors.primary
}))``

export const LoadingIndicator = ({ size = 'small', style = {} }) => {
  const theme = useTheme()
  return (
    <Container style={[{ alignItems: 'center' }, style]}>
      <Spinner theme={theme} spinnerSize={size} />
    </Container>
  )
}
