import React from 'react'
import { FlatList, View } from 'react-native'
import styled from 'styled-components/native'
import { Text as TText } from 'react-native-elements'
import { Flex } from '../../../FlexBox'
import useFeatureFlag from '../../../../hooks/useFeatureFlag'

export const ToastContainer = styled(Flex)`
  ${({ theme }) => `
    padding: ${theme.space[2]}px;
    border-radius: 3px;
    margin-bottom: ${theme.space[3]}px;
  `}
`
export const ToastText = styled(TText)`
  ${({ theme }) => `
    color: ${theme.colors.primaryPalette.white};
    font-size: ${theme.sizes[3]}px;
  `}
`

export const ModalTitle = styled(TText)`
  ${({ theme }) => `
    font-size: ${theme.sizes[3] + theme.sizes[1]}px;
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: ${theme.sizes[2]};
  `}
`

export const TitleView = styled(View)`
  width: 100%;
  height: '100%;
`

export const SubTitleView = styled(View)`
  width: 100%;
`

export const InnerTableContainer = styled(View)`
  ${({ isSticky }) => (isSticky ? 'overflow-y: hidden;' : 'overflow-y: auto;')}
  height: 100%;
  flex-shrink: 1;
`

export const DynamicTableHeaderComponents = styled(View)`
  ${({ isAlternativeView }) => (isAlternativeView ? 'display: flex' : '')};
  position: relative;
  z-index: 2;
  background: white;
  ${({ isAlternativeView }) =>
    isAlternativeView ? 'flex-direction: row' : ''};
`

export const DynamicSearchBarContainer = styled(View)`
  display: flex;
  flex-direction: row;
  height: ${({ theme }) => theme.sizes[5]}px;
  border-radius: 12px;
  margin: ${({ theme }) => theme.space[2]}px 0
    ${({ theme }) => theme.space[2]}px 0;
  width: ${({ isAlternativeView }) => (isAlternativeView ? 30 : 100)}%;
  background-color: ${({ isAlternativeView }) =>
    isAlternativeView ? 'transparent' : '#d9d9d9'};
`

export const SearchComponentContainer = styled(View)`
  height: 100%;
  justify-content: center;
  width: ${({ isAlternativeView }) => (isAlternativeView ? 100 : 50)}%;
  background-color: ${({ backgroundColor }) => backgroundColor ?? ''};
`

export const HeaderButtonsContainer = styled(View)`
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
  padding-right: ${({ theme }) => theme.space[3]}px;
  justify-content: flex-end;
`

export const ButtonContainer = styled(View)`
  justify-content: center;
  height: 100%;
  width: ${({ theme }) => theme.sizes[6] + theme.sizes[4]}}px;
  margin-left: ${({ theme }) => theme.sizes[2]}}px;
`

export const DynamicFilterContainer = styled(View)`
  ${({ isAlternativeView }) => (isAlternativeView ? 'width: fit-content' : '')};
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme, hasFilters }) =>
    hasFilters ? theme.space[3] : 0}px;
  margin-top: ${({ theme }) => theme.space[3]}px;
  ${({ isAlternativeView }) =>
    isAlternativeView ? 'align-items: center' : ''};
`

export const MainContainer = styled(View)`
  height: 100%;
  width: 100%;
`
export const DynamicTableText = styled(TText)``

export const StyledFlatList = styled(FlatList)`
  ${({ isSticky }) =>
    isSticky ? 'overflow-y: scroll;' : 'overflow-y: hidden;'}
`

export const FlatTableContainer = styled(View)`
  ${({ width }) => (width ? `width: ${width}%` : 'auto')};
  height: 100%;
`

export const DynamicTableContainer = styled(View)`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  ${({ isSticky }) => (isSticky ? 'height: 98%;' : 'height: max-content;')}
`

export const TableScrollViewContainer = styled(View)`
  ${({ width }) => (width ? `width: ${width}%` : '')};
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`

export const FilterTitleContainer = styled(View)`
  justify-content: center;
  margin-right: ${({ theme }) => theme.space[3]}px;
  font-family: sans-serif;
`

export const AlternatingTableRowContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => theme.sizes[0]} ${({ theme }) => theme.sizes[3]}px;
  margin-bottom: ${({ theme }) => theme.sizes[3]}px;
  background-color: ${({ rebrand, theme, isRowSelected }) =>
    isRowSelected
      ? theme.colors.primaryPalette.black
      : rebrand
      ? theme.colors.containerBackground
      : theme.colors.tableRowAlternate};
  height: ${({ theme, rowHeight }) => rowHeight ?? theme.sizes[5]}px;
  ${({ theme, leftTable }) =>
    leftTable
      ? `
      border-top-left-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      border-bottom-left-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      `
      : `
      border-top-right-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      border-bottom-right-radius: ${theme.sizes[1] + theme.sizes[2]}px;
      `}
  ${({ theme, hover, isRowSelected }) =>
    hover && !isRowSelected
      ? `
    background-color: ${theme.colors.tableRowHover};
  `
      : ``}
  ${({ theme, isSelected }) =>
    isSelected
      ? `
      border-top-width: 1px;
      border-top-color: ${theme.colors.darkIcon};
      border-bottom-width: 1px;
      border-bottom-color: ${theme.colors.darkIcon};
      `
      : ``}
  ${({ theme, isSelected, leftTable }) =>
    isSelected
      ? leftTable
        ? `
          border-left-width: 1px;
          border-left-color: ${theme.colors.darkIcon};
        `
        : `
          border-right-width: 1px;
          border-right-color: ${theme.colors.darkIcon};
        `
      : ``}
  ${({ isSticky }) =>
    isSticky
      ? `
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      `
      : ''}
  
  ${({ rebrand, theme }) =>
    rebrand &&
    `
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-width: 1px;
      border-bottom-color: ${theme.colors.tableRowHeader}
      margin-bottom: 0px;
      padding: ${theme.sizes[2]}px;
      `}
`

export const AlternatingHoverableRow = ({ children, ...rest }: any) => {
  const rebrand = useFeatureFlag('innovationRebrandPlatform')
  return (
    <AlternatingTableRowContainer {...rest} rebrand={rebrand}>
      {children}
    </AlternatingTableRowContainer>
  )
}
