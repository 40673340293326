import React, { FC, useCallback } from 'react'

import { FormikProps } from 'formik'
// Hooks
import useTranslation from '../hooks/useTranslation'
import {
  useGetUnsavedAdvisorChanges,
  useSetUnsavedAdvisorChanges
} from '../hooks/unsavedAdvisorChanges/unsavedAdvisorChangesManager'
import usePreventUnsavedAdvisorChanges from '../hooks/unsavedAdvisorChanges/usePreventUnsavedAdvisorChanges'
// Components
import { UnsavedChangesDialog } from './layout/sharedComponents'

interface UnsavedAdvisorChangesModalProps {
  formik: FormikProps<any>
  isReadOnly?: boolean
  loadingFormData?: boolean
}

const UnsavedAdvisorChangesModal: FC<UnsavedAdvisorChangesModalProps> = ({
  formik,
  isReadOnly = false,
  loadingFormData = false
}) => {
  const { t } = useTranslation()
  const { dialog, onNavigate } = useGetUnsavedAdvisorChanges()
  const { resetState, setDialogState } = useSetUnsavedAdvisorChanges()

  // Dialog events
  const onCloseDialog = () => {
    setDialogState(false)
  }
  const onSubmitDialog = useCallback(async () => {
    resetState()
    onNavigate?.()
  }, [onNavigate, onNavigate])

  usePreventUnsavedAdvisorChanges(formik, isReadOnly, loadingFormData)

  return (
    <UnsavedChangesDialog
      description={t('common:dialogs:unsavedAdvisorChanges:description')}
      showDialog={dialog}
      setShowDialog={onCloseDialog}
      discardChangesCallback={onCloseDialog}
      saveChangesCallback={() => {}}
      actions={[
        {
          title: t('common:dialogs:unsavedChanges:closeBtn'),
          type: 'outline',
          onPress: onCloseDialog
        },
        {
          title: t('common:dialogs:unsavedChanges:discardBtn'),
          onPress: onSubmitDialog
        }
      ]}
    />
  )
}

export default UnsavedAdvisorChangesModal
