import React, { useState } from 'react'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import EllipsizedText from './EllipsizedText'
import { Flex } from '../../../../../FlexBox'

const StyledEllipsizedTextH = styled(EllipsizedText)`
  cursor: 'pointer';
  ${({ hover, theme: { colors, fontWeights } }) => `
    font-weight: ${fontWeights.medium};
    color: ${hover ? colors.buttonHover : colors.buttonBackground};
  `}
`
const StyledEllipsizedText = styled(EllipsizedText)`
  cursor: 'auto';
  ${({ hover, theme: { colors, fontWeights } }) => `
    font-weight: ${fontWeights.medium};
    color: ${hover ? colors.buttonHover : colors.buttonBackground};
  `}
`

const TitleContainer = styled(View)`
  flex-direction: column;
  width: 100%;
  min-height: 44px;
  justify-content: center;
`

const LabelAndValueContainer = styled(View)`
  border-top: 1px solid ${props => props.theme.colors.inputBorder};
  padding: 10px 0;
`

interface TitleCellItem {
  title: string
  subtitle?: string
}

interface TitleCellProps {
  item: TitleCellItem
  isValueHighlighted?: boolean
  onPress?: () => void
  style?: any
}

interface SubtitleCellProps {
  isValueHighlighted?: boolean
  onPress?: () => void
  style?: any
  text?: any
}

interface LabelAndValueCellProps {
  label: string
  title: string
  subtitle?: string | JSX.Element
  onPress?: () => void
  isValueHighlighted?: boolean
  containerStyles?: any
  style?: any
}

const FormatTitle = ({
  title,
  onPress,
  styles,
  hover,
  isValueHighlighted = true
}: any) => {
  const StyledElipsizedComponent = isValueHighlighted
    ? StyledEllipsizedTextH
    : StyledEllipsizedText
  return (
    <TouchableOpacity onPress={onPress}>
      <StyledElipsizedComponent style={styles} hover={hover}>
        {title}
      </StyledElipsizedComponent>
    </TouchableOpacity>
  )
}

const TitleCell = ({
  item,
  onPress,
  isValueHighlighted,
  style = {}
}: TitleCellProps) => {
  const [hover, setHover] = useState(false)
  return (
    <TitleContainer
      testID={'productName'}
      style={style}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <FormatTitle
        title={item?.title}
        onPress={onPress}
        hover={hover}
        isValueHighlighted={isValueHighlighted}
      />
      <EllipsizedText>{item?.subtitle}</EllipsizedText>
    </TitleContainer>
  )
}

export const SubtitleCell = ({
  text,
  style = {},
  onPress
}: SubtitleCellProps) => {
  return (
    <TitleContainer>
      <EllipsizedText style={style} onPress={onPress}>
        {text}
      </EllipsizedText>
    </TitleContainer>
  )
}
const rightAlignedStyle = {
  textAlign: 'right'
}

export const LabelAndTitleCell = ({
  label = '',
  title,
  subtitle,
  onPress,
  isValueHighlighted = false,
  containerStyles = {},
  style = {}
}: LabelAndValueCellProps) => {
  return (
    <LabelAndValueContainer
      isValueHighlighted={isValueHighlighted}
      style={containerStyles}
    >
      <Flex flexDirection="row" justifyContent={'space-between'} w="100%">
        <View
          style={{
            flexBasis: '40%'
          }}
        >
          <EllipsizedText style={style}>{label}</EllipsizedText>
        </View>
        <View
          style={{
            flexBasis: '60%',
            justifyContent: 'flex-end'
          }}
        >
          {isValueHighlighted ? (
            <FormatTitle
              title={title}
              onPress={onPress}
              styles={{ ...rightAlignedStyle, ...style }}
            />
          ) : (
            <EllipsizedText style={{ ...rightAlignedStyle, ...style }}>
              {title}
            </EllipsizedText>
          )}
          {subtitle && (
            <EllipsizedText style={{ ...rightAlignedStyle, ...style }}>
              {subtitle}
            </EllipsizedText>
          )}
        </View>
      </Flex>
    </LabelAndValueContainer>
  )
}

export default TitleCell
