import { useMemo } from 'react'
import gql from 'graphql-tag'
import { useRecoilCallback } from 'recoil'

import useTranslation from './useTranslation'
import useQuery from './useQuery'
import { FormData } from '../types/form'
import {
  setQuestionAnswer,
  setQuestionAnswers,
  setQuestionCallback
} from '../components/DynamicForm/recoil/questionAtomFamily'
import { setQuestionGroupCallback } from '../components/DynamicForm/recoil/questionGroupAtomFamily'
import { setQuestionDependencyCallback } from '../components/DynamicForm/recoil/questionDependencyAtomFamily'
import QuestionFieldsFragment from '../graphql/QuestionFieldsFragment'
import QuestionGroupFieldsFragment from '../graphql/QuestionGroupFieldsFragment'
import QuestionGroupEntityMapFieldsFragment from '../graphql/QuestionGroupEntityMapFieldsFragment'
import AnswerFieldsFragment from '../graphql/AnswerFieldsFragment'

const formQuery = gql`
  query getFormByName($type: String!, $answerEntityId: String) {
    formByType(type: $type) {
      id
      name
      questions {
        ...QuestionFieldsFragment
        answer(answerEntityId: $answerEntityId) {
          ...AnswerFieldsFragment
        }
      }
      questionGroups {
        ...QuestionGroupFieldsFragment
      }
      questionDependencies {
        id
        type
        value
        question1Id
        question2Id
      }
      formGroup {
        ...QuestionGroupFieldsFragment
        questionGroupEntityMaps {
          ...QuestionGroupEntityMapFieldsFragment
          entity {
            ... on Question {
              id
            }
            ... on QuestionGroup {
              ...QuestionGroupFieldsFragment
              questionGroupEntityMaps {
                ...QuestionGroupEntityMapFieldsFragment
                entity {
                  ... on Question {
                    id
                  }
                  ... on QuestionGroup {
                    ...QuestionGroupFieldsFragment
                    questionGroupEntityMaps {
                      ...QuestionGroupEntityMapFieldsFragment
                      entity {
                        ... on Question {
                          id
                        }
                        ... on QuestionGroup {
                          ...QuestionGroupFieldsFragment
                          questionGroupEntityMaps {
                            ...QuestionGroupEntityMapFieldsFragment
                            entity {
                              ... on Question {
                                id
                              }
                              ... on QuestionGroup {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${QuestionFieldsFragment}
  ${QuestionGroupFieldsFragment}
  ${QuestionGroupEntityMapFieldsFragment}
  ${AnswerFieldsFragment}
`

const formByIdQuery = gql`
  query getFormById($id: String!, $formSubmissionId: String) {
    formById(id: $id) {
      id
      name
      questions {
        ...QuestionFieldsFragment
        answer(formSubmissionId: $formSubmissionId) {
          ...AnswerFieldsFragment
        }
      }
      questionGroups {
        ...QuestionGroupFieldsFragment
        questionGroupEntityMaps {
          entity {
            ... on QuestionGroup {
              id
            }
            ...QuestionFieldsFragment
          }
        }
      }
      questionDependencies {
        id
        type
        value
        question1Id
        question2Id
      }
      formGroup {
        ...QuestionGroupFieldsFragment
        questionGroupEntityMaps {
          ...QuestionGroupEntityMapFieldsFragment
          entity {
            ...QuestionFieldsFragment
            ... on QuestionGroup {
              ...QuestionGroupFieldsFragment
              questionGroupEntityMaps {
                ...QuestionGroupEntityMapFieldsFragment
                entity {
                  ...QuestionFieldsFragment
                  ... on QuestionGroup {
                    ...QuestionGroupFieldsFragment
                    questionGroupEntityMaps {
                      ...QuestionGroupEntityMapFieldsFragment
                      entity {
                        ...QuestionFieldsFragment
                        ... on QuestionGroup {
                          ...QuestionGroupFieldsFragment
                          questionGroupEntityMaps {
                            ...QuestionGroupEntityMapFieldsFragment
                            entity {
                              ...QuestionFieldsFragment
                              ... on QuestionGroup {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${QuestionFieldsFragment}
  ${QuestionGroupFieldsFragment}
  ${QuestionGroupEntityMapFieldsFragment}
  ${AnswerFieldsFragment}
`

interface QueryProps {
  type?: string
  formId?: string
  answerEntityId?: string
  formSubmissionId?: string
}

interface FormQueryObject {
  formData?: FormData
  loadingFormData: boolean
  setQuestionWithAnswers: (questions: any, answers: any) => void
  setDiffQuestionWithAnswers: (questions: any, answers: any) => void
  refetchFormData: () => void
}

const useFormQuery = ({
  type,
  formId,
  answerEntityId,
  formSubmissionId
}: QueryProps): FormQueryObject => {
  const { t } = useTranslation()
  const setQuestions = useRecoilCallback(setQuestionCallback)
  const setQuestionWithAnswers = useRecoilCallback(setQuestionAnswer)
  const setDiffQuestionWithAnswers = useRecoilCallback(setQuestionAnswers)
  const setQuestionGroups = useRecoilCallback(setQuestionGroupCallback)
  const setQuestionDependencies = useRecoilCallback(
    setQuestionDependencyCallback
  )

  const variables = useMemo(
    () => (type ? { type, answerEntityId } : { id: formId, formSubmissionId }),
    [type, formId, answerEntityId, formSubmissionId]
  )

  const { data, loading, refetch } = useQuery(
    type ? formQuery : formByIdQuery,
    {
      variables,
      fetchPolicy: 'no-cache',
      onCompleted: formData => {
        setQuestions(
          formData?.formByType?.questions || formData?.formById?.questions
        )
        setQuestionGroups(
          formData?.formByType?.questionGroups ||
            formData?.formById?.questionGroups
        )
        setQuestionDependencies(
          formData?.formByType?.questionDependencies ||
            formData?.formById?.questionDependencies
        )
      },
      skip: !(type && answerEntityId) && !(formId && formSubmissionId),
      errorMessage: t('error:form:getForm')
    }
  )

  return {
    formData: type ? data?.formByType : data?.formById,
    refetchFormData: refetch,
    loadingFormData: loading,
    setQuestionWithAnswers,
    setDiffQuestionWithAnswers
  }
}

export default useFormQuery
