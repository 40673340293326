import { useCallback, useEffect } from 'react'

import { FormikProps } from 'formik'
import { useFocusEffect } from '@react-navigation/native'
// Hooks
import { useSetUnsavedAdvisorChanges } from './unsavedAdvisorChangesManager'

const usePreventUnsavedAdvisorChanges = (
  formik: FormikProps<any>,
  isReadOnly: boolean,
  loadingFormData = false
) => {
  const { resetState, setHasUnsaved } = useSetUnsavedAdvisorChanges()

  useEffect(() => {
    if (!isReadOnly && !loadingFormData) {
      setHasUnsaved(Boolean(formik.dirty))
    }
  }, [formik.dirty, isReadOnly, loadingFormData])

  useFocusEffect(
    useCallback(() => {
      return () => {
        resetState()
      }
    }, [])
  )
}

export default usePreventUnsavedAdvisorChanges
