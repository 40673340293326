import React, { useState } from 'react'
import { path, split } from 'ramda'
import { withTheme } from 'styled-components/native'
import TextInput from '../../../ui-library/TextInput'
import useTranslation from '../../../hooks/useTranslation'

const StandardInput = ({
  item,
  itemMap,
  handlers,
  isEditable,
  label,
  theme
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(path(split('.', itemMap), item))

  const commonStyles = {
    textInputs: {
      width: '',
      margin: 0,
      borderWidth: 0,
      backgroundColor: theme.colors.tableCellBackground,
      borderRadius: 0,
      color: theme.colors.disabled
    },
    containerStyles: { width: '', padding: 0, margin: 0 },
    dropDownStyles: {
      marginBottom: 0,
      width: 'auto',
      minWidth: '100%',
      borderWidth: 0,
      borderRadius: 0,
      backgroundColor: theme.colors.tableCellBackground
    }
  }

  return (
    <TextInput
      value={value}
      onChangeText={text => setValue(text)}
      label={label && t(label)}
      editable={isEditable}
      onBlur={() =>
        isEditable && handlers && handlers.update({ ...item, [itemMap]: value })
      }
      selectTextOnFocus={isEditable}
      style={commonStyles.textInputs}
      containerStyles={commonStyles.containerStyles}
      tabIndex={-1}
    />
  )
}

export default withTheme(StandardInput)
