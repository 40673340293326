import { useCallback } from 'react'
import linkingConfiguration from '../navigation/LinkingConfiguration'
import { CommonActions, useRoute } from '@react-navigation/native'
import { useNavigation } from '@react-navigation/core'
import useHistoryRoutes from './useHistoryRoutes'
import {
  useGetUnsavedAdvisorChanges,
  useSetUnsavedAdvisorChanges
} from './unsavedAdvisorChanges/unsavedAdvisorChangesManager'

export default function useLinkToScreen() {
  const navigation = useNavigation()
  const { pushToHistory } = useHistoryRoutes()
  const { name: currentRouteName } = useRoute()

  const { hasUnsavedChanges } = useGetUnsavedAdvisorChanges()
  const { setUnsavedAdvisorState } = useSetUnsavedAdvisorChanges()

  const navigate = useCallback(
    (routeName: string, params = {}) => {
      const navAction = navActionFromScreenName(
        routeName,
        params,
        currentRouteName
      )

      // There's an issue with Tab.Navigators that does not save the correct history
      pushToHistory({
        screen: navAction.screen,
        params: navAction.params
      })

      navigation.dispatch(
        CommonActions.navigate(navAction.screen, navAction.params)
      )
    },
    [navigation, pushToHistory, currentRouteName]
  )

  const linkToScreen = useCallback(
    (routeName: string, params = {}) => {
      if (hasUnsavedChanges) {
        setUnsavedAdvisorState({
          onNavigate: () => navigate(routeName, params),
          dialog: true
        })
      } else {
        navigate(routeName, params)
      }
    },
    [navigation, pushToHistory, currentRouteName, hasUnsavedChanges]
  )

  return linkToScreen
}

/**
 * Helper functions
 */
export function navActionFromScreenName(
  screenName: string,
  params = {},
  relativeToScreenName: string | undefined = undefined
) {
  let navPath = screenToNavMap[screenName]

  // if given a screen to build relative to,
  // find the common navPath parent and drop
  // it and everything "above" it
  if (relativeToScreenName) {
    const relativeBaseNavPath = screenToNavMap[relativeToScreenName].map(
      e => e[0]
    )
    // find the point in the destination navPath where the paths converge
    const commonPathIndex = navPath.findIndex(
      p => relativeBaseNavPath.indexOf(p[0]) > -1
    )

    // find the point in the destination navPath where params are used
    //  they might have changed if overlapping with current page
    const firstPathIndexWithParams = navPath.reduce(
      (lastIndex, [, args], index) => (args.length ? index : lastIndex),
      0
    )

    // only build a navPath from the point at which is needed
    const sliceFrom = Math.max(commonPathIndex, firstPathIndexWithParams + 1)
    navPath = navPath.slice(0, sliceFrom)
  }

  if (!navPath)
    return console.error(`No route screen found by the name: ${screenName}`)

  return applyNavPathParams(navPath, params)
}

function applyNavPathParams(navPath, params) {
  // TODO: throw exception if missing non-optional param value
  // find wich params are using for navigation
  const pathParams = navPath.reduce((accum, np) => [...np[1], ...accum], [])
  // separate params that are extra
  const extraParams = {}
  Object.keys(params).forEach(key => {
    if (pathParams.indexOf(key) === -1) {
      extraParams[key] = params[key]
    }
  })
  return navPath.reduce(
    (nestedParams, [screen, levelParams]) => ({
      screen,
      params: { ...nestedParams, ...extractParams(levelParams, params) }
    }),
    extraParams
  )
}

function extractParams(paramNames, params) {
  return Object.fromEntries(paramNames.map(p => [p, params[p]]))
}

// NavMapEntry: [screenName: string, navPath: string[]]
type NavMapEntry = [string, string[]]

interface ScreenToUrlMap {
  [screenName: string]: string
}

interface ScreenToNavMap {
  [screenName: string]: NavMapEntry[]
}

interface ScreenNavigationMappings {
  screenToUrlMap: ScreenToUrlMap
  screenToNavMap: ScreenToNavMap
}

interface ScreenNavigationConfig {
  navPath: NavMapEntry[]
  url: string
}

type NamedScreenNavigationConfigEntry = [string, ScreenNavigationConfig]

/**
 * Recursive function that walks the linkingConfiguration tree
 * and returns a flattened registry of each named screen
 * and the associated combined URL and navigation path to get there.
 */
function getNavDataFromLinkingConfig(
  config: any,
  navConfig: ScreenNavigationConfig | null = null
): NamedScreenNavigationConfigEntry[] {
  // @ts-ignore
  return Object.entries(config.screens || {})
    .map(([routeName, routeConfig]: [string, any]) => {
      if (typeof routeConfig === 'string') {
        return [[routeName, joinPaths(navConfig, routeName, routeConfig)]]
      } else {
        const newNavConfig = joinPaths(navConfig, routeName, routeConfig.path)
        return [
          [routeName, newNavConfig],
          ...getNavDataFromLinkingConfig(routeConfig, newNavConfig)
        ]
      }
    })
    .flat()
}

/**
 * Helper function to build joined URL and NavPath for nested screens
 * Cleans up the recursive code a bit to extract this noise
 */
function joinPaths(baseConfig, routeName, pathSegment) {
  return {
    url: joinUrlPaths(baseConfig?.url || '', pathSegment),
    navPath: joinNavPaths(baseConfig?.navPath || [], routeName, pathSegment)
  }
}

/**
 * Join two url path strings
 */
function joinUrlPaths(a: string, b: string) {
  return (
    '/' +
    [
      ...(a || '').split('/').filter(Boolean),
      ...(b || '').split('/').filter(Boolean)
    ].join('/')
  )
}

/**
 * Append NavPath config onto a base nav Path
 */
function joinNavPaths(
  baseNavPath: NavMapEntry[],
  routeName: string,
  urlSection: string
): NavMapEntry[] {
  return [[routeName, getPathParams(urlSection)], ...baseNavPath]
}

/**
 * given a path snippet, extract a list of named parameters
 */
function getPathParams(path = '') {
  return path
    .split('/')
    .filter(s => s.startsWith(':'))
    .map(s => s.replace(/[:?]/g, ''))
}

/**
 * Given a list of Screen mapping entries, ensure no screen name is duplicated
 */
function ensureNoDuplicateScreens(screenNames: string[]) {
  const seen = new Set()
  screenNames.forEach(screenName => {
    if (seen.has(screenName)) {
      throw new Error(`Duplicate Screen Name found! ${screenName}`)
    }
    seen.add(screenName)
  })
}

/**
 * Trigger the walking of the linkingConfiguration tree
 * and generate the mappings needed.
 */
function getScreenNavigationMappings(config: any): ScreenNavigationMappings {
  const screenNavEntries = getNavDataFromLinkingConfig(config)
  ensureNoDuplicateScreens(screenNavEntries.map(([screenName]) => screenName))

  const screenToUrlMap = Object.fromEntries(
    screenNavEntries.map(([screen, { url }]) => [screen, url])
  )
  const screenToNavMap = Object.fromEntries(
    screenNavEntries.map(([screen, { navPath }]) => [screen, navPath])
  )

  return { screenToUrlMap, screenToNavMap }
}

/**
 * screenToUrlMap:
 *   mapping of screenName => url
 *   used in useTopic and useLinkToScreen
 *
 * screenToNavMap:
 *   mapping of screenName => [ path through nav structure to the screen with params along the way ]
 *   Not currently used, but can be used to support linkToScreen()
 *   which uses navigation.navigate() instead of linkTo() if needed.
 */
const { screenToNavMap } = getScreenNavigationMappings(
  linkingConfiguration.config
)
