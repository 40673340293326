import React from 'react'
import styled, { withTheme } from 'styled-components/native'

import useTranslation from '../../../hooks/useTranslation'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'

import { Text } from '../../../components/common/Text'
import { Flex } from '../../../components/FlexBox'

import { Trans } from 'react-i18next'
import Link from '../../../components/Link'
import SubmissionWindowsCalendar from './SubmissionCalendar'
import useFeatureFlag from '../../../hooks/useFeatureFlag'

export const Body = styled.Text`
  color: 'rgba(255, 255, 255, 0.84)'
    ${({ theme: { isSmallScreen, sizes, fontWeights } }) => `
    font-weight: ${fontWeights.light}; 
    line-height: ${sizes[3] + sizes[2]}px;
    fontSize: ${isSmallScreen ? 14 : 18}px;
  `};
`

const PageLink = ({ to, title, linkStyles }) => {
  const { t } = useTranslation()

  return (
    <Link to={to} title={t(title)} target="_blank" style={linkStyles}>
      {t(title)}
    </Link>
  )
}

const LoginInfo = ({ theme }) => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const rebrand = useFeatureFlag('innovationRebrandPlatform')

  const defaultLinkStyles = {
    color: theme.colors.brightPrimary,
    textDecoration: 'none',
    fontStyle: 'italic'
  }

  return (
    <Flex
      flexDirection="column"
      flexBasis={isSmallScreen ? 'auto' : '45%'}
      width={isSmallScreen ? '100%' : '45%'}
      justifyContent="center"
      alignItems="center"
      maxWidth={550}
      padding={isSmallScreen ? '0 24px 24px 15px' : '0 16px'}
    >
      <Text
        styles={{
          fontWeight: '300',
          fontSize: `${isSmallScreen ? 14 : 18}px`,
          lineHeight: '24px',
          color: 'rgba(255, 255, 255, 0.84)'
        }}
      >
        <Trans
          i18nKey="verifyTerms"
          defaults={
            rebrand
              ? t('auth:login:acceleratorDescription')
              : t('auth:login:description')
          }
          components={{
            linkOne: (
              <PageLink
                to="https://www.linkedin.com/company/ehir/"
                title="auth:login:linkedIn"
                linkStyles={defaultLinkStyles}
              />
            )
          }}
        />
      </Text>
      <SubmissionWindowsCalendar isHomeScreen={false} marginTop={30} />
    </Flex>
  )
}

export default withTheme(LoginInfo)
