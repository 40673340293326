import React, { FC } from 'react'
import styled, { useTheme } from 'styled-components/native'

import {
  AddButton,
  ProductContainer,
  ProductContent,
  ProductTitle,
  Title
} from './SharedComponents'
import { Flex } from '../../../components/FlexBox'
import { Company, InnovatorProduct } from '../../../types'
import useTranslation from '../../../hooks/useTranslation'
import TouchableIcon from '../../../components/icon/TouchableIcon'
import Button from '../../../ui-library/Button'
import CollapsibleContainer from './common/CollapsibleContainer'
import DateService from '../../../services/dateService'
import { ScrollView } from 'react-native'
import useInnovationAccess from '../../../hooks/useInnovationAccess'
import Icon from '../../../components/icon'
import useFeatureFlag from '../../../hooks/useFeatureFlag'

const StyledScrollView = styled(ScrollView)`
  width: 100%;
  max-height: 300px;
`
interface CompanyProductListProps {
  company?: Company
  productSelectedId?: string | null
  onProductSelected?: (string) => void
  openProductDialog: (boolean) => void
}

const CompanyProductList: FC<CompanyProductListProps> = ({
  company,
  productSelectedId,
  onProductSelected,
  openProductDialog
}) => {
  const { t } = useTranslation()
  const { space, colors } = useTheme()
  const { innovatorTeam: isInnovator } = useInnovationAccess()
  const innovationInnovatorDirectoryEnabled = useFeatureFlag(
    `innovationInnovatorDirectory`
  )

  const rebrand = useFeatureFlag('innovationRebrandPlatform')

  // JC: we auto-open the first product after loading
  // @ts-ignore
  const product = company?.products?.length > 0 ? company?.products[0] : null
  const canAddProducts = company?.canAddInnovatorProducts
  return (
    <Flex marginTop={space[1]}>
      {product ? (
        <CollapsibleContainer
          testID={'productContainer'}
          title={t('directoryProfile:products:title')}
          subtitle={'Current company products'}
          isOpen={isInnovator}
        >
          <Flex>
            {canAddProducts ? (
              <Flex alignItems="flex-start" style={{ paddingBottom: space[2] }}>
                {rebrand ? (
                  <AddButton
                    title={t('directoryProfile:products:newProduct')}
                    onPress={() => {
                      openProductDialog(true)
                    }}
                  />
                ) : (
                  <Button
                    title={t('directoryProfile:products:addProduct')}
                    onPress={() => {
                      openProductDialog(true)
                    }}
                    style={{
                      marginTop: space[2]
                    }}
                    hoverStyles={{
                      buttonStyle: {
                        backgroundColor: colors.tints.grays.g200,
                        borderColor: colors.tints.grays.g200
                      },
                      titleStyle: {
                        color: colors.primaryPalette.white
                      }
                    }}
                  />
                )}
              </Flex>
            ) : null}

            <StyledScrollView>
              {company?.products?.map((product: InnovatorProduct) => {
                const isSelected = product.id === productSelectedId
                const productInfo = product?.submission?.submissionInfo
                const productDelegation = product?.delegation?.name
                const publishedLog = product?.publishedLog
                const hasBeenPublished = publishedLog
                  ? publishedLog?.isPublished
                  : false

                const lastUpdatedAt =
                  product?.updatedAt || product?.submission?.updatedAt
                    ? `${t(
                        'innovatorDirectory:lastUpdated'
                      )}: ${DateService.getFormat(
                        product?.updatedAt || product?.submission?.updatedAt,
                        'MM/DD/YYYY'
                      )}`
                    : null

                const publishedAt =
                  hasBeenPublished && publishedLog?.publishedAt
                    ? `${t(
                        'innovatorDirectory:publishedAt'
                      )}: ${DateService.getFormat(
                        publishedLog?.publishedAt,
                        'MM/DD/YYYY'
                      )}`
                    : lastUpdatedAt
                return (
                  <ProductContainer
                    key={`product-${product.id}`}
                    isSelected={isSelected}
                    onPress={() => {
                      onProductSelected && onProductSelected(product.id)
                    }}
                    rebrand={rebrand}
                  >
                    <Flex
                      style={{
                        maxWidth: '80%'
                      }}
                    >
                      <ProductTitle isSelected={isSelected} rebrand={rebrand}>
                        {productInfo?.name}
                      </ProductTitle>
                      <ProductContent isSelected={isSelected} rebrand={rebrand}>
                        {productDelegation ? `${productDelegation}` : ''}
                        {productDelegation && productInfo?.categoryLabel
                          ? ' - '
                          : ''}
                        {productInfo?.categoryLabel}
                      </ProductContent>
                      <ProductContent
                        hasBeenPublished={
                          hasBeenPublished &&
                          innovationInnovatorDirectoryEnabled
                        }
                        isSelected={isSelected}
                        rebrand={rebrand}
                      >
                        {hasBeenPublished &&
                        innovationInnovatorDirectoryEnabled ? (
                          <Icon
                            name="fileReadyCircle"
                            width={15}
                            height={15}
                            color={colors.saved}
                          />
                        ) : null}
                        {hasBeenPublished && innovationInnovatorDirectoryEnabled
                          ? publishedAt
                          : lastUpdatedAt}
                      </ProductContent>
                    </Flex>
                    <Flex flexDirection="row">
                      <TouchableIcon
                        name="chevron-right"
                        width={16}
                        height={16}
                        color={
                          isSelected
                            ? rebrand
                              ? colors.buttonBackground
                              : colors.primaryPalette.white
                            : colors.primaryPalette.black
                        }
                        onPress={() => {}}
                      />
                    </Flex>
                  </ProductContainer>
                )
              })}
            </StyledScrollView>
          </Flex>
        </CollapsibleContainer>
      ) : canAddProducts ? (
        <Flex marginBottom={space[1]}>
          <Title>{t('directoryProfile:products:noProductFound')}</Title>
          <Button
            title={t('directoryProfile:products:addProduct')}
            onPress={() => {
              openProductDialog(true)
            }}
            style={{
              marginTop: space[2]
            }}
            hoverStyles={{
              buttonStyle: {
                backgroundColor: colors.tints.grays.g200,
                borderColor: colors.tints.grays.g200
              },
              titleStyle: {
                color: colors.primaryPalette.white
              }
            }}
          />
        </Flex>
      ) : null}
    </Flex>
  )
}

export default CompanyProductList
