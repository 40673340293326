import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import styled from 'styled-components/native'
import {
  Container,
  Title as FormTitle
} from '../../../../components/DynamicForm/layout'
import { Text } from '../../../../components/common/Text'
import { formElementDict } from '../../../../components/DynamicForm'
import InputLabel from '../../../../ui-library/TextInput/Label'
import { EntityTypeEnum, FormData } from '../../../../types/form'
import { Flex } from '../../../../components/FlexBox'

export const StaffFeedbackView = styled(View)`
  margin-right: 15px;
`

export const StyledFlex = styled(Flex).attrs({
  w: '100%',
  flex: 1,
  flexDirection: 'column'
})`
  background-color: ${props => props.theme.colors.appBackground};
  border-radius: ${props => props.theme.radii[4]}px;
`

export const Spinner = styled(ActivityIndicator).attrs({
  size: 'small',
  color: props => props.theme.colors.primary
})

export const LoadingIndicator = () => (
  <Container>
    <Spinner />
  </Container>
)

const TitleContainer = styled(View)`
  ${({ theme }) => `
    width: 100%;
    background-color: ${theme.colors.appBackground};
    padding-left: 0;
    padding-top: ${theme.space[3]}px;
    border-style: solid;
    border-bottom-width: 1px;
    border-color: ${theme.colors.tabBorder}
`}
`

const TextLabel = styled(Text)`
  ${({ theme: { colors, fontWeights } }) => `
    color: ${colors.text2};
    fontSize: 14px;
    fontWeight: ${fontWeights.light};
`}
`
export const TextContent = styled(Text).attrs(props => ({
  style: {
    fontSize: `${props.theme.fontSizes[4]}px`,
    ...props.style
  }
}))``

export const Title = ({ text }) => (
  <TitleContainer>
    <FormTitle>{text}</FormTitle>
  </TitleContainer>
)

export const ContainerWithTitle = ({
  title,
  children,
  marginTop = undefined
}) => (
  <Flex
    flexDirection="column"
    maxWidth="100%"
    width="100%"
    marginTop={marginTop}
  >
    <Title text={title} />
    {children}
  </Flex>
)

export const Label = ({ children }) => <TextLabel>{children}</TextLabel>
export const Content = ({ style = {}, children }) => (
  <TextContent style={style}>{children}</TextContent>
)

const getQuestionAndAnswer = (type, item) => {
  const answer = item?.answer
  const { getData } = formElementDict[type]
  const data = Object.assign(getData(item), {
    answer
  })

  const containerStyles = data?.styles?.containerStyles || {}
  containerStyles.flexBasis = containerStyles.minWidth
  delete containerStyles.minWidth

  return (
    <View
      key={`${data.label}-${item?.id}`}
      style={[
        containerStyles,
        {
          paddingTop: 10,
          paddingBottom: 10
        }
      ]}
    >
      <View>
        <InputLabel
          label={data.label}
          withHelperText
          labelStyles={{
            marginLeft: '0px'
          }}
        />
        <View>{answer && <Content>{answer.value}</Content>}</View>
      </View>
    </View>
  )
}

const createFormElement = item => {
  if (item?.type in formElementDict) {
    return getQuestionAndAnswer(item.type, item)
  }
}

const DynamicForm = ({ formGroup }) => {
  const formComponents = formGroup?.questionGroupEntityMaps.map(item => {
    if (item?.entityType === EntityTypeEnum.Question) {
      return createFormElement(item.entity)
    }
  })
  return <>{formComponents}</>
}

export const createFormComponent = (formData: FormData) =>
  React.createElement(DynamicForm, {
    formGroup: formData?.formGroup
  })

export const FlexRow = ({ children }) => (
  <Flex
    flexDirection="row"
    flexWrap="wrap"
    justifyContent="space-between"
    w="100%"
    marginTop={2}
    marginBottom={3}
  >
    {children}
  </Flex>
)
