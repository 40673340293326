import { useDimensions } from '@react-native-community/hooks'
import { Breakpoint } from '../types/breakpoint'
import { useTheme } from 'styled-components/native'

const useBreakpoint = () => {
  const { width } = useDimensions().window
  const theme = useTheme()
  if (!theme) return Breakpoint.Full
  const { breakpoints } = theme

  if (width < breakpoints.phone) {
    return Breakpoint.Phone
  }

  if (width < breakpoints.tablet) {
    return Breakpoint.Tablet
  }

  if (width < breakpoints.desktop) {
    return Breakpoint.Desktop
  }

  return Breakpoint.Full
}

export default useBreakpoint
