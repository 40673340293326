import gql from 'graphql-tag'
import useQuery from '../../hooks/useQuery'

const legalStatusQuery = gql`
  query legalStatusQuery {
    isLegalUpToDate(location: web, agreements: [terms, privacy])
  }
`

const useLegalStatus = (options = {}) => {
  const { data, loading, error } = useQuery(legalStatusQuery, options)

  return { data, loading, error }
}

export default useLegalStatus
