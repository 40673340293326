import React, { useState } from 'react'
import { View, Text, TouchableOpacity, FlatList } from 'react-native'
import styled from 'styled-components/native'
import { Text as TText } from 'react-native-elements'

export const EmptyContainer = styled(View)`
  height: ${({ theme: { space } }) => space[5] - space[3]};
`

export const AutoCompleteDropdownContainer = styled(View)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.space[3]}px;
`

export const TableText = styled(TText)``

export const TableFlatList = styled(FlatList)``

export const SortButton = styled(TouchableOpacity)`
  width: 100%;
  align-self: center;
  align-items: center;
`

export const SortContainer = styled(View)`
  height: ${({ theme }) => theme.sizes[1] + theme.sizes[3]}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SortHandle = styled(Text)`
  font-size: 13px;
  padding-left: 5px;
`

export const TableContainer = styled(View)`
  flex: 1;
  max-height: 100%;
`
export const TableRowContainer = styled(View)`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.tableRowHeader};
  padding: 15px;
  ${({ singleTable, leftTable }) =>
    singleTable
      ? `border-radius: 12px;`
      : leftTable
      ? `border-top-left-radius: 12px; border-bottom-left-radius: 12px;
      `
      : `border-top-right-radius: 12px; border-bottom-right-radius: 12px;`}}
  ${({ isSticky }) => {
    return isSticky
      ? `border-top-right-radius: 12px; border-bottom-right-radius: 12px;`
      : ''
  }}
  ${({ marginLeft, marginRight, marginBottom }) => `
    margin-left: ${marginLeft || 0}px;
    margin-right: ${marginRight || 0}px;
    margin-bottom: ${marginBottom || 0}px;
  `};
`

export const AlternatingTableRowContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  border-radius: 60px;
  ${({ theme, rowStyle }) =>
    rowStyle === 'even'
      ? `
    background-color: ${theme.colors.containerBackground};
  `
      : ``}
  ${({ theme, hover }) =>
    hover
      ? `
    background-color: ${theme.colors.tableRowHover};
  `
      : ``}
`

export const TableColumn = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
`

export const TableCell = styled(View)`
  ${({ width }) => (width ? `width: ${width}%` : 'flex: 1')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}px` : 'unset')};
  display: flex;
  margin: ${({ theme, cellStyle }) =>
    cellStyle?.margin || theme.isSmallScreen ? 0 : '0px 5px 3px 0;'}
  flex-direction: column;
  max-height: ${({ theme }) =>
    theme.sizes[4] + theme.sizes[2] + theme.sizes[1]}px;
  overflow-y: ${({ hasSubtitle }) => (hasSubtitle ? 'visible' : 'scroll')};
`

export const DummyCell = styled(View)`
  ${({ width }) => (width ? `min-width: ${width}%` : 'flex: 1')};
  min-height: 30px;
  background-color: rgba(229, 229, 229, 0.54);
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;
  border-color: rgba(229, 229, 229, 0.54);
`
export const EmptyCell = styled(View)`
  background-color: transparent;
  ${({ width }) => (width ? `min-width: ${width}%` : 'flex: 1')};
  min-height: 30px;
`

export const EmptyText = styled(Text)`
  margin-left: 5px;
  margin-top: 5px;
  font-style: italic;
  opacity: 0.5;
`

export const HeaderText = styled(Text).attrs(props => ({
  style: {
    fontSize: `${props.theme.fontSizes[3]}px`,
    fontWeight: props.theme.fontWeights.light,
    opacity: props.theme.opacity.inputLabels,
    whiteSpace: 'nowrap',
    ...props.style
  }
}))``

export const SmallRowContainer = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.colors.inputBorder};
  border-radius: ${({ theme }) => theme.radii[3]}px;
  padding: ${({ theme }) => theme.space[3]}px;
`

export const AlternatingHoverableRow = ({ children, ...rest }: any) => {
  const [hover, setHover] = useState(false)

  const onMouseEnter = () => setHover(true)
  const onMouseLeave = () => setHover(false)

  return (
    <AlternatingTableRowContainer
      hover={hover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...rest}
    >
      {children}
    </AlternatingTableRowContainer>
  )
}

export const SmallRow = ({ children, ...rest }: any) => {
  return <SmallRowContainer {...rest}>{children}</SmallRowContainer>
}
