import React from 'react'
import FooterLinks from '../sharedComponents/FooterLinks'

import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'

import { Flex } from '../../../../components/FlexBox'
import W50Logo from '../../../../components/icon/W50Logo'

import { BaseContainer, StyledScrollView } from '../SharedStyledComponents'
import useFeatureFlag from '../../../../hooks/useFeatureFlag'
import AcceleratorLogo from '../../../../components/icon/AcceleratorLogo'

const ColumnLayout = ({ children }) => {
  const isSmallScreen = useIsSmallScreen()
  const rebrand = useFeatureFlag('innovationRebrandPlatform')

  const logoStyles = {
    marginBottom: 20,
    marginLeft: isSmallScreen ? 10 : 40,
    alignSelf: 'flex-start'
  }
  return (
    <BaseContainer>
      <StyledScrollView
        contentContainerStyle={{
          flexGrow: 1,
          alignItems: 'center'
        }}
      >
        {rebrand ? (
          <AcceleratorLogo width={306} height={45} style={logoStyles} />
        ) : (
          <W50Logo
            width={isSmallScreen ? 124 : 144}
            height={isSmallScreen ? 47 : 67}
            style={logoStyles}
          />
        )}
        <Flex
          flex={1}
          flexDirection={isSmallScreen ? 'column' : 'row'}
          flexBasis="auto"
          flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
          justifyContent="center"
          alignItems="center"
          maxWidth="1366px"
        >
          {children}

          <FooterLinks />
        </Flex>
      </StyledScrollView>
    </BaseContainer>
  )
}

export default ColumnLayout
