import React, { useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useTheme } from 'styled-components/native'
import useTranslation from '../../../../../../../hooks/useTranslation'

import { TouchableOpacity, View } from 'react-native'
import {
  FormField,
  ValidationContentProps,
  QuestionValidationMapValues,
  ValidationsProps
} from '../../../../types'
import { Text } from '../../../../../../../components/common/Text'
import {
  QuestionValidationMap,
  ValidationPhaseEnum,
  ValidationTypeEnum
} from '../../../../../../../components/DynamicForm/types'
import AnimatedComponent from '../../../../../../../components/common/AnimatedComponent'
import capitalize from '../../../../../../../utils/capitalize'
import { DropdownOption } from '../../../../../../../components/common/Dropdown'
import {
  CancelButton,
  CreateIcon,
  Footer,
  SaveButton
} from '../../../../../constants'
import DropdownField from '../../../DropdownField'
import InputField from '../../../InputField'
import { FormikProvider, useFormik } from 'formik'
import useUpsertQuestionValidationMap from '../../hooks/useUpsertQuestionValidationMap'
import { isEmpty } from 'ramda'
import useToast from '../../../../../../../hooks/useToast'
import { JSONValidation } from '../../../../../utils'

const validationTypes = Object.keys(ValidationTypeEnum)
const validationTypesValues = Object.values(ValidationTypeEnum)

const validationTypeOptions: DropdownOption[] = validationTypes.map(
  (typeKey, index) => ({
    label: capitalize(typeKey),
    value: validationTypesValues[index]
  })
)

const validationPhase = Object.keys(ValidationPhaseEnum)
const validationPhaseValues = Object.values(ValidationPhaseEnum)

const validationPhaseOptions: DropdownOption[] = validationPhase.map(
  (typeKey, index) => ({
    label: typeKey,
    value: validationPhaseValues[index]
  })
)

const validationFields: FormField[] = [
  {
    field: 'type',
    Component: DropdownField,
    isParent: false,
    options: validationTypeOptions
  },
  {
    field: 'phase',
    Component: DropdownField,
    isParent: false,
    options: validationPhaseOptions
  },
  {
    field: 'errorMessage',
    Component: InputField,
    isParent: false
  },
  {
    field: 'value',
    Component: InputField,
    isParent: false
  }
]

// Yup validation
const getValidationsValidationSchema = () =>
  Yup.object().shape({
    id: Yup.string().nullable(),
    type: Yup.mixed<ValidationTypeEnum>()
      .oneOf(Object.values(ValidationTypeEnum))
      .required(),
    phase: Yup.mixed<ValidationPhaseEnum>()
      .oneOf(Object.values(ValidationPhaseEnum))
      .required(),
    errorMessage: Yup.string().notRequired().nullable(),
    value: JSONValidation
  })

const ValidationContent = (props: ValidationContentProps) => {
  const { t } = useTranslation()
  const { validation, onClose } = props
  const { setToastMessage, setToastErrorMessage } = useToast()

  // Mutations
  const [
    upsertQuestionValidationMapMutation,
    isLoading
  ] = useUpsertQuestionValidationMap({
    onError: e => {
      setToastErrorMessage(
        t('platformManagement:forms:menuItems:validations:errorSave')
      )
      console.error(e)
    },
    onCompleted: ({ upsertQuestionValidationMap }) => {
      if (
        upsertQuestionValidationMap &&
        !isEmpty(upsertQuestionValidationMap)
      ) {
        setToastMessage(
          t('platformManagement:forms:menuItems:validations:successSaved')
        )
        onClose()
      } else {
        setToastErrorMessage(
          t('platformManagement:forms:menuItems:validations:errorSave')
        )
      }
    },
    errorMessage: t('platformManagement:validations:errorSave')
  })

  const isNew = useMemo(() => !validation?.id, [validation?.id])

  let initialValues = useMemo(
    () => ({
      type: validation?.validation?.type ?? undefined,
      phase: validation?.phase ?? undefined,
      errorMessage: validation?.errorMessage ?? '',
      value: validation?.value
        ? typeof validation?.value === 'object'
          ? JSON.stringify(validation?.value)
          : validation?.value
        : ''
    }),
    [validation]
  )

  // Formik
  const form = useFormik<QuestionValidationMapValues>({
    initialValues: { ...initialValues },
    validationSchema: getValidationsValidationSchema(),
    enableReinitialize: true,
    onSubmit: async values => {
      const input = {
        id: isNew ? undefined : validation.id,
        questionId: validation.questionId,
        validationType: values.type,
        phase: values.phase,
        errorMessage: values.errorMessage,
        value: values.value
      }

      upsertQuestionValidationMapMutation({
        variables: { ...input },
        refetchQueries: ['getQuestions']
      })
    }
  })

  const validateAndSubmit = () => {
    form.validateForm().then(() => form.submitForm())
  }

  const enableSubmit = useMemo(() => {
    return form.isValid && form.dirty
  }, [form])

  return (
    <View style={{ flex: 1 }}>
      <FormikProvider value={form}>
        {validationFields
          .filter(formField => (isNew ? !formField.hideOnNew : true))
          .map(formField => {
            const {
              Component,
              field,
              options,
              isParent,
              shouldShow
            } = formField
            const componentProps = {
              type: 'validations',
              key: field,
              fieldName: field,
              isReadOnly: !isNew && isParent,
              options
            }

            if (shouldShow && !shouldShow(form.values)) return null

            return <Component {...componentProps} />
          })}
        <Footer>
          <CancelButton
            isProcessing={isLoading}
            onPress={onClose}
            title={t('common:buttons:cancel')}
          />
          <SaveButton
            disabled={!enableSubmit}
            onPress={validateAndSubmit}
            isProcessing={isLoading}
            title={t(`common:buttons:${!isNew ? 'update' : 'save'}`)}
          />
        </Footer>
      </FormikProvider>
    </View>
  )
}
const Validations = (props: ValidationsProps) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { question, onClose } = props
  const questionValidationMaps = question?.questionValidationMaps ?? []
  const [currentValidation, setCurrentValidation] = useState<
    Partial<QuestionValidationMap> | undefined
  >(questionValidationMaps?.[0])

  return (
    <View
      style={{
        marginTop: 5,
        width: '100%',
        height: 400,
        flexDirection: 'row'
      }}
    >
      <View
        style={{
          width: '20%',
          height: '100%',
          borderColor: colors.tints.grays.g100,
          borderRightWidth: 1
        }}
      >
        {questionValidationMaps.map(validation => (
          <TouchableOpacity
            key={validation.id}
            style={{
              width: '100%',
              marginBottom: 5,
              backgroundColor:
                validation.id === currentValidation?.id
                  ? colors.tableRowHover
                  : 'transparent'
            }}
            onPress={() => setCurrentValidation(validation)}
          >
            <AnimatedComponent
              hoverOpacity
              hoverColor={colors.tableRowHover}
              style={{
                width: '100%',
                height: 40,
                justifyContent: 'center',
                borderColor: colors.highlight,
                borderBottomWidth: 1.5
              }}
            >
              <Text styles={{ textAlign: 'center' }}>
                {`${capitalize(validation.phase)}  - ${capitalize(
                  validation.validation.type
                )}`}
              </Text>
            </AnimatedComponent>
          </TouchableOpacity>
        ))}
        <CreateIcon
          style={{
            alignSelf: 'center'
          }}
          disabled={!question?.id}
          isLoading={false}
          title={t('platformManagement:forms:menuItems:validations:addNew')}
          onPress={() => {
            const newEmptyValidation: Partial<QuestionValidationMap> = {
              questionId: question.id,
              errorMessage: ''
            }
            setCurrentValidation(newEmptyValidation)
          }}
        />
      </View>
      <View
        style={{
          width: '80%',
          height: '100%',
          paddingHorizontal: 10
        }}
      >
        {currentValidation ? (
          <ValidationContent onClose={onClose} validation={currentValidation} />
        ) : null}
      </View>
    </View>
  )
}

export default Validations
