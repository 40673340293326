import React from 'react'
import { Switch, View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { ErrorText } from '../../../authentication/components/SharedStyledComponents'
import useTranslation from '../../../../hooks/useTranslation'
import { useField } from 'formik'
import { Text } from '../../../../components/common/Text'

const SwitchField = ({
  fieldName,
  isReadOnly,
  type
}: {
  fieldName: string
  isReadOnly: boolean
  type: string
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [field, meta, helpers] = useField({ name: fieldName })

  return (
    <View style={{ marginTop: 5 }}>
      <Text styles={{ marginBottom: 5 }}>
        {t(`platformManagement:forms:menuItems:${type}:fields:${field.name}`)}
      </Text>
      <Switch
        disabled={isReadOnly}
        value={field.value}
        onValueChange={value => {
          helpers.setValue(value)
          helpers.setTouched(true)
        }}
        style={{
          transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }]
        }}
        trackColor={{
          true: colors.highlight,
          false: colors.border
        }}
        thumbColor={colors.background}
        // @ts-expect-error
        activeThumbColor={colors.background}
      />
      {meta.error && meta.touched ? <ErrorText>{meta.error}</ErrorText> : null}
    </View>
  )
}

export default SwitchField
