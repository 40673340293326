import { View } from 'react-native'
import styled from 'styled-components/native'
import W50Logo from '../../icon/W50Logo'
import AcceleratorLogo from '../../icon/AcceleratorLogo'
import React from 'react'

import useIsInBreakpoint from '../../../hooks/useIsInBreakpoint'
import { Breakpoint } from '../../../types/breakpoint'
import useFeatureFlag from '../../../hooks/useFeatureFlag'

export const LogoContainer = styled(View)`
  ${({ theme, rebrand }) => `
  padding: ${theme.space[rebrand ? 4 : 3] + theme.space[1]}px;
  padding-bottom: ${theme.space[4]}px;
`}
`

const Logo = () => {
  const isSmallScreen = useIsInBreakpoint(
    Breakpoint.Tablet,
    Breakpoint.Desktop,
    Breakpoint.Phone
  )
  const rebrand = useFeatureFlag('innovationRebrandPlatform')
  return isSmallScreen ? null : (
    <LogoContainer rebrand={rebrand}>
      {rebrand ? (
        <AcceleratorLogo width={240} height={40} />
      ) : (
        <W50Logo width={127} height={50} />
      )}
    </LogoContainer>
  )
}

export default Logo
