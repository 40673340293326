import { useRecoilState, SetterOrUpdater } from 'recoil'
import featureFlagAtom from '../recoil/featureFlagAtom'

const useFeatureFlags = (): object | null => {
  const [featureFlags] = useRecoilState(featureFlagAtom) as [
    object,
    SetterOrUpdater<object>
  ]
  return featureFlags || null
}

export default useFeatureFlags
