import React, {
  useState,
  useCallback,
  useEffect,
  ReactNode,
  useMemo
} from 'react'
import {
  View,
  KeyboardType,
  TextInputChangeEventData,
  NativeSyntheticEvent
} from 'react-native'
import { FieldInputProps, useField } from 'formik'
import styled, { withTheme } from 'styled-components/native'
import { find, pick } from 'ramda'
import { Text } from '../common/Text'
import useTranslation from '../../hooks/useTranslation'
import TextInput, { TextInputProps } from '../../ui-library/TextInput'
import {
  ErrorContainer,
  ErrorText
} from '../../screens/authentication/components/SharedStyledComponents'
import {
  Question,
  QuestionTypeEnum,
  JSONStyles,
  ValidationTypeEnum
} from './types'
import { removeNonNumeric } from '../../utils/changeFormikText'
import useMixpanel, { EVENT_MAP } from '../../hooks/useMixpanel'
import { parseJson } from '../../utils/parseJson'
import useQuestionDependency from './hooks/useQuestionDependency'
import useDebounce from '../../hooks/useDebounce'
import { getCurrencyFormat } from '../../services/currencyService'
import { getSingleQuestion } from './recoil/questionAtomFamily'
import { formQuestionsConditionsAtomFamily } from '../../recoil/formQuestionsConditionsAtomFamily'
import { useRecoilValue } from 'recoil'

export const Count = styled(Text)`
  position: absolute;
  height: 0;
  bottom: 6px;
  right: 0;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.inputText};
`

export type TextInputStyles = Pick<
  JSONStyles,
  'containerStyles' | 'errorContainerStyles' | 'labelStyles' | 'styles'
>

export interface TextInputDataProps {
  name: string
  label?: string
  helperText?: string
  length?: number
  renderCounter?: boolean
  isMultiline?: boolean
  numberOfLines?: number
  keyboardType?: KeyboardType
  styles?: TextInputStyles
  placeholder?: string
  key: string
  type: QuestionTypeEnum
  formSubmissionId: string
  questionDependencyIds?: string[]
  questionId: string
  dropDownValue?: string
  isDynamicQuestion?: boolean
}

const getFieldLabel = (label: string) => {
  if (!label) return null
  const splitLabel = label.split('-')

  if (splitLabel.length > 1) {
    return splitLabel[0]?.trim()
  }

  return label
}

// Mask Input
const maskIntegerValue = (value: string | number) =>
  typeof value === 'number'
    ? getCurrencyFormat(value ?? 0)
    : value?.length
    ? getCurrencyFormat(Number(value))
    : ''

interface KeyboardProps {
  onBlur?: () => void
  onChange?: (event: NativeSyntheticEvent<TextInputChangeEventData>) => void
}

export interface DynamicTextInputProps extends TextInputProps {
  data: TextInputDataProps
  isReadOnly: boolean
  asPrintable: boolean
  loading?: boolean
  theme: any
}

interface TextInputComponentProps {
  isReadOnly: boolean
  asPrintable: boolean
  placeholder?: string
  label?: string
  helperText?: string
  isMultiline?: boolean
  numberOfLines?: number
  styles?: TextInputStyles
  showError: boolean
  onFocus: (e: any) => void
  renderRightComponent: () => ReactNode
  field: FieldInputProps<any>
  inputValue: string
  keyboardProps: KeyboardProps
}

const TextInputComponent = (props: TextInputComponentProps) => {
  const {
    isReadOnly,
    asPrintable,
    placeholder,
    label,
    helperText,
    isMultiline,
    numberOfLines,
    styles,
    showError,
    onFocus,
    renderRightComponent,
    inputValue,
    keyboardProps,
    ...rest
  } = props

  const { t } = useTranslation()
  return (
    <TextInput
      autoCapitalize="none"
      isReadOnly={isReadOnly}
      asPrintable={asPrintable}
      placeholder={t(placeholder)}
      label={label}
      helperText={helperText}
      withHelperText
      multiline={isMultiline}
      numberOfLines={numberOfLines}
      style={styles?.styles}
      labelStyles={styles?.labelStyles}
      hasError={showError}
      onFocus={onFocus}
      renderRightComponent={renderRightComponent}
      isAutoSizable={isMultiline}
      value={inputValue}
      {...rest}
      {...keyboardProps}
    />
  )
}

const DynamicTextInput = (props: DynamicTextInputProps) => {
  const { t } = useTranslation()
  const { trackWithProperties } = useMixpanel()
  const {
    data,
    isReadOnly = false,
    asPrintable = false,
    loading = false,
    theme,
    ...rest
  } = props
  const {
    name,
    label,
    placeholder,
    length,
    renderCounter,
    isMultiline,
    numberOfLines,
    styles,
    type,
    formSubmissionId,
    helperText,
    questionId,
    questionDependencyIds = [],
    dropDownValue = undefined,
    isDynamicQuestion = false
  } = data

  const question = useRecoilValue(getSingleQuestion(questionId))

  const formQuestionCondition = useRecoilValue(
    formQuestionsConditionsAtomFamily(formSubmissionId)
  )

  const questionCondition = useMemo(() => {
    return formQuestionCondition?.[questionId] || null
  }, [questionId, formQuestionCondition])

  const [field, meta, helpers] = useField(name)
  const { visible } = useQuestionDependency(
    questionDependencyIds,
    questionId,
    field?.value
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [focused, setFocused] = useState(false)
  const keyboardProps: KeyboardProps = {}

  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue, 200)

  useEffect(() => {
    if (question?.answer?.value && typeof question.answer?.value !== 'object') {
      setInputValue(question.answer.value)
    } else {
      setInputValue('')
    }
  }, [question?.answer])

  useEffect(() => {
    if (!loading && dropDownValue && !checkIfValidUUID(dropDownValue)) {
      setInputValue(dropDownValue)
    }
  }, [dropDownValue, loading])

  useEffect(() => {
    if (!loading && (field?.value || isDynamicQuestion)) {
      setInputValue(field?.value)
    }
  }, [field?.value, loading])

  const onNumericChange = useCallback(
    event => {
      const value = removeNonNumeric(event?.nativeEvent?.text)
      return setInputValue(value)
    },
    [helpers, type]
  )

  const onTextChange = event => {
    return setInputValue(event?.nativeEvent?.text)
  }

  useEffect(() => {
    helpers.setValue(debouncedInputValue)
  }, [debouncedInputValue])

  const numericOnBlur = useCallback(() => {
    if (inputValue === '') {
      helpers.setValue(null)
      helpers.setTouched(true)
    } else if (!isDynamicQuestion && inputValue?.length) {
      helpers.setValue(inputValue)
      helpers.setTouched(true)
    }
  }, [helpers, field])

  const textOnBlur = useCallback(() => {
    if (inputValue?.length) {
      helpers.setValue(inputValue)
      helpers.setTouched(true)
    }
  }, [helpers, field?.value])

  const onFocus = useCallback(() => {
    setFocused(true)
    if (!isReadOnly) {
      trackWithProperties(EVENT_MAP.click.field, {
        category: 'dynamic-form',
        field: name,
        label: getFieldLabel(label || ''),
        labelRaw: label
      })
    }
  }, [field])

  /**
   * The text input currently sets the value to
   * an empty string when the user leaves the field blank
   * after deleting the value. We need the value to be a number
   * or null and handling this in the onBlur is the best solution I've found
   * until we rebuild the numeric input.
   * React uncontrolled vs controlled inputs have an issue based on the default value
   * https://github.com/facebook/react/issues/11417
   */
  if (type === QuestionTypeEnum.Integer) {
    keyboardProps.onChange = onNumericChange
    keyboardProps.onBlur = numericOnBlur
  } else {
    keyboardProps.onChange = onTextChange
    keyboardProps.onBlur = textOnBlur
  }

  const renderRightComponent = () => {
    const val = Number(inputValue?.length)

    if (renderCounter && length && !(isReadOnly || asPrintable)) {
      const counterValue = length - val

      return (
        <Count
          style={counterValue < 0 ? { color: theme.colors.inputTextError } : {}}
        >
          {val}/{length}
        </Count>
      )
    }

    return null
  }

  const showError = !(isReadOnly || asPrintable) && !!meta.error

  const containerStyle = {
    width: '100%',
    // @ts-ignore
    ...(styles?.containerStyles || {})
  }

  // JC: the funding-question has a null value initially so we avoid react-native warning by doing
  // the condition field.value === null ? '' : field.value
  return visible ? (
    <View style={containerStyle}>
      <TextInputComponent
        asPrintable={asPrintable}
        field={field}
        isReadOnly={isReadOnly}
        keyboardProps={keyboardProps}
        onFocus={onFocus}
        renderRightComponent={renderRightComponent}
        showError={showError}
        inputValue={
          type === QuestionTypeEnum.Integer
            ? maskIntegerValue(inputValue)
            : typeof inputValue === 'object'
            ? inputValue[`${question?.id}`] ?? ''
            : inputValue
        }
        helperText={
          questionCondition?.helperTextOverride
            ? questionCondition?.helperTextOverride
            : helperText
        }
        isMultiline={isMultiline}
        label={label}
        numberOfLines={numberOfLines}
        placeholder={placeholder}
        styles={styles}
        {...rest}
      />
      {showError ? (
        <ErrorContainer style={styles?.errorContainerStyles}>
          <ErrorText>{t(meta.error, { length })}</ErrorText>
        </ErrorContainer>
      ) : null}
    </View>
  ) : null
}

export const transformTextInputData = (
  question: Question,
  formSubmissionId: string
): TextInputDataProps => {
  const validations = question?.questionValidationMaps || []

  const styles = question?.style
    ? parseJson(question?.style)
    : { containerStyles: { marginRight: '10px' } }

  const configData = question?.configData
    ? parseJson(question.configData)
    : undefined

  const questionLength = Number(
    find(
      validation => validation.validation.type === ValidationTypeEnum.max,
      validations
    )?.value?.length
  )

  const placeholder = find(
    validation => validation.type === ValidationTypeEnum.required,
    validations
  )
    ? 'forms:required'
    : undefined

  const helperText = configData ? configData?.helperText : undefined
  const placeholderText = configData ? configData?.placeholderText : undefined

  return {
    key: `questions-${question?.id}`,
    name: `questions.${question?.id}`,
    label: question?.questionText,
    placeholder: placeholderText || placeholder,
    length: questionLength,
    isMultiline: question?.type === QuestionTypeEnum.Textarea,
    numberOfLines: styles?.textareaLineHeight || 1,
    type: question?.type,
    renderCounter: styles?.renderCounter,
    styles: pick(['containerStyles', 'labelStyles', 'styles'], styles || {}),
    helperText,
    formSubmissionId,
    questionId: question?.id,
    questionDependencyIds: question?.questionDependencies?.map(qd => qd?.id),
    isDynamicQuestion: !!question?.isDynamicQuestion
  }
}

const checkIfValidUUID = (str: string) => {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return regexExp.test(str)
}

export default withTheme(DynamicTextInput)
