import React from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import Colors from '../../../../constants/Colors'
import useFeatureFlag from '../../../../hooks/useFeatureFlag'
import styled from 'styled-components/native'

const legacyColors = [
  Colors.light.gradientLightBlue,
  Colors.light.gradientDarkBlue,
  Colors.light.gradientBlack
]

const gradientColors = [
  Colors.light.highlight,
  Colors.light.buttonBackground,
  Colors.light.darkGradientBackground
]

const legacyStops = [0.004, 0.055, 0.2]
const gradientStops = [0, 0.02, 0.1]

const Gradient = styled(LinearGradient).attrs(({ rebrand }) => ({
  colors: rebrand ? gradientColors : legacyColors,
  locations: rebrand ? gradientStops : legacyStops,
  start: { x: 0, y: 0 },
  end: { x: 0.5, y: rebrand ? 0.25 : 2 }
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const NewGradient = ({ children }: any) => {
  const rebrand = useFeatureFlag('innovationRebrandPlatform')
  return <Gradient rebrand={rebrand}>{children}</Gradient>
}

export default NewGradient
