import React, { useCallback, useEffect, useState } from 'react'
import { Container } from '../../../components/common/SharedStyledComponents'
import {
  UserListContainer,
  UserListContentVew,
  UserListLabelContent,
  UserListRemoveButton,
  UserListEditButton,
  UserListTitleContent,
  UserListView
} from './SharedStyledComponents'
import { useTheme } from 'styled-components/native'
import BinIcon from '../../../components/icon/BinIcon'
import Icon from '../../../components/icon'
import {
  RolesDict,
  UserListProps,
  UserListContentProps,
  UserListRemoveDialogProps,
  UserListTitlesContentProps
} from '../constants'
import { Text } from '../../../components/common/Text'
import useTranslation from '../../../hooks/useTranslation'
import RemovePopup from '../../../components/common/Dialog/RemovePopup'
import useUpsertInnovationUserMutation from '../hooks/useUpsertUserMutation'
import useToast from '../../../hooks/useToast'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import { TEAM_MEMBER_ROLES } from '../../../types'

const UserListRemoveDialog = ({
  i,
  user,
  onPopupToggle,
  onRemoveUser
}: UserListRemoveDialogProps) => {
  const { t } = useTranslation()
  const { space } = useTheme()
  const { person, id } = user

  return (
    <RemovePopup
      title={`${t('product:general:delete')} ${person.firstName} ${
        person.lastName
      }`}
      testID="user_list_remove_dialog"
      cancelButtonTestId="user_list_remove_dialog_cancel_button"
      deleteButtonTestId="user_list_remove_dialog_delete_button"
      onPrimaryClick={onRemoveUser(id)}
      onSecondaryClick={onPopupToggle(i)}
      styles={{
        position: 'absolute',
        top: space[3],
        right: space[4]
      }}
    />
  )
}

const UserListTitlesContent = ({ user }: UserListTitlesContentProps) => {
  const { person, roles } = user
  const { jobTitle, firstName, lastName, emailAddresses = [] } = person
  const email = emailAddresses[0]?.email
  const { sizes, fontWeights } = useTheme()
  const role =
    RolesDict[roles.find(role => TEAM_MEMBER_ROLES.includes(role)) ?? '']

  return (
    <UserListTitleContent>
      <UserListLabelContent style={{ width: '30%' }}>
        <Text
          styles={{
            width: 'fit-content',
            marginRight: sizes[1],
            fontWeight: `${fontWeights.bold}`,
            overflow: 'auto'
          }}
        >
          {firstName} {lastName}
        </Text>
      </UserListLabelContent>
      <UserListLabelContent style={{ width: '20%' }}>
        <Text
          styles={{
            marginRight: sizes[1],
            overflow: 'auto',
            width: 'fit-content'
          }}
        >
          {jobTitle}
        </Text>
      </UserListLabelContent>
      <UserListLabelContent style={{ width: '35%' }}>
        <Text
          styles={{
            width: 'fit-content',
            marginRight: sizes[1],
            overflow: 'auto'
          }}
        >
          {email}
        </Text>
      </UserListLabelContent>
      <UserListLabelContent style={{ width: '15%' }}>
        <Text>{role}</Text>
      </UserListLabelContent>
    </UserListTitleContent>
  )
}

const UserListContent = ({
  i,
  onPopupToggle,
  onEditToggle,
  isPending,
  user,
  canEdit,
  canDelete
}: UserListContentProps) => {
  const { id } = user
  const { space, colors } = useTheme()
  const rebrand = useFeatureFlag('innovationRebrandPlatform')
  const [hovered, setHovered] = useState('')

  const isReadOnly = !canEdit
  return (
    <UserListView>
      <UserListContentVew
        style={{
          height: space[3],
          maxWidth: '100%'
        }}
        key={`member-${id}`}
      >
        <UserListTitlesContent user={user} />
        {!isReadOnly && (
          <>
            {!isPending && (
              <UserListEditButton
                testID="team_member_edit_user_btn"
                onPress={onEditToggle(user)}
                onMouseEnter={() => setHovered('edit')}
                onMouseLeave={() => setHovered('')}
              >
                <Icon
                  name="editUser"
                  color={
                    rebrand
                      ? hovered === 'edit'
                        ? colors.buttonBackground
                        : colors.darkText
                      : colors.primaryPalette.black
                  }
                  width={16}
                  height={16}
                />
              </UserListEditButton>
            )}
            {isPending && (
              <UserListEditButton
                testID="team_member_resend_invite_btn"
                onPress={onEditToggle(user)}
                onMouseEnter={() => setHovered('resend')}
                onMouseLeave={() => setHovered('')}
              >
                <Icon
                  name="resend"
                  color={
                    rebrand
                      ? hovered === 'resend'
                        ? colors.buttonBackground
                        : colors.darkText
                      : colors.primaryPalette.black
                  }
                  width={17}
                  height={16}
                />
              </UserListEditButton>
            )}
            {canDelete ? (
              <UserListRemoveButton
                testID="team_member_delete_user_btn"
                onPress={onPopupToggle(i)}
                onMouseEnter={() => setHovered('delete')}
                onMouseLeave={() => setHovered('')}
              >
                <BinIcon
                  stroke={
                    rebrand
                      ? hovered === 'delete'
                        ? colors.dangerHover
                        : colors.dangerButton
                      : colors.primaryPalette.black
                  }
                  color={colors.white}
                  style={{ width: space[3], height: space[3] }}
                />
              </UserListRemoveButton>
            ) : null}
          </>
        )}
      </UserListContentVew>
    </UserListView>
  )
}

const UserList = ({
  i,
  onEditToggle,
  isPending,
  index,
  user,
  canEdit,
  canDelete,
  testID
}: UserListProps) => {
  const { colors, radii, borderWidths } = useTheme()
  const { t } = useTranslation()
  const [toastError, setToastError] = useState(false)
  const [toastSuccess, setToastSuccess] = useState(false)
  const { setToastMessage, setToastErrorMessage } = useToast()
  const [upsertInnovationUser] = useUpsertInnovationUserMutation()
  const [popupVisibility, setPopupVisibility] = useState({
    isVisible: false,
    index: -1
  })

  const rebrand = useFeatureFlag('innovationRebrandPlatform')

  useEffect(() => {
    if (toastSuccess) {
      setToastMessage(t('joinTeam:dialog:successRemoveMessage'))
      setToastSuccess(false)
    }
  }, [toastSuccess])

  useEffect(() => {
    if (toastError) {
      setToastErrorMessage(t('joinTeam:dialog:errorRemoveMessage'))
      setToastError(false)
    }
  }, [toastError])

  const onPopupToggle = useCallback(
    index => () => {
      setPopupVisibility(({ isVisible }) => ({
        index,
        isVisible: !isVisible
      }))
    },
    [setPopupVisibility]
  )

  const onRemoveItem = useCallback(
    () => async () => {
      try {
        const { data } = await upsertInnovationUser({
          id: user.id,
          remove: true
        })
        if (data.upsertInnovationUserMutation) {
          setToastSuccess(true)
        } else {
          setToastError(true)
        }
      } catch (errors) {
        setToastError(true)
      }
      setPopupVisibility(({ isVisible }) => ({
        index: -1,
        isVisible: !isVisible
      }))
    },
    [setPopupVisibility]
  )

  return (
    <Container
      testID={testID ?? ''}
      key={`user-${user.id}`}
      flexDirection="column"
      flexWrap="wrap"
      minWidth="100%"
      backgroundColor={rebrand ? colors.containerBackground : colors.formCard}
      borderColor={rebrand ? colors.tableRowHeader : colors.formCard}
      borderRadius={rebrand ? radii[0] : radii[4]}
      borderWidth={rebrand ? borderWidths[1] : borderWidths[1]}
      borderBottomWidth={rebrand ? 1 : 0}
      zIndex={index}
      styles={{
        marginBottom: 0,
        justifyContent: 'center',
        borderRadius: rebrand ? radii[0] : radii[4],
        borderWidth: rebrand ? borderWidths[0] : borderWidths[1],
        borderBottomWidth: rebrand ? 1 : 0
      }}
    >
      <UserListContainer>
        <UserListContent
          isPending={isPending}
          user={user}
          i={i}
          onPopupToggle={onPopupToggle}
          onEditToggle={onEditToggle}
          canEdit={canEdit}
          canDelete={canDelete}
        />
        {popupVisibility.isVisible && popupVisibility.index === i && (
          <UserListRemoveDialog
            user={user}
            i={i}
            onPopupToggle={onPopupToggle}
            onRemoveUser={onRemoveItem}
          />
        )}
      </UserListContainer>
    </Container>
  )
}

export default UserList
