import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

const Container = styled(View)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  z-index: 999;
  background: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface LoadingScreenProps {
  showSpinner?: boolean
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ showSpinner }) => {
  const { colors } = useTheme()
  return (
    <Container>
      {showSpinner && <ActivityIndicator size="large" color={colors.primary} />}
    </Container>
  )
}

export default LoadingScreen
