const newDesignColors = {
  primaryPalette: {
    white: '#F2F2F2',
    black: '#0a0a0a',
    blue: '#dfebfa',
    accent: '#63CBF8',
    alert: '#FA9E42'
  },
  cta: {
    primary: 'rgb(33, 32, 27, 0.2)',
    secondary: '#21201B',
    previousPrimary: '#9470d1',
    previousSecondary: '#7154a1'
  },
  tints: {
    grays: {
      g100: '#dedede',
      g200: '#bbbbba',
      g300: '#979797',
      g400: '#717171',
      g500: '#404040'
    },
    blues: {
      b100: '#f9fbfd',
      b200: '#f3f6fd',
      b300: '#ebf3fa',
      b400: '#e5effa',
      b500: '#dfebfa'
    }
  }
}

const lightLegacy = {
  ...newDesignColors,
  header: newDesignColors.tints.grays.g500,
  headerText: newDesignColors.primaryPalette.white,
  menuItem: newDesignColors.primaryPalette.white,
  selectedMenuItem: newDesignColors.primaryPalette.accent,
  appBackground: '#FFF',
  containerBackground: '#FFF',
  danger: '#ED435C',
  dangerButton: '#A30000',
  primary: '#006890',
  lightGradientBackground: '#0a6890',
  darkGradientBackground: '#18233f',
  logo_onAppBackground: 'rgba(196,196,196,0.8)',
  tableCellBackground: 'rgba(229, 229, 229, 0.54)',
  inputBackground: newDesignColors.primaryPalette.white,
  inputText: newDesignColors.primaryPalette.black,
  inputLabels: newDesignColors.primaryPalette.black,
  inputBorder: newDesignColors.tints.grays.g100,
  inputTextError: 'rgba(230, 0, 34, 0.54)',
  inputBorderError: 'rgba(230, 0, 34, 0.54)',
  placeholderText: 'rgba(0, 0, 0, 0.34)',
  pillColor: '#006890',
  pillRemoveIconColor: '#FFF',
  dropDownBackground: 'rgb(229, 229, 229)',
  dropDownOnFocus: '#c1c1c1',
  dropDownSelected: newDesignColors.cta.primary,
  dropDownTextColor: newDesignColors.primaryPalette.black,
  dropDownIndicatorColor: 'rgb(205, 205, 205)',
  dropDownIndicatorOnFocus: 'rgb(153, 153, 153)',
  buttonText: '#FFF',
  buttonBackground: newDesignColors.cta.secondary,
  buttonDisabledBackground: newDesignColors.cta.secondary,
  buttonBorder: 'transparent',
  buttonVariant: '#8C8C8C',
  darkIcon: '#0C0C0C',
  subduedIcon: '#A8A8A8',
  skeletonIcon: '#B2B2B2',
  lightGrey: '#E5E5E5',
  pageTitle: '#E5E5E5',
  tableRowHover: 'rgba(0, 104, 144, 0.1)',
  tableRowAlternate: 'rgba(196, 196, 196, 0.14)',
  brightPrimary: '#20C1FF',
  accent: '#cb7a16',
  background: '#FFF',
  text: newDesignColors.primaryPalette.black,
  text2: 'rgba(0, 0, 0, 0.54)', //
  border: '#eee',
  tabBorder: '#D0D0D0',
  attention: '#F35F75',
  enabled: '#006890',
  disabled: '#8b8a8a',
  success: '#33A51F',
  saved: '#00C389',
  info: '#06A4CC',
  warning: '#FCDA00',
  inactive: '#828282',
  copyright: 'rgba(249, 245, 245, 0.74)',
  feedbackInfo: 'rgba(0, 104, 144, 0.34)',
  feedbackFastPassed: '#F3D35E',
  formCard: newDesignColors.tints.blues.b200,
  subTitle: newDesignColors.tints.grays.g300,
  subTitleDescription: newDesignColors.tints.grays.g400,
  gradientLightBlue: 'rgba(0, 192, 255, 0.4)',
  gradientDarkBlue: 'rgba(12, 37, 116, 0.45)',
  gradientBlack: 'rgba(0, 0, 0, 0.2)',
  darkBackground: '#21201b',
  darkText: '21201b',
  highlight: '#109eda',
  tableRowHeader: '#dae8f8',
  alert: '#f2451a',
  lightBlueBackground: '#97CFEE',
  overlay: 'rgba(0, 0, 0, 0.6)',
  delete: '#e56a3b'
}

const light = {
  ...lightLegacy,
  appBackground: '#F2F2F2',
  darkBackground: '#C094F8', // #C094F8
  tableRowHover: 'rgba(243, 230, 255, 0.3)', // #F3E6FF
  tableRowHeader: '#F3E6FF', //
  tableRowHeaderBorder: 'transparent',
  highlight: '#C094F8',
  brightPrimary: '#C094F8',
  primary: '#C094F8',
  buttonBackground: '#9933FF',
  buttonHover: '#AD64FC',
  darkGradientBackground: '#141413',
  pageTitle: '#21201B',
  darkText: '#21201B',
  dangerButton: '#F2451A',
  dangerHover: '#FF542A',
  success: '#0C8D52',
  successHover: '#0D542F',
  formCard: 'rgba(243, 230, 255, 0.3)' // #F3E6FF
}

const darkThemeBordersAndText = newDesignColors.primaryPalette.white
const darkThemeDanger = '#FF6543'

const darkLegacy = {
  ...lightLegacy,
  text: darkThemeBordersAndText,
  text2: darkThemeBordersAndText,
  disabled: 'rgba(115, 117, 114, .2)',
  inputBackground: 'transparent',
  pillColor: darkThemeBordersAndText,
  pillRemoveIconColor: '#18233f',
  danger: darkThemeDanger,
  inputBorder: darkThemeBordersAndText,
  inputLabels: newDesignColors.primaryPalette.white,
  dropDownOnFocus: 'rgba(249, 245, 245, 0.4)',
  dropDownSelected: '#0597d5',
  dropDownBackground: '#006890',
  dropDownTextColor: newDesignColors.primaryPalette.white,
  dropDownIndicatorColor: darkThemeBordersAndText,
  dropDownIndicatorOnFocus: 'rgba(249, 245, 245, 0.74)',
  inputText: darkThemeBordersAndText,
  inputTextError: darkThemeDanger,
  inputBorderError: darkThemeDanger,
  buttonText: darkThemeBordersAndText,
  buttonBackground: 'transparent',
  buttonBorder: darkThemeBordersAndText,
  placeholderText: darkThemeBordersAndText
}

const dark = {
  ...darkLegacy,
  buttonBackground: '#9933FF',
  buttonBorder: '#9933FF',
  outlineButton: '#AD64FC',
  inputFocus: '#AD64FC',
  primary: '#C094F8',
  highlight: '#C094F8',
  brightPrimary: '#C094F8',
  dropDownOnFocus: '#9933FF',
  dropDownSelected: '#C094F8',
  dropDownBackground: '#21201B',
  dropDownMultiValueBackground: '#9933FF',
  dropDownMultiValueRemove: '#AD64FC',
  dropDownMultiValueBorderColor: '#9933FF'
}

export default { light, dark, darkLegacy, lightLegacy }
