import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

const Container = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const LoadingScreen = ({ style = {} }) => {
  const { colors } = useTheme()
  return (
    <Container style={style}>
      <ActivityIndicator size="large" color={colors.primary} />
    </Container>
  )
}

export default LoadingScreen
