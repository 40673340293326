import {
  atom,
  atomFamily,
  CallbackInterface,
  selector,
  selectorFamily
} from 'recoil'
import { Answer, Question } from '../types'

export const questionAtomFamily = atomFamily<Question | null, string>({
  key: 'questionState',
  default: null
})
const questionIds = atom<string[]>({
  key: 'questionIds',
  default: []
})

const getQuestion = selectorFamily<Question | null, string>({
  key: 'getQuestions',
  get: (id: string) => ({ get }) => {
    return get(questionAtomFamily(id))
  }
})

export const getSingleQuestion = selectorFamily<Question | null, string>({
  key: 'singleQuestionSelector',
  get: (id: string) => ({ get }) => {
    return get(getQuestion(id))
  }
})

export const getAllQuestions = selector<Question[]>({
  key: 'allQuestionsSelector',
  // @ts-ignore
  get: ({ get }) => {
    const ids = get(questionIds)
    return ids.map(id => get(getQuestion(id)))
  }
})

export const setQuestionCallback = ({ set }: CallbackInterface) => (
  questions: Question[]
) => {
  for (const question of questions) {
    set(questionAtomFamily(question.id), question)
    set(questionIds, currVal => [...currVal, question.id])
  }
}

const getAnswerByQuestionId = (answers: Answer[], questionId: string) => {
  return answers.find(a => a.questionId === questionId)
}

export const setQuestionAnswer = ({ set }: CallbackInterface) => (
  questions: Question[],
  answers: Answer[]
) => {
  for (const question of questions) {
    const answer = getAnswerByQuestionId(answers, question.id)

    set(questionAtomFamily(question.id), oldValue => {
      if (answer && oldValue) {
        return { ...oldValue, answer }
      }
      return question
    })
  }
}

export const setQuestionAnswers = ({ set }: CallbackInterface) => (
  questions: Question[],
  answers: Answer[]
) => {
  for (const answer of answers) {
    const question = questions.find(q => q.id === answer.questionId)

    if (question) {
      set(questionAtomFamily(question.id), oldValue => {
        if (answer && oldValue) {
          return { ...oldValue, answer }
        }
        return question
      })
    }
  }
}
