import React from 'react'
import { useTheme } from 'styled-components/native'
import { Container } from '../../../components/common/SharedStyledComponents'
import {
  UserListContainer,
  UserListTitleContent,
  UserListLabelContent,
  UserListHeaderLabel,
  UserListView,
  UserListContentVew
} from './SharedStyledComponents'
import useTranslation from '../../../hooks/useTranslation'

const UserListHeader = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  return (
    <Container
      backgroundColor={colors.tableRowHeader}
      borderColor={colors.tableRowHeaderBorder}
    >
      <UserListContainer>
        <UserListView>
          <UserListContentVew>
            <UserListTitleContent>
              <UserListLabelContent style={{ width: '30%' }}>
                <UserListHeaderLabel>
                  {t('settings:members:label:memberName')}
                </UserListHeaderLabel>
              </UserListLabelContent>
              <UserListLabelContent style={{ width: '20%' }}>
                <UserListHeaderLabel>
                  {t('settings:members:label:title')}
                </UserListHeaderLabel>
              </UserListLabelContent>
              <UserListLabelContent style={{ width: '35%' }}>
                <UserListHeaderLabel>
                  {t('settings:members:label:email')}
                </UserListHeaderLabel>
              </UserListLabelContent>
              <UserListLabelContent style={{ width: '15%' }}>
                <UserListHeaderLabel>
                  {t('settings:members:label:role')}
                </UserListHeaderLabel>
              </UserListLabelContent>
            </UserListTitleContent>
          </UserListContentVew>
        </UserListView>
      </UserListContainer>
    </Container>
  )
}

export default UserListHeader
