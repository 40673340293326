import React, { useEffect, useMemo } from 'react'
import LoadingScreen from './LoadingScreen'
import useFeatureFlags from '../hooks/useFeatureFlags'

const LoadingCheck = ({ send, firebaseInitialized, firebaseToken }: any) => {
  const features = useFeatureFlags()

  // Check if features are loaded
  const areFeaturesFlags: boolean = useMemo(
    () => !!features && Object.keys(features).length > 0,
    [features]
  )

  // Evaluate if we need to remove the loading check
  // by checking a list of requirements are completed
  const removeLoadingCheck: boolean = useMemo(
    () => firebaseInitialized && areFeaturesFlags,
    [firebaseInitialized, areFeaturesFlags]
  )

  useEffect(() => {
    if (removeLoadingCheck) {
      send(firebaseToken ? 'LOGGED_IN' : 'NOT_LOGGED_IN')
    }
  }, [removeLoadingCheck, firebaseToken])

  return <LoadingScreen showSpinner={areFeaturesFlags} />
}

export default LoadingCheck
