import React from 'react'

import { useTheme } from 'styled-components/native'

import {
  TableConfig,
  CellRendererProps,
  ColumnDefinition
} from '../../../../components/Table/types'
import { TableNameEnum } from '../../../../components/DynamicTable/types'
import Theme from '../../../../constants/Theme'
import { SmallRow } from '../../../../components/Table/components/SharedStyledComponents'
import { AlternatingHoverableRow as DynamicAlternatingHoverableRow } from '../../../../components/Table/components/DynamicTable/SharedStyledComponents'
import { FormValues } from '../../types'
import {
  Content,
  ContentLink
} from '../../../../screens/PlatformManagement/SharedStyledComponents'
import { Flex } from '../../../../components/FlexBox'
import useTranslation from '../../../../hooks/useTranslation'

const headerStyle = {
  fontWeight: 'bold',
  fontSize: `${Theme.fontSizes[2]}px`,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  textTransform: 'uppercase',
  textAlign: 'center',
  textAlignVertical: 'center',
  width: '100%'
}
const headerContainerStyle = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 10
}
const cellTextStyle = { textAlign: 'center' }

const formNameColumn: ColumnDefinition<any> = {
  id: 'formNameColumn',
  header: 'platformManagement:forms:menuItems:forms:name',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  fixed: true,
  width: 25,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={item.name}
        style={cellTextStyle}
      />
    )
  }
}

const formTypeColumn: ColumnDefinition<any> = {
  id: 'formTypeColumn',
  header: 'platformManagement:forms:menuItems:forms:type',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 10,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={`${item?.type}` || ''}
        style={cellTextStyle}
      />
    )
  }
}

const activeInactiveColumn: ColumnDefinition<any> = {
  id: 'activeInactiveColumn',
  header: 'platformManagement:forms:menuItems:forms:activeHeaderName',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  width: 20,
  emptyMessage: '',
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    const title = item.isActive ? 'Active' : 'Inactive'
    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={title}
        style={{ ...cellTextStyle }}
      />
    )
  }
}

const editPreviewColumn = ({ handleEditForm }) => ({
  id: 'editPreviewColum',
  width: 10,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    const { colors } = useTheme()

    return (
      <Flex flexDirection="row" width="65px" textAlign="center">
        <ContentLink
          isSmallScreen={isSmallScreen}
          label={labelTranslated}
          title={'Edit /'}
          style={{
            color: colors.highlight,
            textAlign: 'right'
          }}
          onPress={() => {
            handleEditForm(item)
          }}
        />
        <Content
          isSmallScreen={isSmallScreen}
          label={labelTranslated}
          title={' Preview'}
          style={{
            color: colors.highlight,
            textAlign: 'left'
          }}
        />
      </Flex>
    )
  }
})

export const FORM_MNG_TABLE_CONFIG: Partial<TableConfig<any>> = {
  name: TableNameEnum.FormsManagementTable,
  headerContainerStyle,
  LargeRow: DynamicAlternatingHoverableRow,
  SmallRow: SmallRow
}

interface getFormsManagementTableArgs {
  handleEditForm: (form: FormValues) => void
}

export const getFormsTableConfig = ({
  handleEditForm
}: getFormsManagementTableArgs) => {
  const table = Object.assign({}, FORM_MNG_TABLE_CONFIG) as TableConfig<any>

  let columns = [
    formNameColumn,
    formTypeColumn,
    activeInactiveColumn,
    editPreviewColumn({
      handleEditForm
    })
  ]
  table.columns = columns
  table.showTotalElements = true

  return table
}
