import React, { FC, useCallback, useEffect, useState } from 'react'
import { Switch, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'

import { ScreenContainerWithTabs } from '../../../components/layout/ScreenContainer'

import withNavigationPermissions from '../../../navigation/withNavigationPermissions'
import {
  CallForSubmission,
  CallForSubmissionStatusEnum,
  CallForSubmissionStepEnum,
  DateTime,
  InnovationAccessKey,
  OriginSourceEnum,
  RoleEnum
} from '../../../types'
import DateService from '../../../services/dateService'
import useTranslation from '../../../hooks/useTranslation'
import useUpsertCallForSubmissionMutation from '../../Curation/hooks/useUpsertCallForSubmissionMutation'
import { orderedSteps } from '../../Curation/hooks/useSetupNextStepMutation'
import { useFormik } from 'formik'

import TextInput from '../../../ui-library/TextInput'
import { Flex } from '../../../components/FlexBox'
import DateInput from '../../../ui-library/DateInput/DateInput'
import {
  ActionContainer,
  QuestionBlock,
  ScrollViewContent,
  StepDateRangeContainer,
  ThresholdsContainer,
  ThresholdsTitle,
  ThresholdTitle
} from './components/SettingsStyledComponents'
import {
  calculateSteps,
  generateGeneralSettingsInitialValues,
  getGeneralSettingsValidationSchema,
  Steps
} from './formUtils'

import Button from '../../../ui-library/Button'
import useToast from '../../../hooks/useToast'
import WizardStep from './components/WizardStep'
import { Text } from '../../../components/common/Text'
import SkeletonContainer from '../../../components/skeletonLoadings/SkeletonContainer'
import { DisclaimerForm } from '../components/CallForSubmissionFormScreen/CallForSubmissionForm'
import { GeneralSettingsSkeleton } from './components/Skeleton'
import {
  FAVORITES_DEFAULT_LIMIT,
  SHORTLISTED_DEFAULT_LIMIT
} from '../constants'
import changeText, { removeNonNumeric } from '../../../utils/changeFormikText'
import useHasPermission from '../../../hooks/useHasPermission'
import DeleteDialog from '../../../components/common/Dialog/DeleteDialog'
import useLinkToScreen from '../../../hooks/useLinkToScreen'

const AcademyFiledRow = styled.View`
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.space[3]}px;
`

interface StepDateRangeFormField {
  id?: string
  curationStep: CallForSubmissionStepEnum
  openAt: DateTime
  closeAt: DateTime
}

interface GeneralFormValues {
  name: string
  stepDateRanges: StepDateRangeFormField[]
  academyElegible: boolean
  shortlistLimit: number
  favoritesLimit: number
  disclaimer?: boolean
  disclaimerEventLocation?: string
  disclaimerInnovatorFee?: string
  disclaimerContactEmail?: string
}

const { getDate, getDateByAddingTime } = DateService

interface SettingsProps {
  callForSubmission: CallForSubmission
  isLoading: boolean
}

const GeneralSettings: FC<SettingsProps> = ({
  callForSubmission,
  isLoading
}) => {
  const { t } = useTranslation()
  const { space, colors } = useTheme()
  const isSmallScreen = useIsSmallScreen()
  const { setToastMessage } = useToast()
  const linkToScreen = useLinkToScreen()
  const {
    upsertCallForSubmission,
    loading: isProcessing
  } = useUpsertCallForSubmissionMutation(callForSubmission?.id)
  const isAdmin = useHasPermission([RoleEnum.Admin])
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)

  const [defaultValues, setDefaultValues] = useState<GeneralFormValues>({
    name: '',
    stepDateRanges: [],
    academyElegible: false,
    shortlistLimit: SHORTLISTED_DEFAULT_LIMIT,
    favoritesLimit: FAVORITES_DEFAULT_LIMIT,
    disclaimer: false,
    disclaimerEventLocation: '',
    disclaimerInnovatorFee: '',
    disclaimerContactEmail: ''
  })
  const [steps, setSteps] = useState<Steps | {}>({})

  const generateSteps = (
    callForSubmission,
    stepDateRanges,
    academyEligible,
    shortlistLimit,
    favoritesLimit,
    disclaimer,
    disclaimerEventLocation,
    disclaimerInnovatorFee,
    disclaimerContactEmail
  ) => {
    if (callForSubmission) {
      const calculatedSteps = calculateSteps(stepDateRanges)
      setSteps(calculatedSteps)
      setDefaultValues(
        // @ts-ignore
        generateGeneralSettingsInitialValues(
          callForSubmission,
          calculatedSteps,
          academyEligible,
          shortlistLimit,
          favoritesLimit,
          disclaimer,
          disclaimerEventLocation,
          disclaimerInnovatorFee,
          disclaimerContactEmail
        )
      )
    }
  }

  useEffect(() => {
    generateSteps(
      callForSubmission,
      callForSubmission?.stepDateRanges,
      callForSubmission?.academyElegible,
      callForSubmission?.shortlistLimit,
      callForSubmission?.favoritesLimit,
      callForSubmission?.disclaimer,
      callForSubmission?.disclaimerEventLocation,
      callForSubmission?.disclaimerInnovatorFee,
      callForSubmission?.disclaimerContactEmail
    )
  }, [callForSubmission?.stepDateRanges])

  const today = new Date()

  const getInitialDateValidation = (startAt: Date) => {
    return startAt > today ? today : startAt
  }

  const getStepIndex = (curationStep: CallForSubmissionStepEnum) => {
    return orderedSteps.findIndex(step => step === curationStep)
  }

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    submitForm,
    dirty,
    resetForm,
    isValid
  } = useFormik<GeneralFormValues>({
    validationSchema: getGeneralSettingsValidationSchema(t),
    // @ts-ignore
    initialValues: defaultValues,
    enableReinitialize: true,
    onSubmit: async ({
      name,
      stepDateRanges,
      shortlistLimit,
      favoritesLimit
    }: GeneralFormValues) => {
      const [
        submissionStepDateRange,
        advisingStepDateRange,
        votingStepDateRange,
        selectionStepDateRange,
        eventStepDateRange
      ] = stepDateRanges

      const { data } = await upsertCallForSubmission({
        variables: {
          originSource: OriginSourceEnum.Innovation,
          id: callForSubmission.id,
          name: name?.trim?.() ?? '',
          shortlistLimit: Number(shortlistLimit),
          favoritesLimit: Number(favoritesLimit),
          // Disclaimer Fields
          disclaimer: values?.disclaimer ?? false,
          disclaimerEventLocation: values?.disclaimerEventLocation?.length
            ? values?.disclaimerEventLocation?.trim?.()
            : undefined,
          disclaimerInnovatorFee: values?.disclaimerInnovatorFee
            ? values?.disclaimerInnovatorFee?.trim?.()
            : undefined,
          disclaimerContactEmail: values?.disclaimerContactEmail
            ? values?.disclaimerContactEmail?.trim?.()
            : undefined,
          stepDateRanges: [
            submissionStepDateRange,
            {
              id: steps?.preCurateStep?.id,
              curationStep: CallForSubmissionStepEnum.preCurate,
              openAt: submissionStepDateRange.closeAt,
              closeAt: advisingStepDateRange.openAt
            },
            advisingStepDateRange,
            {
              id: steps?.cohortStep?.id,
              curationStep: CallForSubmissionStepEnum.cohort,
              openAt: advisingStepDateRange.closeAt,
              closeAt: votingStepDateRange.openAt
            },
            votingStepDateRange,
            {
              id: steps?.finalSelectionsStep?.id,
              curationStep: CallForSubmissionStepEnum.finalSelections,
              openAt: votingStepDateRange.closeAt,
              closeAt: selectionStepDateRange.openAt
            },
            selectionStepDateRange,
            eventStepDateRange
          ],
          academyElegible: values.academyElegible
        }
      })

      if (data && data.upsertCallForSubmission.stepDateRanges) {
        generateSteps(
          callForSubmission,
          data.upsertCallForSubmission.stepDateRanges,
          data.upsertCallForSubmission.academyElegible,
          data.upsertCallForSubmission.shortlistLimit,
          data.upsertCallForSubmission.favoritesLimit,
          data.upsertCallForSubmission.disclaimer,
          data.upsertCallForSubmission.disclaimerEventLocation,
          data.upsertCallForSubmission.disclaimerInnovatorFee,
          data.upsertCallForSubmission.disclaimerContactEmail
        )
        setToastMessage(t(`callsForSubmission:success:changesSaved`))
      }
    }
  })

  const handleSwitchToggle = useCallback(
    () => setFieldValue('academyElegible', !values.academyElegible),
    [values.academyElegible]
  )

  const stepIndex = getStepIndex(callForSubmission?.currentStep?.name!)

  const { stepDateRanges } = values

  const getErrorMessage = (index, field) => {
    // @ts-ignore
    return errors?.stepDateRanges?.length > 0 && errors?.stepDateRanges[index]
      ? errors?.stepDateRanges[index][field]
      : null
  }

  const isEnabledDeleteButton =
    callForSubmission.status === CallForSubmissionStatusEnum.upcoming && isAdmin

  const handleChangeDate = (value, field) => {
    let newValue: Date | undefined
    if (value) {
      newValue = DateService.convertToTimeZone(value)
    }
    setFieldValue(field, newValue)
  }
  return (
    <ScreenContainerWithTabs>
      <ScrollViewContent>
        <SkeletonContainer
          isLoading={isLoading || !callForSubmission}
          Skeleton={GeneralSettingsSkeleton}
        >
          <View>
            <QuestionBlock style={{ maxWidth: '950px' }}>
              <TextInput
                name="name"
                value={values.name}
                errorMessage={errors.name}
                onChange={handleChange}
                label={t('curation:general:form:name')}
              />
            </QuestionBlock>
            <ThresholdsTitle>
              {t('curation:general:form:votingThresholds')}:
            </ThresholdsTitle>
            <ThresholdsContainer>
              <ThresholdTitle>
                {t('curation:general:form:shortlistLimit')}
              </ThresholdTitle>
              <TextInput
                name="shortlistLimit"
                value={`${values.shortlistLimit}`}
                errorMessage={errors.shortlistLimit}
                onChangeText={value => {
                  changeText(
                    'shortlistLimit',
                    removeNonNumeric(value),
                    setFieldValue
                  )
                }}
                containerStyles={{
                  flexDirection: 'column'
                }}
              />
              <ThresholdTitle>
                {t('curation:general:form:favoritesLimit')}
              </ThresholdTitle>
              <TextInput
                containerStyles={{
                  flexDirection: 'column'
                }}
                name="favoritesLimit"
                value={`${values.favoritesLimit}`}
                errorMessage={errors.favoritesLimit}
                onChangeText={value => {
                  changeText(
                    'favoritesLimit',
                    removeNonNumeric(value),
                    setFieldValue
                  )
                }}
              />
            </ThresholdsContainer>
            <AcademyFiledRow>
              <Text>{t('curation:general:form:academyEligible')}:</Text>
              <Switch
                value={values.academyElegible}
                onValueChange={handleSwitchToggle}
                style={{
                  marginLeft: space[3],
                  marginRight: space[4],
                  transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }]
                }}
                trackColor={{
                  true: colors.highlight,
                  false: colors.border
                }}
                thumbColor={colors.background}
                // @ts-expect-error
                activeThumbColor={colors.background}
              />
              {values.disclaimer ? (
                <DisclaimerForm
                  formik={{ values, errors, handleChange, setFieldValue }}
                />
              ) : null}
            </AcademyFiledRow>
            <View
              style={{
                marginTop: space[4]
              }}
            >
              {stepDateRanges?.map((stepDateRange, i) => {
                const stepName = t(
                  `callsForSubmission:step:name:${stepDateRange.curationStep}`
                )
                const prevStep = values.stepDateRanges[i - 1]
                const nextStep = values.stepDateRanges[i + 1]
                const stepFieldIndex = getStepIndex(stepDateRange.curationStep)
                const isEnabled = stepIndex <= stepFieldIndex + 1
                const orderedIndex = orderedSteps.findIndex(
                  step => step === stepDateRange.curationStep
                )
                const stepInBetween = orderedSteps[orderedIndex + 1]
                const inBetweenStepEnabled =
                  stepInBetween &&
                  stepDateRange.closeAt &&
                  nextStep.openAt &&
                  stepInBetween !== CallForSubmissionStepEnum.event
                const stepInBetweenDateOpen = stepDateRange.closeAt
                const stepInBetweenDateClose = nextStep?.openAt

                const convertDateToEST = date => {
                  return date
                    ? DateService.convertToLocalZone(new Date(date))
                    : undefined
                }

                return (
                  <View key={`stepDateRange-${i}`}>
                    <Flex flexDirection="row" w="100%">
                      <WizardStep
                        stepName={stepName}
                        curationStep={stepDateRange.curationStep}
                      />
                      <StepDateRangeContainer stepName={stepName}>
                        <DateInput
                          isReadOnly={!isEnabled}
                          isDateIconVisible={isEnabled}
                          isClosableIconVisible={isEnabled}
                          value={convertDateToEST(stepDateRange.openAt)}
                          label={t('curation:nextSteps:openAt')}
                          handleChange={value =>
                            handleChangeDate(
                              value,
                              `stepDateRanges[${i}].openAt`
                            )
                          }
                          minimumDate={
                            prevStep
                              ? getDateByAddingTime(prevStep.closeAt, 1, 'day')
                              : i === 0
                              ? getInitialDateValidation(
                                  getDate(stepDateRange.openAt)
                                )
                              : undefined
                          }
                          maximumDate={
                            stepDateRange.openAt
                              ? getDateByAddingTime(
                                  stepDateRange.closeAt,
                                  -1,
                                  'day'
                                )
                              : undefined
                          }
                          containerStyles={{ backgroundColor: 'white' }}
                          parentContainerStyles={{
                            width: isSmallScreen ? '100%' : '47%'
                          }}
                          dateIconColor={colors.highlight}
                          errorMessage={getErrorMessage(i, 'openAt')}
                          withTime
                        />
                        <DateInput
                          dateIconColor={colors.highlight}
                          containerStyles={{ backgroundColor: 'white' }}
                          parentContainerStyles={{
                            width: isSmallScreen ? '100%' : '47%'
                          }}
                          isReadOnly={!isEnabled || !stepDateRange.openAt}
                          isDateIconVisible={isEnabled}
                          isClosableIconVisible={isEnabled}
                          value={convertDateToEST(stepDateRange.closeAt)}
                          label={t('curation:nextSteps:closeAt')}
                          handleChange={value =>
                            handleChangeDate(
                              value,
                              `stepDateRanges[${i}].closeAt`
                            )
                          }
                          minimumDate={getDateByAddingTime(
                            stepDateRange.openAt,
                            1,
                            'day'
                          )}
                          maximumDate={
                            nextStep?.openAt
                              ? getDateByAddingTime(nextStep.openAt, -1, 'day')
                              : undefined
                          }
                          errorMessage={getErrorMessage(i, 'closeAt')}
                          withTime
                        />
                      </StepDateRangeContainer>
                    </Flex>
                    {inBetweenStepEnabled && (
                      <Flex flexDirection="row" w="100%">
                        <WizardStep
                          stepName={t(
                            `callsForSubmission:step:name:${stepInBetween}`
                          )}
                        />
                        <StepDateRangeContainer
                          stepName={t(
                            `callsForSubmission:step:name:${stepInBetween}`
                          )}
                        >
                          <DateInput
                            withTime
                            isReadOnly
                            isDateIconVisible={false}
                            isClosableIconVisible={false}
                            value={convertDateToEST(stepInBetweenDateOpen)}
                            label={t('curation:nextSteps:openAt')}
                            containerStyles={{ backgroundColor: 'white' }}
                            parentContainerStyles={{
                              width: isSmallScreen ? '100%' : '47%'
                            }}
                            dateIconColor={colors.highlight}
                          />
                          <DateInput
                            withTime
                            dateIconColor={colors.highlight}
                            containerStyles={{ backgroundColor: 'white' }}
                            parentContainerStyles={{
                              width: isSmallScreen ? '100%' : '47%'
                            }}
                            isReadOnly
                            isDateIconVisible={false}
                            isClosableIconVisible={false}
                            value={convertDateToEST(stepInBetweenDateClose)}
                            label={t('curation:nextSteps:closeAt')}
                          />
                        </StepDateRangeContainer>
                      </Flex>
                    )}
                  </View>
                )
              })}
            </View>
          </View>
        </SkeletonContainer>
      </ScrollViewContent>

      <ActionContainer>
        <Button
          type="outline"
          onPress={() => {
            resetForm()
            let routeName = 'CurationSubmissions'
            let params = {
              id: callForSubmission?.id
            }
            linkToScreen(routeName, params)
          }}
          title={t('curation:buttons:cancel')}
          containerStyle={{
            marginRight: space[3]
          }}
        />
        <Button
          type="solid"
          onPress={async () => {
            await submitForm()
          }}
          title={t('curation:buttons:save')}
          disabled={dirty ? !isValid : true}
          loading={isProcessing}
        />
        <Button
          type="solid"
          onPress={() => {
            setOpenDeleteDialog(true)
          }}
          title={t('product:general:deleteCallForSubmission')}
          disabled={!isEnabledDeleteButton}
          containerStyle={{
            marginLeft: space[3]
          }}
          buttonStyle={{
            backgroundColor: colors.danger
          }}
        />
      </ActionContainer>
      <DeleteDialog
        callForSubmissionId={callForSubmission.id}
        isCFSDelete
        openDialog={openDeleteDialog}
        triggerClose={() => setOpenDeleteDialog(false)}
      />
    </ScreenContainerWithTabs>
  )
}

const GeneralSettingsWithPerms = withNavigationPermissions(GeneralSettings, [
  InnovationAccessKey.CURATE
])

export default GeneralSettingsWithPerms
