import React, { useContext, useMemo, useRef } from 'react'
import useTableRow from '../../../hooks/useTableRow'
import useIsSmallScreen from '../../../../../hooks/useIsSmallScreen'
import { TableContext } from '../../../hooks/TableProvider'
import { Cell, TableRowLarge, TableRowSmall } from '../SharedStyledComponents'
import { getCellWidth } from '../../../utils'
import { RowComponentsProps, RowProps } from '../types'
import DetailedView from './DetailedView'
import { withTheme } from 'styled-components/native'
import useFeatureFlag from '../../../../../hooks/useFeatureFlag'

const RowComponents = (props: RowComponentsProps<any>) => {
  const rowRef = useRef<HTMLDivElement>(null)
  const {
    config,
    columns,
    item,
    isReadOnly,
    isItemSelected,
    handleSelectItems,
    leftTable,
    leftTableWidth,
    rigthTableWidth,
    index,
    selectedItems,
    isSmallScreen,
    handleDetailedViewItems,
    theme
  } = props

  const {
    LargeRow,
    SmallRow,
    queryDynamicName,
    rowHeight = -1 as Number,
    tableId,
    isSticky
  } = config

  const handlers = useTableRow({ config })
  const rebrand = useFeatureFlag('innovationRebrandPlatform')

  const LargeRowComponent = LargeRow || TableRowLarge
  const SmallRowComponent = SmallRow || TableRowSmall

  const RowComponent = isSmallScreen ? SmallRowComponent : LargeRowComponent

  const rowComponentId = `table-row-index-${index}${
    tableId ? `-${tableId}` : ``
  }${leftTable ? `-left` : ``}`
  const secondRowComponentId = `table-row-index-${index}${
    tableId ? `-${tableId}` : ``
  }${!leftTable ? `-left` : ``}`

  const handleHover = (value: boolean) => {
    if (rowRef.current) {
      const rowComponent = document.getElementById(secondRowComponentId)
      const rowComponent2: HTMLElement = rowRef.current
      const rowColor = value
        ? theme.colors.tableRowHover
        : rebrand
        ? theme.colors.containerBackground
        : theme.colors.tableRowAlternate
      if (rowComponent) {
        const childNode = rowComponent.childNodes[0]
        childNode.style.backgroundColor = rowColor
      }
      if (rowComponent2) {
        const childNode = rowComponent2.childNodes[0]
        childNode.style.backgroundColor = rowColor
      }
    }
  }

  const isSelected = isItemSelected(item?.id)

  const rowComponentProps = isSmallScreen
    ? {}
    : {
        leftTable,
        isSticky,
        onMouseEnter: () => handleHover(true),
        onMouseLeave: () => handleHover(false),
        rowStyle: index % 2 === 0 ? 'even' : 'odd',
        isSelected,
        rowHeight
      }

  const selectItemProps = {
    isSelected,
    onSelect: id => {
      handleSelectItems([id])
    },
    selectedItems,
    isDisabled: isReadOnly
  }

  const cellProps = {
    handlers,
    isReadOnly,
    isSmallScreen,
    selectItemProps,
    queryDynamicName,
    handleDetailedViewItems,
    tableName: config.queryDynamicName
  }

  const getRowWidth = (width?: Number) =>
    isSmallScreen
      ? '100'
      : getCellWidth(
          (width && width?.valueOf()) ?? 0,
          leftTable ?? false,
          leftTableWidth,
          rigthTableWidth
        )

  return (
    <div ref={rowRef} id={rowComponentId}>
      <RowComponent testID={'tableRow'} {...rowComponentProps}>
        {columns.map((col, index) => {
          const {
            Cell: ItemCell,
            width,
            minWidth,
            isEditable,
            header,
            itemMap,
            role,
            cellStyle,
            ...rest
          } = col
          return (
            <Cell
              key={`cell-${index}`}
              minWidth={isSmallScreen ? undefined : minWidth?.valueOf()}
              cellStyle={cellStyle}
              width={getRowWidth(width)}
            >
              <ItemCell
                key={`item-cell-${index}`}
                item={item}
                itemMap={itemMap}
                isEditable={isEditable}
                label={header}
                role={role}
                {...cellProps}
                {...rest}
              />
            </Cell>
          )
        })}
      </RowComponent>
    </div>
  )
}

const Row = (props: RowProps) => {
  const {
    config,
    fixedColumns,
    dynamicColumns,
    filteredColumns,
    isReadOnly,
    selectedItems,
    isItemSelected,
    handleSelectItems,
    leftTableWidth,
    rigthTableWidth,
    selectedAll,
    handleDetailedViewItems,
    detailedViewItems
  } = useContext(TableContext)
  const isSmallScreen = useIsSmallScreen()
  const { item, leftTable, index, theme } = props

  const showDetailedView = useMemo(() => detailedViewItems.includes(item?.id), [
    detailedViewItems
  ])

  return (
    <>
      <RowComponents
        leftTable={leftTable}
        config={config}
        columns={
          isSmallScreen
            ? filteredColumns
            : leftTable
            ? fixedColumns
            : dynamicColumns
        }
        item={item}
        selectedItems={selectedItems}
        isReadOnly={isReadOnly}
        isItemSelected={isItemSelected}
        handleSelectItems={handleSelectItems}
        leftTableWidth={leftTableWidth}
        rigthTableWidth={rigthTableWidth}
        index={index}
        selectedAll={selectedAll}
        isSmallScreen={isSmallScreen}
        handleDetailedViewItems={handleDetailedViewItems}
        theme={theme}
      />
      {showDetailedView && config.getDetailedViewComponents ? (
        <DetailedView
          isSticky={config.isSticky ?? false}
          item={item}
          getDetailedViewComponents={config.getDetailedViewComponents}
          leftTable={leftTable ?? false}
        />
      ) : null}
    </>
  )
}

export default withTheme(Row)
