import React from 'react'
import { ScrollView, Text, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { Flex } from '../../../../components/FlexBox'
import { InputContainer } from '../../../../components/DynamicForm/groups/SharedStyledComponents'
import { Container } from '../../../../components/common/SharedStyledComponents'
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'

export const Title = styled(Text)`
  ${({ theme: { sizes, colors, fontWeights, fontSizes }, isSmallScreen }) => `
  color: ${colors.text2};
  font-size: ${isSmallScreen ? fontSizes[4] : sizes[3] + sizes[1]}px;
  font-weight: ${fontWeights.regular};
`}
`

export const PercentageLabel = styled(Text)`
  ${({ theme: { colors, space, fontWeights, fontSizes } }) => `
  color: ${colors.text};
  font-size: ${fontSizes[4]}px;
  font-weight: ${fontWeights.regular};
  padding-top: ${space[3]}px;
  padding-right: ${space[3]}px;
  padding-left: ${space[1]}px;
`}
`

export const HorizontalSeparator = styled(View)`
  ${({ theme: { space, colors } }) => `
  height: 1px;
  margin-top: ${space[4]}px;
  margin-bottom: ${space[4]}px;
  background-color: ${colors.tints.grays.g100};
`}
`

export const ScrollViewContent = styled(ScrollView)`
  ${({ theme: { space }, isSmallScreen }) => `
    padding: ${isSmallScreen ? space[3] : space[4] + space[2]}px;
  `}
`

export const ActionContainer = styled(Flex)`
  ${({ theme: { space } }) => `
  margin: ${space[1]}px ${space[1]}px ${space[1]}px
    ${space[3]}px;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${space[2]}px 0 ${space[4]}px ${space[4]}px;
`}
`

export const QuestionBlock = styled(Flex)`
  ${({ theme: { space } }) => `
  margin: 0 0 ${space[3]}px 0px;

`}
`
export const ThresholdsContainer = styled(View)`
  flex-direction: row;
`
export const ThresholdsTitle = styled(Text)`
  ${({ theme: { space } }) => `
  margin-bottom: ${space[2] + space[1]}px;
`}
`

export const ThresholdTitle = styled(Text)`
  ${({ theme: { space } }) => `
  margin: ${space[2] + space[1]}px ${space[2]}px ${space[1]}px
    ${space[3]}px;
`}
`

const StepNameText = styled(Text)`
  ${({ theme: { space } }) => `
  margin-bottom: ${space[2]}px;
`}
`

export const StepDateRangeContainer = ({
  stepName,
  isAlternativeView = false,
  children
}) => {
  const { colors } = useTheme()
  const isSmallScreen = useIsSmallScreen()
  return (
    <Flex
      flexDirection="column"
      flexWrap="wrap"
      width={isAlternativeView ? '100%' : '85%'}
      maxWidth={isAlternativeView ? '950px' : '800px'}
    >
      {isSmallScreen && <StepNameText>{stepName}</StepNameText>}
      <Container
        flexDirection="column"
        flexWrap="wrap"
        backgroundColor={colors.formCard}
        borderColor={colors.formCard}
        width="100%"
        styles={{
          marginTop: 0,
          marginBottom: 30
        }}
      >
        <InputContainer
          style={{
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'space-between',
            w: '100%'
          }}
        >
          {children}
        </InputContainer>
      </Container>
    </Flex>
  )
}

export const QuestionWeightContainer = ({ children }) => {
  return (
    <Flex>
      <Flex flexDirection="row" alignItems="center" flexWrap="wrap">
        {children}
      </Flex>
    </Flex>
  )
}

export const WarningContainer = styled(Flex)`
  ${({ theme }) => `
    padding: ${theme.space[3]}px ${theme.space[2]}px;
    border-radius: 5px;
    margin-bottom: ${theme.space[3]}px;
    background-color: ${theme.colors.primaryPalette.alert};
  `}
`
export const WarningText = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.primaryPalette.white};
    font-size: ${theme.sizes[3]}px;
  `}
`
