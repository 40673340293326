import React, { ReactNode, useEffect } from 'react'
import { ScrollView, View, ViewStyle } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native'
import {
  CustomHeaderContainer,
  CustomHeaderContent,
  HeaderProps,
  ItemHeaderContainer,
  ItemHeaderContent,
  MenuHeaderContainer,
  MenuHeaderContent
} from './AppHeader'
import { useSetRecoilState } from 'recoil'
import appHeaderAtom from '../../recoil/appHeaderAtom'
import ToastAnchor from '../ToastAnchor'
import { Flex } from '../FlexBox'

interface ScreenContainerWithMenuHeaderProps {
  title?: string
  headerLogo?: boolean
  webTitle?: string
  children?: any
  screenCategory: string | null
}

export function ScreenContainerWithMenuHeader(
  props: ScreenContainerWithMenuHeaderProps
) {
  return <ScreenContainer header="menu-header" {...props} />
}

interface ScreenContainerWithItemHeaderProps {
  title?: string
  defaultBack?: string
  onBackPress?: Function
  HeaderRight?: React.ComponentType
  renderedHeaderRight?: ReactNode
  HeaderBottom?: React.ComponentType
  headerStyle?: ViewStyle
  webTitle?: string
  children?: any
  screenCategory: string | null
}

export function ScreenContainerWithItemHeader(
  props: ScreenContainerWithItemHeaderProps
) {
  return <ScreenContainer header="item-header" {...props} />
}

interface ScreenContainerWithCustomHeaderProps {
  Header?: React.ComponentType<HeaderProps>
  headerProps?: any
  headerStyle?: ViewStyle
  webTitle?: string
  children?: any
}

export function ScreenContainerWithCustomHeader(
  props: ScreenContainerWithCustomHeaderProps
) {
  return <ScreenContainer {...props} />
}

interface ScreenContainerProps {
  header?: 'none' | 'menu-header' | 'item-header' | 'custom' | 'unset'
  Header?: React.ComponentType<HeaderProps>
  headerProps?: any
  title?: string
  webTitle?: string
  defaultBack?: string
  onBackPress?: Function
  HeaderRight?: React.ComponentType
  renderedHeaderRight?: ReactNode
  HeaderBottom?: React.ComponentType
  headerStyle?: ViewStyle
  headerLogo?: boolean
  withoutPadding?: boolean
  children?: any
  style?: ViewStyle
}

const ScreenContainer = ({
  header = 'unset',
  Header,
  headerProps,
  title,
  webTitle,
  HeaderRight,
  renderedHeaderRight,
  HeaderBottom,
  headerStyle,
  defaultBack,
  onBackPress,
  withoutPadding = false,
  style,
  children
}: ScreenContainerProps) => {
  const route = useRoute()
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const setAppHeader = useSetRecoilState(appHeaderAtom)

  const pageTitle = title ?? route?.name
  if (Header) {
    header = 'custom'
  }

  const getHeader = headerName => {
    const dictionary = {
      'menu-header': () => (
        <MenuHeaderContainer style={headerStyle}>
          <MenuHeaderContent />
          {HeaderBottom && <HeaderBottom />}
          <ToastAnchor />
        </MenuHeaderContainer>
      ),
      'item-header': () => (
        <ItemHeaderContainer style={headerStyle}>
          <ItemHeaderContent
            pageTitle={pageTitle}
            HeaderRight={HeaderRight}
            renderedHeaderRight={renderedHeaderRight}
            defaultBack={defaultBack}
            onBackPress={onBackPress}
            navigation={navigation}
          />
          {HeaderBottom && <HeaderBottom />}
          <ToastAnchor />
        </ItemHeaderContainer>
      ),
      custom: () => (
        <CustomHeaderContainer style={headerStyle}>
          <CustomHeaderContent
            Header={Header}
            headerProps={headerProps}
            navigation={navigation}
            onBackPress={onBackPress}
          />
          {HeaderBottom && <HeaderBottom />}
          <ToastAnchor />
        </CustomHeaderContainer>
      ),
      default: () => null
    }

    const found = dictionary[headerName]
    return found ? found() : dictionary.default()
  }

  // Setting AppHeader when screen focus changes
  useEffect(() => {
    if (!isFocused) return
    // screen has specified no header info so leave current header config
    if (header === 'unset') return
    // screen has specifically requested no header
    if (header === 'none') {
      return setAppHeader(null)
    }
    // otherwise, set requested header info in atom
    setAppHeader(getHeader(header))
  }, [isFocused, header, headerStyle, HeaderBottom])

  useEffect(() => {
    if (!isFocused) return
    navigation.setOptions({
      webTitle: webTitle || title || ''
    })
  }, [isFocused, webTitle, title])

  return (
    <StyledScreenContainer style={style} withoutPadding={withoutPadding}>
      {children}
    </StyledScreenContainer>
  )
}

const StyledScreenContainer = styled(View)`
  ${({ theme: { space }, isSmallScreen, withoutPadding }) => `
    flex: 1;
    z-index: 100;
    padding: ${isSmallScreen || withoutPadding ? 0 : space[4]}px;
  `}
`

export const StyledViewContainer = styled(View)`
  ${({ theme: { space, colors, radii } }) => `
    padding: ${space[3]}px;
    background: ${colors.containerBackground};
    border-radius: ${radii[4]}px;
    box-shadow: #0000001a 0px 4px 5.65px;
  `}
`

export const StyledViewContainerAutoHeight = styled(View)`
  ${({ theme: { space, colors, radii } }) => `
    padding: ${space[3]}px;
    background: ${colors.containerBackground};
    border-radius: ${radii[4]}px;
    box-shadow: #0000001a 0px 4px 5.65px;
    height: 100%;
    overflow: hidden;
  `}
`

export const StyledFixedHeightViewContainer = styled(View)`
  ${({ theme: { space, colors, radii } }) => `
    padding: ${space[3]}px;
    background: ${colors.containerBackground};
    border-radius: ${radii[4]}px;
    box-shadow: #0000001a 0px 4px 5.65px;
    overflow: hidden;
    height: 100%;
  `}
`

export const StyledScrollViewContainer = styled(ScrollView)`
  ${({ theme: { space, colors, radii } }) => `
    padding: ${space[3]}px;
    background: ${colors.containerBackground};
    border-radius: ${radii[4]}px;
    box-shadow: #0000001a 0px 4px 5.65px;
  `}
`

export const ShadowContainerWithTabs = styled(Flex)`
  ${({ theme: { colors, radii } }) => `
    flex: 1;
    padding: 0 0 10px 0;
    background: ${colors.containerBackground};
    border-radius: ${radii[4]}px;
    box-shadow: #0000001a 0px 4px 5.65px;
  `}
`

export const ScreenContainerWithTabs = ({ children }) => {
  const { colors } = useTheme()
  return (
    <ScreenContainer
      header="menu-header"
      withoutPadding
      style={{
        backgroundColor: colors.containerBackground
      }}
    >
      {children}
    </ScreenContainer>
  )
}

export default ScreenContainer
