import React, { FC, useState, useEffect } from 'react'
import { Message, MessageStatus } from '../../../recoil/toastMessageAtom'
import { withTheme } from 'styled-components/native'
import { ToastContainer, ToastText } from './SharedStyledComponents'

interface ModalToastProps {
  message: Message | null
  testID?: string
  theme: any
}

const DEFAULT_TIMEOUT = 5000

const ModalToast: FC<ModalToastProps> = ({ message, testID, theme }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!!message && !show) {
      setShow(true)

      setTimeout(() => setShow(false), DEFAULT_TIMEOUT)
    }
  }, [message])

  return show ? (
    <ToastContainer
      testID={testID ?? undefined}
      style={{
        backgroundColor:
          message?.status === MessageStatus.Success
            ? theme.colors.cta.secondary
            : theme.colors.danger
      }}
    >
      <ToastText>{message?.message}</ToastText>
    </ToastContainer>
  ) : null
}

export default withTheme(ModalToast)
