import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Text } from 'react-native-elements'
import styled, { withTheme } from 'styled-components/native'

interface MenuItemProps {
  Icon?: JSX.Element
  label: string
  testID?: string
  highlight?: boolean
  onPress: () => void
  style?: object | object[]
  theme: any
}

const Label = styled(Text)`
  ${({ theme: { space, fontSizes, fontWeights, colors } }) => `
  font-size: ${fontSizes[4]}px;
  line-height: ${fontSizes[6]}px;
  margin-left: ${space[3]}px;
  font-weight: ${fontWeights.regular};
  color: ${colors.menuItem};
  `}
`

const LabelH = styled(Label)`
  ${({ theme: { fontWeights, colors } }) => `
  font-weight: ${fontWeights.medium};
  color: ${colors.highlight};
  `}
`

const ItemContainer = styled(TouchableOpacity)`
  ${({ theme: { space, colors, borderWidths }, highlight }) => `
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: ${space[1]}px ${space[4]}px;
    margin: ${space[3]}px 0;
    border-left-color: ${highlight ? colors.highlight : 'transparent'};
    border-left-width: ${borderWidths[3]}px;
    border-left-style: solid;
  `}
`

const MenuItem = ({
  Icon,
  label,
  onPress,
  highlight,
  style,
  testID,
  theme
}: MenuItemProps) => {
  const decoratedIcon =
    highlight && Icon
      ? React.cloneElement(
          Icon,
          highlight
            ? { color: theme.colors.highlight }
            : { color: theme.colors.menuItem }
        )
      : Icon
  const LabelComponent = highlight ? LabelH : Label
  return (
    <ItemContainer
      testID={testID}
      onPress={onPress}
      highlight={highlight}
      style={style}
    >
      {decoratedIcon}
      <LabelComponent>{label}</LabelComponent>
    </ItemContainer>
  )
}

export default withTheme(MenuItem)
