import React, { useEffect, useState } from 'react'
import { Switch, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { useRoute } from '@react-navigation/native'
// Hooks
import useToast from '../../hooks/useToast'
import useTranslation from '../../hooks/useTranslation'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import useCompanyQuery from '../../hooks/useCompanyQuery'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import useInnovationAccess from '../../hooks/useInnovationAccess'
import useCreteAlumniMembershipMutation from '../../hooks/useCreateAlumniMembershipMutation'
import useDeleteAlumniMembershipMutation from '../../hooks/useDeleteAlumniMembershipMutation'
// Components
import Dialog from '../../components/common/Dialog'
import CompanyProfile from './components/CompanyProfile'
import { Text } from '../../components/common/Text'
import {
  ScreenContainerWithMenuHeader,
  StyledScrollViewContainer
} from '../../components/layout/ScreenContainer'
import { LoadingIndicator } from '../../components/common/LoadingIndicator'
import {
  Container,
  Title
} from '../CallForSubmission/components/SharedComponents'
import CompanyLogoUploader from './components/CompanyLogoUploader'
// Types
import { ApolloError } from '@apollo/client'

// Styled Components
const RowContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const InnovatorDirectoryDetails = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { canCreateInnovatorAlumniMembership } = useInnovationAccess()
  const { setToastMessage, setToastErrorMessage } = useToast()
  const isSmallScreen = useIsSmallScreen()
  const linkToScreen = useLinkToScreen()
  const route: any = useRoute()
  const companyId = route?.params?.companyId
  const [openDialog, setOpenDialog] = useState(false)
  const { company, loading } = useCompanyQuery(companyId)
  const [createAlumniMembership] = useCreteAlumniMembershipMutation({
    refetchQueries: ['company']
  })
  const [deleteAlumniMembership] = useDeleteAlumniMembershipMutation({
    refetchQueries: ['company']
  })
  const [alumniMembershipId, setAlumniMembershipId] = useState('')

  useEffect(() => {
    if (company) {
      setAlumniMembershipId(company?.alumniMembership?.id)
    }
  }, [company])

  if (!companyId || loading || !company) {
    return <LoadingIndicator size="large" />
  }

  const onNewMembershipCreated = () => {
    setToastMessage(t('company:isAlumniSuccessMessage'))
  }

  const onMembershipRemoved = () => {
    setAlumniMembershipId('')
    setToastMessage(t('company:isNotAlumniSuccessMessage'))
  }

  const onToggleAlumniMembership = async () => {
    setOpenDialog(false)
    if (alumniMembershipId) {
      await deleteAlumniMembership({
        variables: {
          id: alumniMembershipId
        },
        onCompleted: onMembershipRemoved,
        onError: (err: ApolloError) => {
          setToastErrorMessage(err.message)
        }
      })
    } else {
      const { data } = await createAlumniMembership({
        variables: {
          companyId: company.id
        },
        onCompleted: onNewMembershipCreated,
        onError: (err: ApolloError) => {
          setToastErrorMessage(err.message)
        }
      })
      if (data) {
        const { createAlumniMembership: membership } = data
        setAlumniMembershipId(membership.id)
      }
    }
  }

  const isAlumni = !!alumniMembershipId

  return (
    <ScreenContainerWithMenuHeader screenCategory="InnovatorDirectory">
      <StyledScrollViewContainer>
        <Title
          text={`${t('innovatorDirectory:details:title')} - ${company.name}`}
          withBackArrow
          onBackArrow={() => {
            linkToScreen('InnovatorDirectoryProfile', {
              companyId: companyId
            })
          }}
        >
          {canCreateInnovatorAlumniMembership ? (
            <View style={{ marginLeft: 'auto' }}>
              <Text>{t('company:isAlumni')}</Text>
              <Switch
                disabled={false}
                value={isAlumni}
                onValueChange={() => {
                  setOpenDialog(true)
                }}
                style={{
                  alignSelf: 'center',
                  marginTop: '10px'
                }}
                trackColor={{
                  true: colors.highlight,
                  false: colors.border
                }}
                thumbColor={colors.background}
                // @ts-expect-error
                activeThumbColor={colors.background}
              />
            </View>
          ) : null}
        </Title>

        <Dialog
          title={
            alumniMembershipId
              ? t('company:alumniDeleteConfirmTitle')
              : t('company:alumniMakeConfirmTitle')
          }
          description={t('company:alumniModifyWarning')}
          isOpen={openDialog}
          onClose={() => {
            setOpenDialog(false)
          }}
          actions={[
            {
              title: t('common:dialogs:unsavedChanges:discardBtn'),
              type: 'outline',
              onPress: () => {
                setOpenDialog(false)
              }
            },
            {
              title: t('common:dialogs:unsavedChanges:saveBtn'),
              onPress: onToggleAlumniMembership
            }
          ]}
        />

        <Container testID={'companyInfoForm'} isSmallScreen={isSmallScreen}>
          <RowContainer>
            <CompanyProfile
              company={company}
              containerStyles={{ padding: 0 }}
              refetchQueries={['directoryProfileSearchInnovationEngine']}
            />
            <CompanyLogoUploader company={company} />
          </RowContainer>
        </Container>
      </StyledScrollViewContainer>
    </ScreenContainerWithMenuHeader>
  )
}

export default InnovatorDirectoryDetails
