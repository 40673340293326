import React from 'react'
import { TouchableOpacity } from 'react-native'

import { useTheme } from 'styled-components/native'
import useTranslation from '../../../../hooks/useTranslation'

import AnimatedComponent from '../../../../components/common/AnimatedComponent'
import { Text } from '../../../../components/common/Text'
import { MenuItemProps } from '../types'

const MenuItem = (props: MenuItemProps) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { label, option, isActive, setActiveOption } = props
  return (
    <TouchableOpacity
      style={{
        marginBottom: 5,
        backgroundColor: isActive ? colors.tableRowHover : 'transparent'
      }}
      onPress={() => setActiveOption(option)}
    >
      <AnimatedComponent
        hoverOpacity
        hoverColor={colors.tableRowHover}
        style={{
          width: '100%',
          height: 50,
          justifyContent: 'center',
          borderColor: colors.highlight,
          borderBottomWidth: 1.5
        }}
      >
        <Text styles={{ textAlign: 'right', marginRight: 15 }}>{t(label)}</Text>
      </AnimatedComponent>
    </TouchableOpacity>
  )
}

export default MenuItem
