import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { isEmpty } from 'ramda'
import { useTheme } from 'styled-components/native'

import InputField from '../../../InputField'
import { FormikProvider, useFormik } from 'formik'
import {
  FormField,
  FormModalFormProps,
  FormTypeEnum,
  FormValues
} from '../../../../types'
import useToast from '../../../../../../../hooks/useToast'
import useTranslation from '../../../../../../../hooks/useTranslation'
import { CancelButton, Footer, SaveButton } from '../../../../../constants'
import useUpsertForm from '../../hooks/useUpsertForm'
import useFormsQuery from '../../../../../../Product/hooks/useFormsQuery'
import Dropdown, {
  DropdownOption
} from '../../../../../../../components/common/Dropdown'
import { Switch, View } from 'react-native'
import { Text } from '../../../../../../../components/common/Text'
import DropdownField from '../../../DropdownField'
import SwitchField from '../../../SwitchField'

const formTypesKeys = Object.keys(FormTypeEnum)
const formTypesValues = Object.values(FormTypeEnum)
const formTypesOptions: DropdownOption[] = formTypesValues.map(
  (fType, index) => ({
    value: fType,
    label: formTypesKeys[index]
  })
)

const formFields: FormField[] = [
  {
    field: 'type',
    Component: DropdownField,
    options: formTypesOptions,
    isParent: false
  },
  {
    field: 'name',
    Component: InputField,
    isParent: false
  },
  {
    field: 'isActive',
    Component: SwitchField,
    hideOnNew: true,
    isParent: false
  }
]

// Yup validation
const getAddFormValidationSchema = () =>
  Yup.object().shape({
    id: Yup.string().nullable(),
    type: Yup.mixed<FormTypeEnum>()
      .oneOf(Object.values(FormTypeEnum))
      .required(),
    name: Yup.string().required(),
    questionGroupId: Yup.string().notRequired().nullable(),
    isActive: Yup.boolean().default(true)
  })

const getInputValues = (values: FormValues, isEdit: boolean): FormValues => {
  const valuesKeys = Object.keys(values)
  const parentQuestions = ['id']

  const excludeVariables = !isEdit ? parentQuestions : []

  let result = {}
  result = isEdit ? {} : { parentId: values.id }

  for (
    let indexValueKey = 0;
    indexValueKey < valuesKeys.length;
    indexValueKey++
  ) {
    const valueKey = valuesKeys[indexValueKey]
    if (values[valueKey] && !excludeVariables.includes(valueKey)) {
      const customQuestionField = formFields.find(
        field => field.field === valueKey
      )
      if (!customQuestionField?.isParent) {
        result[valueKey] = values[valueKey]
      }
    }
  }

  return result
}

const Form = (props: FormModalFormProps) => {
  const { onClose, form, isEdit } = props
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { setToastMessage, setToastErrorMessage } = useToast()
  const [cloneExisting, setCloneExisting] = useState<boolean>(false)
  const [parentForm, setParentForm] = useState<FormValues | undefined>()
  const [formType, setFormType] = useState<FormTypeEnum | undefined>(
    FormTypeEnum.General
  )

  const { forms, loading } = useFormsQuery({
    skip: !cloneExisting
  })

  const [data, setData] = useState<FormValues[]>([])

  useEffect(() => {
    if (!loading && forms) {
      setData(forms)
    }
  }, [forms, loading])

  const formsOptions: DropdownOption[] = useMemo(
    () =>
      data.map(form => ({
        label: form.name ?? '',
        value: form.id
      })),
    [data]
  )

  // Mutations
  const [upsertForm, isLoading] = useUpsertForm({
    onError: e => console.error(e),
    onCompleted: ({ upsertForm }) => {
      if (upsertForm && !isEmpty(upsertForm)) {
        setToastMessage(
          t('platformManagement:forms:menuItems:forms:successSaved')
        )
        onClose()
      } else {
        setToastErrorMessage(
          t('platformManagement:forms:menuItems:forms:errorSave')
        )
      }
    },
    errorMessage: t('platformManagement:questionGroups:errorSave')
  })

  const currentForm = useMemo(() => parentForm || form, [parentForm, form])

  useEffect(() => {
    if (currentForm?.type) {
      setFormType(currentForm.type as FormTypeEnum)
    } else {
      setFormType(undefined)
    }
  }, [currentForm])

  const formKeys = currentForm ? Object.keys(currentForm) : undefined

  let initialValues = isEdit
    ? { questionGroupId: form?.questionGroupId }
    : { isActive: true }

  if (currentForm && formKeys) {
    for (let indexFormKey = 0; indexFormKey < formKeys.length; indexFormKey++) {
      const formKey = formKeys[indexFormKey]
      if (['id', ...formFields.map(fField => fField.field)].includes(formKey)) {
        const currentValue = currentForm[formKey]
        if (typeof currentValue === 'object' && currentValue != null) {
          initialValues[formKey] = JSON.stringify(currentValue)
        } else {
          initialValues[formKey] = currentValue ?? undefined
        }
      }
    }
  }

  // Formik
  const formik = useFormik<FormValues>({
    initialValues: { ...initialValues },
    validationSchema: getAddFormValidationSchema(),
    enableReinitialize: true,
    onSubmit: async values => {
      const input = getInputValues(values, isEdit)
      upsertForm({
        variables: { input },
        refetchQueries: ['forms']
      })
    }
  })

  const validateAndSubmit = () => {
    formik.validateForm().then(() => formik.submitForm())
  }

  const handleCloneExisting = (value: boolean) => {
    if (!value) {
      setParentForm(undefined)
    }
    formik.resetForm({ values: { name: '', type: undefined } })
    setCloneExisting(value)
  }

  useEffect(() => {
    formik.setFieldValue('type', formType)
  }, [formType])

  const enableSubmit = useMemo(() => {
    return formik.isValid && formik.dirty
  }, [formik])

  const isNew = useMemo(() => !form?.id, [form?.id])

  return (
    <FormikProvider value={formik}>
      {isNew ? (
        <View
          style={{
            paddingVertical: 10,
            width: '100%'
          }}
        >
          <Text styles={{ marginBottom: 5 }}>
            {t('platformManagement:forms:menuItems:forms:cloneExistingForm')}
          </Text>
          <Switch
            value={cloneExisting}
            onValueChange={handleCloneExisting}
            style={{
              transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }]
            }}
            trackColor={{
              true: colors.highlight,
              false: colors.border
            }}
            thumbColor={colors.background}
            // @ts-expect-error
            activeThumbColor={colors.background}
          />
          {cloneExisting ? (
            <Dropdown
              label={t(
                'platformManagement:forms:menuItems:forms:fields:parentForm'
              )}
              isClearable
              isFilter
              isDisabled={loading}
              closeMenuOnSelect
              style={{
                minHeight: 0,
                marginBottom: 0
              }}
              value={parentForm?.id ?? null}
              containerStyle={{ width: 350, height: 50, marginRight: 10 }}
              isLoading={loading}
              options={formsOptions}
              controlStyle={{ minHeight: 0 }}
              menuPortalTarget={document?.body}
              onSelect={value => {
                const currentForm = forms.find(f => f.id === value)
                setParentForm(currentForm)
              }}
              name={'parentFormSelector'}
              placeholder={t(
                'platformManagement:forms:menuItems:forms:selectorPlaceholder'
              )}
            />
          ) : null}
        </View>
      ) : null}
      {formFields
        .filter(formField => (isNew ? !formField.hideOnNew : true))
        .map(formField => {
          const { Component, field, options, isParent } = formField
          const componentProps = {
            type: 'forms',
            key: field,
            fieldName: field,
            isReadOnly:
              (isEdit && isParent) ||
              (isNew && cloneExisting && !parentForm?.id),
            options
          }

          return <Component {...componentProps} />
        })}
      <Footer>
        <CancelButton
          isProcessing={isLoading}
          onPress={onClose}
          title={t('common:buttons:cancel')}
        />
        <SaveButton
          disabled={!enableSubmit}
          onPress={validateAndSubmit}
          isProcessing={isLoading}
          title={t(`common:buttons:${isEdit ? 'update' : 'save'}`)}
        />
      </Footer>
    </FormikProvider>
  )
}

export default Form
