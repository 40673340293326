import { Text } from 'react-native-elements'
import styled from 'styled-components/native'

const EllipsizedText = styled(Text).attrs({
  ellipsizeMode: 'tail',
  numberOfLines: 2
})`
  min-width: 100%;
  overflow: hidden;
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
`

export default EllipsizedText
