import { useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

// Recoil atoms
import {
  hasUnsavedChangesAtom as hasUnsavedAtom,
  unsavedAdvisorChangesAtom as unsavedAtom,
  UnsavedAdvisorChangesAtomData
} from '../../recoil/unsavedAdvisorChangesAtomFamily'

// Types
interface useGetUnsavedAdvisorChangesI {
  dialog?: boolean
  shouldInitForm: boolean
  hasUnsavedChanges: boolean
  unsavedValues: any | null
  onNavigate?: Function | null
}

export const useGetUnsavedAdvisorChanges = (): useGetUnsavedAdvisorChangesI => {
  const unsavedState = useRecoilValue(unsavedAtom)
  const hasUnsavedState = useRecoilValue(hasUnsavedAtom)

  // Memo
  const unsavedValues = useMemo(() => unsavedState?.unsavedValues, [
    unsavedState?.unsavedValues
  ])
  const onNavigate = useMemo(() => unsavedState?.onNavigate, [
    unsavedState?.onNavigate
  ])
  const shouldInitForm = useMemo(() => !!unsavedState?.shouldInitForm, [
    unsavedState?.shouldInitForm
  ])
  const hasUnsavedChanges = useMemo(() => hasUnsavedState, [hasUnsavedState])
  const dialog = useMemo(() => unsavedState?.dialog || false, [
    unsavedState?.dialog
  ])

  return {
    dialog,
    onNavigate,
    unsavedValues,
    shouldInitForm,
    hasUnsavedChanges
  }
}

interface useSetUnsavedAdvisorChangesI {
  setUnsavedAdvisorState: (newState: UnsavedAdvisorChangesAtomData) => void
  setHasUnsaved: (bool: boolean) => void
  setDialogState: (bool: boolean) => void
  resetState: () => void
}

export const useSetUnsavedAdvisorChanges = (): useSetUnsavedAdvisorChangesI => {
  const setUnsavedValues = useSetRecoilState(unsavedAtom)
  const setHasUnsavedState = useSetRecoilState(hasUnsavedAtom)

  const setUnsavedAdvisorState = (newState: UnsavedAdvisorChangesAtomData) => {
    setUnsavedValues(newState)
  }
  const setHasUnsaved = (bool: boolean) => {
    setHasUnsavedState(bool)
  }

  const setDialogState = (dialog: boolean) => {
    setUnsavedValues({ dialog })
  }

  const resetState = () => {
    setUnsavedValues({
      dialog: false,
      onNavigate: null,
      unsavedValues: null
    })
    setHasUnsavedState(false)
  }

  return {
    setUnsavedAdvisorState,
    setHasUnsaved,
    setDialogState,
    resetState
  }
}
