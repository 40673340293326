import React from 'react'
import { path, split } from 'ramda'
import { format } from 'date-fns'
import { useTheme } from 'styled-components/native'
import { TableConfig, CellRendererProps } from '../../components/Table'
import useTranslation from '../../hooks/useTranslation'
import TitleCell, {
  SubtitleCell,
  LabelAndTitleCell
} from '../../components/DynamicTable/components/Table/Item/common/TitleCellContent'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import { SmallRow } from '../../components/Table/components/SharedStyledComponents'
import { AlternatingHoverableRow } from '../../components/Table/components/DynamicTable/SharedStyledComponents'
import { CallForSubmissionStepStatusEnum } from '../../types'
import Theme from '../../constants/Theme'

const headerStyle = {
  fontWeight: 'bold',
  fontSize: `${Theme.fontSizes[6] - Theme.fontSizes[0]}px`,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'inline-block'
}

const headerContainerStyle = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 10
}

const Content = ({ isSmallScreen, label, title, style = {} }) => {
  return isSmallScreen ? (
    <LabelAndTitleCell label={label} title={title} style={style} />
  ) : (
    <SubtitleCell text={title} style={style} />
  )
}

export const SUBMISSIONS_TABLE_CONFIG: TableConfig<any> = {
  name: 'SubmissionsTable',
  headerContainerStyle,
  LargeRow: AlternatingHoverableRow,
  SmallRow: SmallRow,
  columns: [
    {
      header: 'submissions:table:productName',
      headerStyle,
      width: 14,
      emptyMessage: 'submissions:table:emptyMessage',
      isEditable: false,
      fixed: false,
      Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
        const { t } = useTranslation()
        const labelTranslated = label ? t(label) : ''
        const linkToScreen = useLinkToScreen()
        const onPress = () => {
          linkToScreen('SavedSubmission', { id: item?.id })
        }
        const titleObj = {
          title:
            item?.submissionInfo?.name ||
            item?.innovationProduct?.name ||
            t('callsForSubmission:table:noName')
        }

        return isSmallScreen ? (
          <LabelAndTitleCell
            label={labelTranslated}
            title={titleObj.title}
            onPress={onPress}
            isValueHighlighted
            containerStyles={{
              borderTopWidth: 0
            }}
          />
        ) : (
          <TitleCell item={titleObj} onPress={onPress} />
        )
      }
    },
    {
      header: 'submissions:table:cfs',
      headerStyle,
      width: 14,
      isEditable: false,
      fixed: false,
      Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
        const { t } = useTranslation()
        const labelTranslated = label ? t(label) : ''

        return (
          <Content
            isSmallScreen={isSmallScreen}
            label={labelTranslated}
            title={item?.callForSubmission?.name}
          />
        )
      }
    },
    {
      header: 'submissions:table:marketSegments',
      headerStyle,
      width: 14,
      isEditable: false,
      Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
        const { t } = useTranslation()
        const labelTranslated = label ? t(label) : ''
        const marketSegmentNames =
          item?.callForSubmission?.marketSegments?.map(ms => ms.name) || []
        const text = marketSegmentNames.join(', ')
        return (
          <Content
            isSmallScreen={isSmallScreen}
            label={labelTranslated}
            title={text}
          />
        )
      }
    },
    {
      header: 'submissions:table:delegations',
      headerStyle,
      width: 14,
      isEditable: false,
      Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
        const { t } = useTranslation()
        const labelTranslated = label ? t(label) : ''
        const delegationNames =
          item?.callForSubmission?.delegations?.map(ms => ms.name) || []
        const text = delegationNames.join(', ')

        return (
          <Content
            isSmallScreen={isSmallScreen}
            label={labelTranslated}
            title={text}
          />
        )
      }
    },
    {
      header: 'callsForSubmission:status:cfsStatus',
      headerStyle,
      itemMap: 'updatedAt',
      width: 14,
      isEditable: false,
      Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
        const { t } = useTranslation()
        const labelTranslated = label ? t(label) : ''
        const { colors } = useTheme()
        let textColor = colors.text
        let labelContent = 'callsForSubmission:status:open'
        if (
          item?.callForSubmission?.currentStep?.status ===
          CallForSubmissionStepStatusEnum.closed
        ) {
          textColor = colors.tints.grays.g400
          labelContent = 'callsForSubmission:status:closed'
        }

        return (
          <Content
            isSmallScreen={isSmallScreen}
            label={labelTranslated}
            title={t(labelContent)}
            style={{
              color: textColor
            }}
          />
        )
      }
    },
    {
      header: 'submissions:table:submittedAt',
      headerStyle,
      itemMap: 'updatedAt',
      width: 14,
      isEditable: false,
      Cell: ({
        item,
        itemMap,
        label,
        isSmallScreen
      }: CellRendererProps<any>) => {
        const { t } = useTranslation()
        const labelTranslated = label ? t(label) : ''

        if (!item.hasBeenSubmitted) {
          return null
        }

        const value = format(
          new Date(path(split('.', itemMap as string), item) as any),
          'MM/dd/yyyy'
        )
        return (
          <Content
            isSmallScreen={isSmallScreen}
            label={labelTranslated}
            title={value}
          />
        )
      }
    },
    {
      header: 'submissions:table:status',
      headerStyle,
      width: 15,
      isEditable: false,
      Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
        const { t } = useTranslation()
        const { colors } = useTheme()
        const labelTranslated = label ? t(label) : ''

        let textColor = colors.tints.grays.g400
        if (
          item?.callForSubmission?.currentStep?.status !==
          CallForSubmissionStepStatusEnum.closed
        ) {
          textColor = item.hasBeenSubmitted ? colors.success : colors.attention
        }

        const value = t(
          item.hasBeenSubmitted
            ? 'submissions:status:submitted'
            : 'submissions:status:inProgress'
        )

        return (
          <Content
            isSmallScreen={isSmallScreen}
            label={labelTranslated}
            title={value}
            style={{
              color: textColor
            }}
          />
        )
      }
    }
  ]
}
