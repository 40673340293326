import React, { FC } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import useTranslation from '../../../hooks/useTranslation'
import useCurrentUser from '../../../hooks/useCurrentUser'

import { Company } from '../../../types'
import Link from '../../../components/Link'

import DateService from '../../../services/dateService'

export const Container = styled(View)`
  ${({ theme: { space, colors, radii } }) => `
    background: ${colors.containerBackground};
    border-radius: ${radii[2]}px;
    border-left-width: 5px;
    border-left-color: ${colors.highlight};
    max-width: 250px;
    min-width: 250px;
    margin-bottom: ${space[4]}px;
    box-shadow: #0000001a 0px 4px 5.65px;
  `}
`
const InnerContainer = styled(View)`
  ${({ theme: { space } }) => `
    padding: ${space[3]}px;
  `}
`

const Title = styled.Text`
  ${({ theme: { colors, fontWeights, fontSizes, space } }) => `
    color: ${colors.primaryPalette.black};
    fontWeight: ${fontWeights.bold};
    fontSize: ${fontSizes[5]}px;
    margin-bottom: ${space[2]}px;
  `}
`
const P = styled.Text`
  ${({ theme: { colors, fontWeights, fontSizes } }) => `
    color: ${colors.tints.grays.g400};
    fontWeight: ${fontWeights.regular};
    fontSize: ${fontSizes[3]}px;
    line-height: 20px;
  `}
`
const STRONG = styled(P)`
  ${({ theme: { fontWeights, colors } }) => `
    color: ${colors.primaryPalette.black}
    fontWeight: ${fontWeights.bold};
  `}
`

interface CompanySummaryProps {
  company?: Company
}

const getUrl = initialUrl =>
  initialUrl
    ? initialUrl.includes('http')
      ? initialUrl
      : `//${initialUrl}`
    : ''
const formatYearFounded = year => {
  if (!year) {
    return ''
  }

  return DateService.getFormat(year, 'YYYY')
}
const CompanySummary: FC<CompanySummaryProps> = ({ company }) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const website = company?.companyProfile?.url || ''

  if (!company || !currentUser) {
    return null
  }

  const { firstName, lastName, jobTitle } = currentUser.person

  return (
    <Container>
      <InnerContainer
        style={{
          borderBottomWidth: 1,
          borderColor: '#D9D9D9'
        }}
      >
        <Title>{company?.name || ''}</Title>
        <View>
          <P>{company.companyProfile?.country?.name}</P>
          <P>
            {t('submissions:companyInfo:est')}{' '}
            {formatYearFounded(company.companyProfile?.yearFounded)}
          </P>
          <P>
            {t(
              `auth:forms:companyEmployeeCountOptions:${company.companyProfile?.employeeCount}`
            )}{' '}
            {t('submissions:companyInfo:employees')}{' '}
          </P>
          <Link
            to={getUrl(website)}
            title={t('curation:submissionDetails:company:website')}
            target="_blank"
            style={{
              textDecoration: 'none',
              marginBottom: 0
            }}
          >
            <P>{website}</P>
          </Link>
        </View>
      </InnerContainer>
      <InnerContainer>
        <STRONG>{`${firstName} ${lastName}`}</STRONG>
        <P>{jobTitle}</P>
      </InnerContainer>
    </Container>
  )
}

export default CompanySummary
