import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { FormikProps } from 'formik'
import { useTheme } from 'styled-components/native'
import useTranslation from '../../../hooks/useTranslation'
import TextInput from '../../../ui-library/TextInput'
import { StyledLabel } from '../../../ui-library/TextInput/Label'
import { H4 } from '../../../components/common/Text'
import { Flex } from '../../../components/FlexBox'
import Dropdown from '../../../components/common/Dropdown'
import {
  AuthenticationErrorContainer,
  ErrorText
} from './SharedStyledComponents'
import useRegistrationDataQuery from '../queries/useRegistrationDataQuery'
import { EmployeeCountRangeEnum } from '../../../types/company'
import { INPUT_MAX_LENGTH_MD } from '../constants'
import changeText, { removeNonNumeric } from '../../../utils/changeFormikText'
import CurrencyInput from '../../../ui-library/CurrencyInput'
import {
  MultiSelectDropDown,
  MultiSelectDropDownProps
} from '../../../components/DynamicForm/MultiSelect'
import { Option } from '../../../components/DynamicForm/DropDown'
import useDelegationsQuery from '../../Product/hooks/useDelegationsQuery'
import { getCurrencyFormat } from '../../../services/currencyService'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import CustomTextInput from '../../../components/common/CustomTextInput'

interface CompanyInfoFormProps extends FormikProps<any> {}

const maskFundingValue = (value: string) => {
  const number = removeNonNumeric(value.toString())
  return getCurrencyFormat(Number(number))
}

const CompanyInfoForm = ({
  values,
  setFieldValue,
  errors,
  touched,
  handleBlur
}: CompanyInfoFormProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { data } = useRegistrationDataQuery()
  const { delegations, loading } = useDelegationsQuery()
  const [delegationValues, setDelegationValues] = useState<Option[]>([])
  const rebrand = useFeatureFlag('innovationRebrandPlatform')

  const countryOptions = useMemo(() => {
    return (data?.countries || []).map(country => ({
      label: country.name,
      value: country.id
    }))
  }, [data?.countries])

  const employeeCountOptions = useMemo(() => {
    return Object.keys(EmployeeCountRangeEnum).map(key => {
      return {
        label: t(
          `auth:forms:companyEmployeeCountOptions:${EmployeeCountRangeEnum[key]}`
        ),
        value: EmployeeCountRangeEnum[key]
      }
    })
  }, [])

  const multiSelectProps: MultiSelectDropDownProps = {
    label: t('directoryProfile:label:delegationInterest'),
    name: 'delegationInterestMultiSelect',
    multiSelectValues: delegationValues,
    multiSelectOptions:
      delegations &&
      delegations.map(del => ({
        label: `${del.description} (${del.name})`,
        value: del.id
      })),
    styles: { width: '100%' },
    helperText: t('directoryProfile:label:delegationInterestHelperText'),
    helperTextProps: {
      iconProps: {
        name: 'infoOutlined',
        width: theme.sizes[3] + 1,
        height: theme.sizes[3] + 1,
        color: theme.colors.primaryPalette.white
      }
    },
    isDisabled: loading,
    handleSelect: values => {
      setDelegationValues(
        values.map(delId => {
          const currentDelegataion = delegations.filter(
            del => del.id === delId
          )[0]
          return {
            label: `${currentDelegataion.description} (${currentDelegataion.name})`,
            value: currentDelegataion.id
          }
        })
      )
    },
    menuPortalTarget: document?.body
  }

  useEffect(() => {
    setFieldValue(
      'delegationInterest',
      delegationValues.map(del => del.value)
    )
  }, [delegationValues])

  const TextInputComponent = rebrand ? CustomTextInput : TextInput
  return (
    <Flex flexDirection="column" maxWidth="100%" width="100%">
      <H4 styles={{ marginBottom: 5 }}>{t('auth:register:companyInfo')}</H4>
      <TextInputComponent
        value={values.companyName}
        onChangeText={value => changeText('companyName', value, setFieldValue)}
        onBlur={handleBlur('companyName')}
        autoCapitalize="none"
        label={t('auth:forms:labels:companyName')}
        style={{ minWidth: '100%' }}
        maxLength={INPUT_MAX_LENGTH_MD}
        hasError={!!errors.companyName && !!touched.companyName}
      />
      <View>
        <StyledLabel>{t('auth:forms:labels:country')}</StyledLabel>
        <Dropdown
          name="country"
          options={countryOptions}
          placeholder={''}
          onSelect={value => changeText('country', value, setFieldValue)}
          onBlur={handleBlur('country')}
          value={values.country}
          hasError={!!errors.country && !!touched.country}
          style={{ minWidth: '100%', marginBottom: '7px' }}
          isClearable
          menuPortalTarget={document?.body}
        />
      </View>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
      >
        <View style={{ flexBasis: '29%' }}>
          <TextInputComponent
            value={values.yearFounded}
            onChangeText={value => {
              changeText('yearFounded', removeNonNumeric(value), setFieldValue)
            }}
            onBlur={handleBlur('yearFounded')}
            autoCapitalize="none"
            label={t('auth:forms:labels:yearFounded')}
            keyboardType="numeric"
            hasError={!!errors.yearFounded && !!touched.yearFounded}
            style={{ width: '100%' }}
          />
          {!!errors.yearFounded && !!touched.yearFounded && (
            <AuthenticationErrorContainer>
              <ErrorText>{errors.yearFounded}</ErrorText>
            </AuthenticationErrorContainer>
          )}
        </View>
        <View style={{ flexBasis: '38%' }}>
          {!rebrand && (
            <StyledLabel>{t('auth:forms:labels:employeeCount')}</StyledLabel>
          )}
          <Dropdown
            label={rebrand ? t('auth:forms:labels:employeeCount') : undefined}
            name="employeeCount"
            options={employeeCountOptions}
            placeholder={''}
            onSelect={value =>
              changeText('employeeCount', value, setFieldValue)
            }
            onBlur={handleBlur('employeeCount')}
            value={values.employeeCount}
            hasError={!!errors.employeeCount && !!touched.employeeCount}
            style={{ width: '100%' }}
            isClearable
            menuPortalTarget={document?.body}
          />
        </View>
        <View style={{ flexBasis: '30%' }}>
          <CurrencyInput
            symbol="$"
            value={values.funding}
            onChangeText={value => {
              changeText('funding', maskFundingValue(value), setFieldValue)
            }}
            onBlur={handleBlur('funding')}
            autoCapitalize="none"
            label={t('auth:forms:labels:funding')}
            keyboardType="numeric"
            hasError={!!errors.funding && !!touched.funding}
            style={{ width: '100%' }}
            CustomInputComponent={TextInputComponent}
          />
          {!!errors.funding && !!touched.funding && (
            <AuthenticationErrorContainer>
              <ErrorText>{errors.funding}</ErrorText>
            </AuthenticationErrorContainer>
          )}
        </View>
        <MultiSelectDropDown {...multiSelectProps} />
      </Flex>
    </Flex>
  )
}

export default CompanyInfoForm
