import React from 'react'
import styled, { useTheme } from 'styled-components/native'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import { Flex } from '../../../components/FlexBox'
import { ScrollView } from 'react-native'

import { H1, Text } from '../../../components/common/Text'
import useTranslation from '../../../hooks/useTranslation'
import Button from '../../../ui-library/Button'

const StyledScrollView = styled(ScrollView)`
  width: 100%;
`

const Container = styled(Flex)`
  ${({ theme: { space, radii, colors } }) => `
  max-width: 700px;
  border-radius: ${radii[4]}px;
  border: 1px solid ${colors.tints.grays.g100};
  padding: ${space[4]}px;
  background: ${colors.containerBackground};
  margin-top: ${space[3]}px;
  `}
`

const StyledFlex = styled(Flex).attrs({
  w: '100%',
  flex: 1,
  flexDirection: 'column'
})``

const P = styled(Text)`
  color: ${props => props.theme.colors.text};
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 15px;
  text-align: justify;
`

const Title = styled(H1).attrs(
  ({ theme: { space, fontSizes, colors }, isSmallScreen }) => ({
    styles: {
      marginBottom: space[3],
      marginTop: isSmallScreen ? space[2] : 0,
      textAlign: isSmallScreen ? 'center' : 'left',
      color: colors.highlight
    },
    h1Style: {
      fontSize: fontSizes[7],
      fontWeight: 'bold'
    }
  })
)``
const InnovatorWelcome = ({ openProductDialog, canAddInnovatorProducts }) => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const { space, colors } = useTheme()

  return (
    <StyledFlex>
      <Flex
        flexDirection="column"
        width="100%"
        justifyContent="center"
        flexBasis="100%"
        margin="0 auto"
      >
        <StyledScrollView
          style={{
            width: '100%',
            maxWidth: 1000,
            ...(isSmallScreen ? { borderRadius: 0 } : {})
          }}
        >
          <Container testID={'profileIntro'}>
            <Title isSmallScreen={isSmallScreen}>
              {t('innovatorDirectory:welcome:title')}
            </Title>
            <P>{t('innovatorDirectory:welcome:description:paragraph1')}</P>

            <P>{t('innovatorDirectory:welcome:description:paragraph2')}</P>

            {canAddInnovatorProducts ? (
              <Flex alignItems="center" marginTop={space[4]}>
                <Button
                  title={t('directoryProfile:products:addProduct')}
                  onPress={() => {
                    openProductDialog(true)
                  }}
                  style={{
                    marginTop: space[2]
                  }}
                  hoverStyles={{
                    buttonStyle: {
                      backgroundColor: colors.tints.grays.g200,
                      borderColor: colors.tints.grays.g200
                    },
                    titleStyle: {
                      color: colors.primaryPalette.white
                    }
                  }}
                />
              </Flex>
            ) : null}
          </Container>
        </StyledScrollView>
      </Flex>
    </StyledFlex>
  )
}

export default InnovatorWelcome
