import { useRecoilState } from 'recoil'
import { simulationAtom } from '../../recoil/simulationAtom'
import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Text } from '../../components/common/Text'
import styled from 'styled-components/native'

import useTranslation from '../../hooks/useTranslation'
import Dialog from '../../components/common/Dialog'

export const SIMULATOR_HEADER_HEIGHT = 36

const StyledView = styled(View)`
  width: 100%;
  background-color: #ff8080;
  height: ${SIMULATOR_HEADER_HEIGHT};
`

const Container = styled(TouchableOpacity)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 100%;
`

const SimulatorHeader: React.FC = () => {
  const [simulatorState, setSimulatorState] = useRecoilState(simulationAtom)

  const { t } = useTranslation()

  if (!simulatorState.simulatedId) {
    return null
  }

  const turnOnSimDialog = () => {
    console.log('turnOnSimDialog')
    setSimulatorState({ ...simulatorState, dialog: true, isLogOut: false })
  }
  const turnOffSimDialog = () => {
    setSimulatorState({ ...simulatorState, dialog: false, isLogOut: false })
  }

  let actionButtons

  const isLogOut = simulatorState?.isLogOut
  if (!isLogOut) {
    actionButtons = [
      {
        title: t('common:buttons:cancel'),
        type: 'outline',
        onPress: turnOffSimDialog
      },
      {
        title: t('simulation:exitDialog:exitSimulationButton'),
        onPress: () => {
          setSimulatorState({
            simulatedId: undefined,
            name: undefined,
            dialog: false
          })
        }
      }
    ]
  }

  return (
    <StyledView>
      <Container onPress={() => turnOnSimDialog()}>
        <Text>{t('simulation:exitView')}</Text>
      </Container>
      <Dialog
        isOpen={!!simulatorState?.dialog}
        title={t(`simulation:exitDialog:${isLogOut ? 'mode' : 'title'}`)}
        description={t(
          `simulation:exitDialog:${
            isLogOut ? 'cannotExit' : simulatorState.name ? 'bodyName' : 'body'
          }`,
          {
            name: simulatorState.name
          }
        )}
        onClose={turnOffSimDialog}
        actions={actionButtons as any[]}
      />
    </StyledView>
  )
}

export default SimulatorHeader
