import React, { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/react-native'
import { View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { LoadingIndicator } from '../../components/common/LoadingIndicator'

const RESET_HOME = {
  index: 0,
  routes: [
    {
      name: 'App',
      state: {
        routes: [
          {
            name: 'Home',
            state: {
              routes: [{ name: 'Home' }]
            }
          }
        ]
      }
    }
  ]
}

const SimulationLoadingScreen = ({ navigation }: StackScreenProps<any>) => {
  const client = useApolloClient()
  const reset = async () => {
    setTimeout(() => {
      navigation.reset(RESET_HOME)
    }, 2000)
    try {
      await client.resetStore()
    } catch (err) {
      console.log('error resetting store')
      Sentry.captureException(err)
    }
    try {
      await client.reFetchObservableQueries()
    } catch (err) {
      console.log('error refetching queries')
      Sentry.captureException(err)
    }
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      reset()
    })

    return unsubscribe
  }, [navigation])

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <LoadingIndicator size="large" />
    </View>
  )
}

export default SimulationLoadingScreen
