import { atom } from 'recoil'

export interface SimulationState {
  simulatedId?: string
  name?: string
  illegalOperation?: boolean
  dialog?: boolean
  isLogOut?: boolean
}

export const simulationAtom = atom<SimulationState>({
  default: {
    simulatedId: undefined,
    name: undefined,
    illegalOperation: false,
    dialog: false,
    isLogOut: false
  },
  key: 'simulationState'
})
