import React, { useEffect, useMemo, FC, useState } from 'react'
import { Text, View } from 'react-native'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import styled, { useTheme } from 'styled-components/native'

import AdornedTextInput from '../../../ui-library/AdornedTextInput'
import TextInput from '../../../ui-library/TextInput'
import Dropdown from '../../../components/common/Dropdown'
import Button from '../../../ui-library/Button'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import useTranslation from '../../../hooks/useTranslation'
import useRegistrationDataQuery from '../../authentication/queries/useRegistrationDataQuery'
import useToast from '../../../hooks/useToast'
import useUpdateCompanyMutation from '../hooks/useUpdateCompanyMutation'
import { EmployeeCountRangeEnum } from '../../../types/company'
import DateService from '../../../services/dateService'
import { getCurrencyFormat } from '../../../services/currencyService'
import { Company, CompanyProfileValues } from '../../../types'
import useLinkToScreen from '../../../hooks/useLinkToScreen'
import {
  MultiSelectDropDown,
  MultiSelectDropDownProps
} from '../../../components/DynamicForm/MultiSelect'
import useDelegationsQuery from '../../Product/hooks/useDelegationsQuery'
import { Option } from '../../../components/DynamicForm/DropDown'
import { LoadingIndicator } from '../../../components/common/LoadingIndicator'
import { removeNonNumeric } from '../../../utils/changeFormikText'
import { Title } from './common/SharedStyledComponents'
import sentry from '../../../utils/sentry'

const StyledLabel = styled(Text)`
  ${({ theme, styles }) => `
    color: ${theme.colors.text2};
    margin-left: ${styles?.marginLeft ? styles.marginLeft : 7};
    margin-bottom: 3px;
    position: relative;
  `}
`

const btnContainerStyles = { height: 32, marginTop: 20 }
const btnStyles = {
  width: 'fit-content',
  paddingTop: 12,
  paddingBottom: 12,
  paddingLeft: 24,
  paddingRight: 24,
  height: 32
}

const formFieldStyles = { maxWidth: 640, width: '100%' }
const formFieldContainerStyles = { marginBottom: '2%' }

const MAX_FUNDING = 2147483647

const maskFundingValue = (value: number) => getCurrencyFormat(value)

const companyProfileValidationSchema = t =>
  Yup.object({
    name: Yup.string().required(t('validation:error:required')).nullable(),
    countryId: Yup.string().required(t('validation:error:required')).nullable(),
    url: Yup.string().required(t('validation:error:required')).nullable(),
    yearFounded: Yup.number()
      .test(
        'len',
        t('error:auth:invalidYear'),
        val => val?.toString()?.length === 4
      )
      .max(new Date().getFullYear(), t('error:auth:invalidYear'))
      .required('Required'),
    funding: Yup.string()
      .nullable()
      .typeError(t('directoryProfile:error:fundingMustBeNumber'))
      .max(MAX_FUNDING, t('directoryProfile:error:exceededMaxFunding'))
      .required(t('validation:error:required')),
    employeeCount: Yup.mixed()
      .oneOf(Object.values(EmployeeCountRangeEnum))
      .required(t('validation:error:required')),
    domains: Yup.array()
      .required(t('validation:error:required'))
      .of(Yup.string().required(t('validation:error:required'))),
    delegationInterest: Yup.array().notRequired().of(Yup.string()).nullable()
  })

interface CompanyProfileProps {
  company: Company
  containerStyles?: any
  refetchQueries?: string[]
}
const CompanyProfile: FC<CompanyProfileProps> = ({
  company,
  containerStyles = {},
  refetchQueries = []
}) => {
  const isSmallScreen = useIsSmallScreen()
  const { space } = useTheme()
  const { t } = useTranslation()
  const { data, loading: loadingDirectory } = useRegistrationDataQuery()
  const { delegations, loading: loadingDelegations } = useDelegationsQuery()
  const [delegationValues, setDelegationValues] = useState<Option[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const { setToastErrorMessage } = useToast()
  const { setToastMessage } = useToast()
  const linkToScreen = useLinkToScreen()

  const handleSubmitError = e => {
    if (e?.message?.length) {
      if (e.message?.includes('is too big to be stored as a company funding')) {
        setToastErrorMessage(t('error:auth:fundingTooBig'))
        setFieldError('funding', t('error:auth:fundingTooBig'))
      } else {
        setToastErrorMessage(e.message)
      }
      sentry.captureException(
        new Error(`Registration error: ${JSON.stringify(e)}`)
      )
    } else {
      setToastErrorMessage(t('error:auth:failedUpdatingCompany'))
    }
  }

  const handleSuccess = () => {
    setToastMessage(t(`directoryProfile:button:changesSaved`))
  }

  const [onSubmit, loading] = useUpdateCompanyMutation({
    id: company?.id!,
    refetchQueries,
    options: { onError: handleSubmitError, onCompleted: handleSuccess }
  })
  const isReadOnly = !company?.canEdit

  const countryOptions = useMemo(
    () =>
      (data?.countries || []).map(country => ({
        label: country.name,
        value: country.id
      })),
    [data?.countries]
  )

  const employeeCountOptions = useMemo(
    () =>
      Object.keys(EmployeeCountRangeEnum).map(key => ({
        label: t(
          `auth:forms:companyEmployeeCountOptions:${EmployeeCountRangeEnum[key]}`
        ),
        value: EmployeeCountRangeEnum[key]
      })),
    []
  )

  const {
    name = '',
    companyProfile: {
      id = '',
      url = '',
      yearFounded = '',
      funding = '',
      employeeCount = '',
      country = { id: '' },
      domains = [],
      delegationInterest = []
    } = {}
  } = company || {}
  const validationSchema = companyProfileValidationSchema(t)

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldError
  } = useFormik({
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      id,
      url,
      name,
      employeeCount,
      countryId: country?.id,
      funding: maskFundingValue(Number(funding)),
      domains: domains.map(({ name }) => name),
      yearFounded: DateService.getYear(yearFounded).toString(),
      delegationInterest: delegationInterest.map(({ id }) => id)
    } as CompanyProfileValues
  })

  const multiSelectProps: MultiSelectDropDownProps = {
    label: t('directoryProfile:label:delegationInterest'),
    name: 'delegationInterestMultiSelect',
    multiSelectValues: delegationValues,
    multiSelectOptions:
      delegations &&
      delegations.map(del => ({
        label: `${del.description} (${del.name})`,
        value: del.id
      })),
    styles: {},
    helperText: t('directoryProfile:label:delegationInterestHelperText'),
    isDisabled: isLoading || isReadOnly,
    handleSelect: values => {
      setDelegationValues(
        values.map(delId => {
          const currentDelegataion = delegations.filter(
            del => del.id === delId
          )[0]
          return {
            label: `${currentDelegataion.description} (${currentDelegataion.name})`,
            value: currentDelegataion.id
          }
        })
      )
    }
  }

  useEffect(() => {
    setFieldValue(
      'delegationInterest',
      delegationValues.map(del => del.value)
    )
  }, [delegationValues])

  useEffect(() => {
    if (delegationInterest) {
      setDelegationValues(
        delegationInterest.map(delI => {
          const currentDelegataion = delegations.filter(
            del => del.id === delI.id
          )[0]
          return {
            label: `${currentDelegataion.description} (${currentDelegataion.name})`,
            value: currentDelegataion.id
          }
        })
      )
    }
  }, [delegationInterest])

  useEffect(() => {
    if (loading || loadingDelegations || loadingDirectory) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [loading, loadingDirectory, loadingDelegations])

  if (isLoading) {
    return (
      <View>
        <LoadingIndicator />
      </View>
    )
  }

  return (
    <View
      style={{
        flex: 1,
        padding: isSmallScreen ? space[3] : 44,
        ...containerStyles
      }}
    >
      <Title>{t('directoryProfile:label:myCompany')}</Title>

      <form style={{ marginTop: 24, maxWidth: 800 }}>
        <TextInput
          containerStyles={formFieldContainerStyles}
          errorMessage={errors.name}
          label={t('directoryProfile:label:name')}
          style={formFieldStyles}
          name="name"
          value={values.name}
          onChange={handleChange}
          isReadOnly={isReadOnly}
        />

        <Dropdown
          label={t('directoryProfile:label:countryId')}
          style={formFieldStyles}
          name="countryId"
          placeholder="Select..."
          value={values.countryId}
          options={countryOptions}
          onChange={setFieldValue}
          isReadOnly={isReadOnly}
          menuPortalTarget={document?.body}
        />

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            maxWidth: 640
          }}
        >
          <TextInput
            errorMessage={errors.yearFounded}
            label={t('directoryProfile:label:yearFounded')}
            name="yearFounded"
            value={values.yearFounded}
            onChange={handleChange}
            autoCapitalize="none"
            keyboardType="numeric"
            containerStyles={{ width: '49%' }}
            style={{ width: '100% ' }}
            isReadOnly={isReadOnly}
          />
          <TextInput
            label={t('directoryProfile:label:totalFunding')}
            name="funding"
            value={values.funding}
            errorMessage={errors.funding}
            onChange={(event: any) => {
              const numbers = removeNonNumeric(event?.nativeEvent?.text)
              setFieldValue('funding', maskFundingValue(Number(numbers)))
            }}
            containerStyles={{ width: '49%' }}
            style={{ width: '100% ' }}
            isReadOnly={isReadOnly}
          />
        </View>

        <Dropdown
          label={t('directoryProfile:label:employeeCount')}
          name="employeeCount"
          placeholder="Select..."
          style={formFieldStyles}
          value={values.employeeCount}
          options={employeeCountOptions}
          onChange={setFieldValue}
          isReadOnly={isReadOnly}
        />

        <MultiSelectDropDown {...multiSelectProps} />

        <TextInput
          containerStyles={formFieldContainerStyles}
          isReadOnly={isReadOnly}
          errorMessage={errors.url}
          label={t('directoryProfile:label:url')}
          name="url"
          onChange={handleChange}
          style={formFieldStyles}
          value={values.url}
        />

        <View>
          <StyledLabel>{t('directoryProfile:label:domains')}</StyledLabel>
          {values.domains.map((value, i) => {
            return (
              <AdornedTextInput
                key={`domain-${i}`}
                name={`domains[${i}]`}
                value={value}
                errorMessage={
                  touched?.domains?.[i] ? errors?.domains?.[i] : undefined
                }
                containerStyles={formFieldStyles}
                onChange={handleChange}
                isReadOnly
              />
            )
          })}
        </View>

        <div style={{ display: 'flex' }}>
          <Button
            title={t('directoryProfile:button:cancelButton')}
            type="outline"
            buttonStyle={{
              ...btnStyles,
              borderColor: '#8C8C8C'
            }}
            titleStyle={{
              color: '#8C8C8C'
            }}
            containerStyle={{
              ...btnContainerStyles,
              marginRight: 16
            }}
            onPress={() => {
              linkToScreen('InnovatorDirectoryProfile', {
                companyId: company?.id
              })
            }}
          />
          <Button
            title={t('directoryProfile:button:saveButton')}
            buttonStyle={btnStyles}
            containerStyle={btnContainerStyles}
            onPress={() => {
              handleSubmit()
            }}
            disabled={isReadOnly}
          />
        </div>
      </form>
    </View>
  )
}

export default CompanyProfile
