import React, { FC } from 'react'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { Title as FormTitle } from '../../../components/DynamicForm/layout'
import { Flex } from '../../../components/FlexBox'
import ArrowLeftIcon from '../../../components/icon/ArrowLeftIcon'
import useFeatureFlag from '../../../hooks/useFeatureFlag'

export const ViewComponent = styled(View)``

export const ActivityIndicatorComponent = styled(ActivityIndicator)``

export const UsersSelectorView = styled(View)``

const TitleContainer = styled(Flex)`
  ${({ theme, rebrand }) => `
    width: 100%;
    background-color: ${theme.colors.containerBackground};
    padding-left: ${theme.space[3]}px;
    padding-right: ${theme.space[3]}px;
    padding-top: ${theme.space[3]}px;
    border-style: solid;
    flex-wrap: wrap;
    border-bottom-width: ${rebrand ? 0 : 1}px;
    border-color: ${theme.colors.lightGrey}
`}
`

interface TitleProps {
  text: string
  centered?: boolean
  withBackArrow?: boolean
  onBackArrow?: () => void
  rightButton?: any
}

export const Title: FC<TitleProps> = ({
  text,
  centered = false,
  withBackArrow = false,
  onBackArrow = () => {},
  children,
  ...props
}) => {
  const { colors, space } = useTheme()
  const RightButtonComponent = props?.rightButton ?? null
  const rebrand = useFeatureFlag('innovationRebrandPlatform')

  return withBackArrow ? (
    <TitleContainer
      flexDirection="row"
      alignItems="center"
      w="100%"
      {...props}
      paddingBottom={space[3]}
      rebrand={rebrand}
    >
      <TouchableOpacity
        onPress={onBackArrow}
        style={{
          marginRight: space[3] + space[1] // 20
        }}
      >
        <ArrowLeftIcon color={colors.lightGrey} />
      </TouchableOpacity>
      <FormTitle
        style={{ textAlign: centered ? 'center' : undefined, marginBottom: 0 }}
        rebrand={rebrand}
      >
        {text}
      </FormTitle>
      {children}
    </TitleContainer>
  ) : (
    <TitleContainer {...props} rebrand={rebrand}>
      <FormTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '95%',
          textAlign: centered ? 'center' : undefined
        }}
        rebrand={rebrand}
      >
        {text}
        {RightButtonComponent ? <RightButtonComponent /> : null}
      </FormTitle>
      {children}
    </TitleContainer>
  )
}

export const Container = styled(Flex)`
  height: 95%;
  background-color: white;
  flex-shrinnk: 1;
  padding: ${({ padding, theme: { isSmallScreen } }) =>
    padding ?? isSmallScreen ? 15 : 30}px;
`
export const SettingsContainer = styled.View`
  height: 100%;
  ${({ theme: { colors, space } }) => `
    padding: ${space[4]}px;
    background-color: ${colors.appBackground};
  `}
`
