import React, { FC, useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components/native'
import useToast from '../../../hooks/useToast'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import useSubmissionQuery from '../../Submission/hooks/useSubmissionQuery'
import useFormAnswerQuery from '../../Submission/hooks/useFormAnswerQuery'
import useDynamicForm from '../../../hooks/useDynamicForm'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import useTranslation from '../../../hooks/useTranslation'
import { MarketSegment, OriginSourceEnum } from '../../../types'
import { ScrollView, View } from 'react-native'
import { Flex } from '../../../components/FlexBox'
import SkeletonContainer from '../../../components/skeletonLoadings/SkeletonContainer'
import { CompanySummarySkeleton } from '../../Submission/components/skeletons'
import { FormikProvider } from 'formik'
import { Container } from '../../../components/DynamicForm/layout'
import FormButtons from '../../../components/DynamicForm/FormButtons'
import SubmissionStatus from './SubmissionStatus'
import ActionButtons from './ActionButtons'
import { AnswerEntityTypeEnum } from '../../../types/form'
import { InnovatorDirectorySkeleton } from '../../Submission/components/skeletons/FormSkeleton'
import { LoadingIndicator } from '../../../components/common/LoadingIndicator'

const StyledScrollView = styled(ScrollView)`
  width: 100%;
`

const ActionButtonsContainer = styled(View)`
  margin-bottom: ${props => props.theme.space[1]}px;
  flex-direction: row;
  justify-content: flex-end;
`

const SubmissionStatusContainer = styled(View)`
  margin-bottom: ${props => props.theme.space[4]}px;
`

const StyledFlex = styled(Flex).attrs({
  w: '100%',
  flex: 1,
  flexDirection: 'column'
})``

const ButtonsContainer = styled(Flex)``

interface ProductOverviewProps {
  submissionId?: string | null
  formId?: string | null
  isPublished?: boolean
  isInnovatorProduct: boolean
  productId?: string | null
  marketSegment?: MarketSegment | null
  onProductSelected?: (v) => void
  canDelete?: boolean
  canEdit?: boolean
  canPublish?: boolean
}

const ProductOverview: FC<ProductOverviewProps> = ({
  submissionId,
  formId,
  isPublished,
  onProductSelected,
  isInnovatorProduct,
  productId,
  marketSegment,
  canEdit,
  canDelete,
  canPublish
}) => {
  const isSmallScreen = useIsSmallScreen()
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { setToastErrorMessage } = useToast()
  const innovationInnovatorDirectoryEnabled = useFeatureFlag(
    `innovationInnovatorDirectory`
  )
  const { submission, loading: loadingSubmission } = useSubmissionQuery(
    submissionId,
    true
  )
  const { formAnswer, loading: loadingFormAnswer } = useFormAnswerQuery(
    submissionId,
    AnswerEntityTypeEnum.InnovationSubmission
  )
  const [isProductPublished, setIsProductPublished] = useState<boolean>(
    isPublished ?? false
  )

  // Reset the isPublished flag everytime a new product is selected
  useEffect(() => {
    if (submissionId) setIsProductPublished(isPublished ?? false)
  }, [submissionId, isPublished])

  const submissionFormId = useMemo(() => {
    if (formId) {
      return formId
    }
    if (submission) {
      return submission?.callForSubmission?.formId
    }
  }, [formId, submission])

  const onError = (e: any) => {
    setToastErrorMessage(
      e?.message?.length ? e.message : t('error:form:answerQuestions')
    )
  }

  const {
    form,
    component: dynamicForm,
    helpers,
    loadingFormData
  } = useDynamicForm({
    isInnovatorProduct: isInnovatorProduct,
    submissionId: submissionId,
    formId: submissionFormId,
    formSubmissionId: formAnswer?.id,
    originSource: OriginSourceEnum.Innovation,
    isReadOnly: innovationInnovatorDirectoryEnabled
      ? isProductPublished || !canEdit
      : !canEdit,
    withFormButtonsConfig: {
      // in the old form since the Product tab was shared we needed to differentiate the form we were using
      isSubmission: true
    },
    callForSubmission: submission?.callForSubmission,
    marketSegment: marketSegment || null,
    onError
  })

  if (loadingSubmission || loadingFormAnswer || !submissionId) {
    return (
      <Flex minWidth="300px" width="100%" marginTop="auto">
        <LoadingIndicator style={{ alignItems: 'center' }} size="large" />
      </Flex>
    )
  }

  return (
    <StyledFlex>
      <Flex
        flexDirection="column" // {isSmallScreen ? 'column' : 'row'}
        width="100%"
        justifyContent="center"
        flexBasis="100%"
        margin="0 auto"
      >
        <StyledScrollView
          style={{
            width: '100%',
            maxWidth: 1000,
            ...(isSmallScreen ? { borderRadius: 0 } : {})
          }}
        >
          <SkeletonContainer
            isLoading={loadingFormData}
            Skeleton={InnovatorDirectorySkeleton}
          >
            <ActionButtonsContainer testID={'productOptions'}>
              <ActionButtons
                form={form}
                helpers={helpers}
                submission={submission}
                productId={productId}
                canDelete={canDelete}
                canPublish={canPublish}
                isPublished={isPublished}
                isInnovatorProduct={isInnovatorProduct}
                onProductSelected={onProductSelected}
                setIsProductPublished={setIsProductPublished}
              />
            </ActionButtonsContainer>
            {submission?.callForSubmission ? (
              <SubmissionStatusContainer>
                <SubmissionStatus submission={submission} />
              </SubmissionStatusContainer>
            ) : null}
            <FormikProvider value={form}>
              <Container testID={'productDetails'}>{dynamicForm}</Container>
            </FormikProvider>
          </SkeletonContainer>
        </StyledScrollView>

        {canEdit ? (
          <ButtonsContainer
            paddingTop={10}
            style={{
              ...(isSmallScreen
                ? {
                    backgroundColor: colors.tableRowHeader
                  }
                : {})
            }}
          >
            <SkeletonContainer
              isLoading={loadingFormData}
              Skeleton={CompanySummarySkeleton}
            >
              <FormButtons
                form={form}
                formHelpers={helpers}
                innovationSubmissionId={submissionId}
                isSubmission={false}
                isSmallScreen={isSmallScreen}
                showCloseBtn={false}
                canEdit={canEdit}
                onClose={() => {
                  onProductSelected && onProductSelected(null)
                }}
                hasBeenSubmitted={submission?.hasBeenSubmitted}
                keepCountdown={false}
              />
            </SkeletonContainer>
          </ButtonsContainer>
        ) : null}
      </Flex>
    </StyledFlex>
  )
}

export default ProductOverview
