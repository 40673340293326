import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { TableProps } from '../..'
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'
import useTranslation from '../../../../hooks/useTranslation'
import { H4 } from '../../../common/Text'
import { Flex } from '../../../FlexBox'
import useSearchQuery from '../../hooks/useSearchQuery'
import { getInitialFilters, getInitialSortState } from '../constants'
import DynamicTableComponent from './DynamicTableComponent'
import { useIsFocused } from '@react-navigation/native'
import {
  DynamicTableContainer,
  DynamicTableHeaderComponents,
  DynamicTableText,
  MainContainer,
  TableScrollViewContainer,
  InnerTableContainer
} from './SharedStyledComponents'
import DynamicTableFilters from './DynamicTableFilters'
import DynamicTableSearchBar from './DynamicTableSearchBar'
import { TablesEnum } from '../../../../screens/CallForSubmission/constants'
import { CallForSubmissionStepEnum } from '../../../../types'
import { DEFAULT_PAGE_SIZE } from '../../../DynamicTable/hooks/TableProvider'
import { TableMessageText } from '../../../DynamicTable/components/Table/SharedStyledComponents'
import { LoadingIndicator } from '../../../common/LoadingIndicator'

function sortData(data, key, dir = 'asc') {
  return data.sort((a, b) => {
    // if key value is string, sort lowercase
    const firstVal = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key]
    const secondVal = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key]

    if (firstVal < secondVal) {
      return dir === 'asc' ? -1 : 1
    }
    if (firstVal > secondVal) {
      return dir === 'asc' ? 1 : -1
    }
    return 0
  })
}

function DynamicTable<T>({
  config,
  keyExtractor = defaultKeyExtractor,
  data,
  loading,
  error,
  isReadOnly,
  EmptyComponent,
  emptyMessage,
  initialSelectedItems = {}
}: TableProps<T>) {
  const isFocused = useIsFocused()
  const isSmallScreen = useIsSmallScreen()
  const { t } = useTranslation()
  let {
    columns: initialColumns,
    enableFilters = false,
    showSearchField = enableFilters,
    engine,
    hitKey,
    pageSize = DEFAULT_PAGE_SIZE,
    filters,
    queryConditions,
    showStatusFilter,
    rowHeight,
    tableId,
    isSticky,
    isAlternativeView = false,
    showTotalElements = false,
    getQuestionAndAnswers = false,
    showSelectAllElementsText = false,
    enableSelectAllElements = false
  } = config
  const columns = initialColumns.filter(ic => !ic?.isHidden)
  const initialSortState = getInitialSortState(initialColumns) || ['', 'asc']
  const [[sortBy, sortDir], updateSort] = useState(initialSortState)
  const [queryFilters, setQueryFilters] = useState({})
  const [searchFilters, setSearchFilters] = useState({
    ...getInitialFilters(columns)
  })
  const [loadingFetchMore, setLoadingFetchMore] = useState(false)
  const [selectedItems, setSelectedItems] = useState({})
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [selectCurrent, setSelectCurrent] = useState<boolean>(false)

  const currentCFSStep =
    tableId === TablesEnum.Advise
      ? CallForSubmissionStepEnum.advising
      : undefined

  useEffect(() => {
    const handleFilters = () => {
      if (filters) {
        setQueryFilters({
          ...queryFilters,
          ...filters
        })
      }
    }
    handleFilters()
  }, [filters])

  const handleOnSort = newSortBy => {
    let newSortDir
    if (newSortBy === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc'
    } else {
      newSortDir = 'asc'
    }
    updateSort([newSortBy, newSortDir])
  }

  const fixedColumns = isSticky
    ? columns
    : columns.filter(column => column.fixed)
  const dynamicColumns = isSticky ? [] : columns.filter(column => !column.fixed)

  const widthFixedTable = useMemo(
    () =>
      fixedColumns.reduce(
        (totalWidth, column) =>
          column.width ? totalWidth + column.width.valueOf() : totalWidth,
        0
      ),
    [fixedColumns]
  )
  const widthDynamicTable = useMemo(
    () =>
      dynamicColumns.reduce(
        (totalWidth, column) =>
          column.width ? totalWidth + column.width.valueOf() : totalWidth,
        0
      ),
    [dynamicColumns]
  )
  const widthScrollDynamicTable = 100 - widthFixedTable

  const {
    data: searchData,
    loading: searchLoading,
    fetchData: searchFetchData,
    fetchMore
  } = useSearchQuery({
    enableFilters,
    pageSize,
    sortBy,
    sortDir,
    queryFilters,
    searchFilters,
    engine,
    queryConditions,
    queryDynamicName: config?.queryDynamicName,
    isFocused,
    currentCFSStep,
    getQuestionAndAnswers
  })

  let items = searchData
    ? hitKey
      ? searchData?.searchInnovationEngine?.hits?.map(hit => hit[hitKey])
      : searchData?.searchInnovationEngine?.hits
    : []

  const currentElements = useMemo(
    () => (searchData ? searchData?.searchInnovationEngine?.hits?.length : 0),
    [searchData]
  )
  const totalElements = useMemo(
    () => (searchData ? searchData?.searchInnovationEngine?.total : 0),
    [searchData]
  )
  const currentSelection = useMemo(
    () => Object.keys(selectedItems)?.length ?? 0,
    [selectedItems]
  )

  const fetchData = useMemo(() => ({ ...searchFetchData, totalElements }), [
    searchFetchData,
    totalElements
  ])

  useEffect(() => {
    setSelectedItems(initialSelectedItems)
  }, [])

  const handleSelectAll = useCallback(
    (value: boolean) => {
      setSelectCurrent(false)
      setSelectAll(value)
      let newSelection = { ...selectedItems }
      for (const item of searchData?.searchInnovationEngine?.hits) {
        newSelection[item.id] = value
      }
      setSelectedItems(newSelection)
    },
    [searchData]
  )
  const handleSelectCurrent = useCallback(
    (value: boolean) => {
      setSelectCurrent(value)
      if (searchData?.searchInnovationEngine?.hits) {
        let newSelection = { ...selectedItems }
        for (const item of searchData?.searchInnovationEngine?.hits) {
          newSelection[item.id] = value
        }
        setSelectedItems(newSelection)
      }
    },
    [searchData]
  )
  const handleSelect = (value: boolean) => {
    let newSelectedItems = {}
    Object.keys(selectedItems).map(item => {
      newSelectedItems = { ...newSelectedItems, [item]: value }
    })
    setSelectedItems(newSelectedItems)
  }

  const handleChangeFilters = useCallback((newQueryFilters: any) => {
    resetSelector()
    setQueryFilters(newQueryFilters)
  }, [])

  const handleChangeSearch = useCallback((newSearchFilters: any) => {
    resetSelector()
    setSearchFilters(newSearchFilters)
  }, [])

  const resetSelector = () => {
    setSelectAll(false)
    setSelectCurrent(false)
    setSelectedItems({})
  }

  const fetchMoreResults = () => {
    if (
      (showSearchField && (!items?.length || searchLoading)) ||
      !showSearchField ||
      !isFocused ||
      loading ||
      loadingFetchMore
    )
      return

    setLoadingFetchMore(true)

    const offset = Math.ceil(items?.length / pageSize) + 1

    fetchMore &&
      fetchMore({
        variables: {
          offset,
          size: pageSize,
          sort: [{ sortBy, sortDir }],
          engine
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newHits = fetchMoreResult?.searchInnovationEngine?.hits || []
          setLoadingFetchMore(false)
          if (newHits.length === 0) return prev
          if (selectAll) {
            let newSelection = { ...selectedItems }
            for (const newItem of newHits) {
              newSelection[newItem.id] = selectAll
            }
            setSelectedItems(newSelection)
          }
          return {
            ...prev,
            searchInnovationEngine: {
              ...(prev?.searchInnovationEngine || {}),
              hits: [...(prev?.searchInnovationEngine?.hits || []), ...newHits]
            }
          }
        }
      })
  }

  const showEmptyMessage =
    ((showSearchField && !items?.length && !searchLoading) ||
      (!showSearchField && !data?.length && !loading)) &&
    emptyMessage &&
    !EmptyComponent

  const showEmptyComponent =
    showSearchField && EmptyComponent && !searchLoading && !items?.length

  const sortedData = sortBy && data ? sortData(data, sortBy, sortDir) : data
  const dynamicTableProps = {
    config,
    data: showSearchField ? items : sortedData,
    isReadOnly,
    keyExtractor,
    loading: loading || searchLoading,
    fetchMoreResults,
    setQueryFilters,
    queryFilters,
    setSearchFilters,
    searchFilters,
    handleOnSort,
    sortBy,
    sortDir,
    pageSize,
    setSelectedItems,
    selectedItems,
    handleSelect,
    rowHeight,
    widthFixedTable,
    widthDynamicTable,
    currentElements,
    selectAll,
    setSelectAll,
    resetSelector,
    handleSelectAll,
    selectCurrent,
    setSelectCurrent,
    handleSelectCurrent,
    totalElements,
    currentSelection,
    showSelectAllElementsText,
    enableSelectAllElements
  }

  const dynamicTableFiltersProps = {
    tableId,
    enableFilters,
    tableColumns: columns,
    queryFilter: queryFilters,
    handleChangeFilters,
    showStatusFilter,
    isAlternativeView
  }

  const dynamicTableSearchBarProps = {
    enableFilters,
    enableSearch: config.showSearchField,
    searchFilters,
    handleChangeSearch,
    initialValue: '',
    data: showSearchField ? items : data,
    config,
    error,
    selectedItems,
    isAlternativeView,
    fetchData,
    selectAll,
    selectCurrent
  }

  const activityIndicatorStyles = isSticky
    ? {
        zIndex: 2,
        position: 'absolute',
        width: '100%',
        bottom: 0
      }
    : {}

  return (
    <MainContainer>
      <DynamicTableHeaderComponents isAlternativeView={isAlternativeView}>
        <DynamicTableSearchBar {...dynamicTableSearchBarProps} />
        <DynamicTableFilters {...dynamicTableFiltersProps} />
      </DynamicTableHeaderComponents>
      <InnerTableContainer isSticky={isSticky}>
        <DynamicTableContainer isSticky={isSticky}>
          {error && (
            <DynamicTableText>{t('error:errorLoadingItems')}</DynamicTableText>
          )}
          {isSmallScreen ? (
            <DynamicTableComponent
              width={100}
              tableColumns={columns}
              leftTable
              {...dynamicTableProps}
            />
          ) : !fixedColumns?.length && !!dynamicColumns?.length ? (
            <DynamicTableComponent
              width={widthDynamicTable < 100 ? 100 : widthDynamicTable}
              tableColumns={dynamicColumns}
              leftTable={false}
              singleTable
              {...dynamicTableProps}
            />
          ) : (
            <>
              <DynamicTableComponent
                width={widthFixedTable}
                tableColumns={fixedColumns}
                leftTable
                {...dynamicTableProps}
              />
              {!isSticky ? (
                <TableScrollViewContainer width={widthScrollDynamicTable}>
                  <DynamicTableComponent
                    width={widthDynamicTable < 100 ? 100 : widthDynamicTable}
                    tableColumns={dynamicColumns}
                    leftTable={false}
                    {...dynamicTableProps}
                  />
                </TableScrollViewContainer>
              ) : null}
            </>
          )}
        </DynamicTableContainer>
        {loadingFetchMore || searchLoading ? (
          <LoadingIndicator style={activityIndicatorStyles as any} />
        ) : null}
        {showEmptyMessage ? (
          <Flex
            flexDirection="row"
            justifyContent="center"
            marginTop={20}
            width="100%"
          >
            <H4>{emptyMessage}</H4>
          </Flex>
        ) : showEmptyComponent ? (
          <EmptyComponent />
        ) : null}
      </InnerTableContainer>
      {!loading && showTotalElements && totalElements && currentElements ? (
        <TableMessageText
          style={{
            fontStyle: 'italic',
            marginTop: 5,
            textAlign: 'right'
          }}
        >{`${currentElements} of ${totalElements}`}</TableMessageText>
      ) : null}
    </MainContainer>
  )
}

const defaultKeyExtractor = (item: any, i: any) => `${item?.id}_${i}`

// @ts-ignore
export default forwardRef(DynamicTable)
