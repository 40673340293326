import React, { FC } from 'react'
import styled from 'styled-components/native'
import { Flex } from '../FlexBox'
import { Text } from '../common/Text'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'
import GroupButtons from './FormButtons/GroupButtons'

export const Title = styled(Text)`
  ${({ theme: { colors, fontSizes, fontWeights, space }, rebrand }) => `
    color: ${colors.pageTitle};
    fontSize: ${fontSizes[7]}px;
    fontWeight: ${rebrand ? fontWeights.bold : fontWeights.light};
    margin-bottom: ${space[3]}px;
`}
`

interface ContainerProps {
  testID?: string
}

export const Container: FC<ContainerProps> = ({ children, testID }) => {
  const isSmallScreen = useIsSmallScreen()
  return (
    <Flex
      flexDirection={isSmallScreen ? 'column' : 'row'}
      flexWrap="wrap"
      justifyContent={'space-between'}
      w="100%"
      testID={testID}
    >
      {children}
    </Flex>
  )
}

interface ButtonRowProps {
  buttons: ButtonConfig[]
  leftButton?: ButtonConfig
}

export interface ButtonConfig {
  title: string
  onPress: (item?: any) => any
  isProcessing?: boolean
  isDisabled?: boolean
  type?: 'solid' | 'clear' | 'outline'
}

export const ButtonRow: FC<ButtonRowProps> = ({ buttons, leftButton }) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <Flex
      flexDirection={isSmallScreen ? 'column' : 'row'}
      flexWrap="wrap"
      justifyContent="flex-end"
      w="100%"
      px={isSmallScreen ? '15px' : '40px'}
      mb="30px"
    >
      <GroupButtons buttons={buttons} leftButton={leftButton} />
    </Flex>
  )
}
