import React, { useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components/native'
import * as Yup from 'yup'
import { TouchableOpacity, View } from 'react-native'

import useTranslation from '../../../../../../../hooks/useTranslation'
import {
  QuestionContentProps,
  QuestionsAndGroupsProps
} from '../../../../types'
import { Text } from '../../../../../../../components/common/Text'
import {
  JSONStyles,
  Question,
  QuestionGroupConfigData,
  QuestionGroupEntityMap
} from '../../../../../../../components/DynamicForm/types'
import AnimatedComponent from '../../../../../../../components/common/AnimatedComponent'
import capitalize from '../../../../../../../utils/capitalize'
import Dropdown, {
  DropdownOption
} from '../../../../../../../components/common/Dropdown'
import { Footer, SaveButton } from '../../../../../constants'
import useGetQuestionGroupEntityMaps from '../../../Forms/hooks/useGetQuestionGroupEntityMaps'
import { EntityTypeEnum } from '../../../../../../../types/form'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'ramda'
import useUpsertQuestionGroupEntityMapAndEntity from '../../../Forms/hooks/useUpsertQuestionGroupEntityMap'
import useToast from '../../../../../../../hooks/useToast'
import InputField from '../../../InputField'
import useGetQuestions from '../../../Questions/hooks/useGetQuestions'

const questionFields = [
  {
    field: 'sequence',
    component: InputField
  },
  {
    field: 'dynamic',
    component: InputField
  },
  {
    field: 'style',
    component: InputField,
    isJSON: true
  },
  {
    field: 'configData',
    component: InputField,
    isJSON: true
  }
]

// Yup validation
const getValidationSchema = () =>
  Yup.object().shape({
    sequence: Yup.number().default(0).required()
  })

const QuestionContent = (props: QuestionContentProps) => {
  const { t } = useTranslation()
  const { setToastMessage, setToastErrorMessage } = useToast()
  const { question } = props

  const [questionEntityType, setQuestionEntityType] = useState<EntityTypeEnum>(
    EntityTypeEnum.Question
  )

  const [parentQuestion, setParentQuestion] = useState<Question | undefined>()

  useEffect(() => {
    setParentQuestion(undefined)
  }, [questionEntityType])

  const { loading: fetchLoading, questions = [] } = useGetQuestions(
    true,
    undefined,
    undefined,
    { skip: questionEntityType !== EntityTypeEnum.Question }
  )

  const questionsOptions: DropdownOption[] = questions.map(q => ({
    label: q.questionText,
    value: q.id
  }))

  const questionEntityTypes = Object.keys(EntityTypeEnum)
  const questionEntityTypesValues = Object.values(EntityTypeEnum)

  const questionEntityTypesOptions: DropdownOption[] = questionEntityTypes.map(
    (typeKey, index) => ({
      label: typeKey,
      value: questionEntityTypesValues[index]
    })
  )

  const [
    upsertQuestionGroupEntityMapAndEntity,
    isLoading
  ] = useUpsertQuestionGroupEntityMapAndEntity({
    onError: e => console.error(e),
    onCompleted: ({ upsertQuestionGroupEntityMapAndEntity }) => {
      if (
        upsertQuestionGroupEntityMapAndEntity &&
        !isEmpty(upsertQuestionGroupEntityMapAndEntity)
      ) {
        setToastMessage(
          t('platformManagement:forms:menuItems:forms:successSaved')
        )
      } else {
        setToastErrorMessage(
          t('platformManagement:forms:menuItems:forms:errorSave')
        )
      }
    },
    errorMessage: t('platformManagement:questionGroups:errorSave')
  })

  const currentQuestion = parentQuestion
    ? {
        entity: parentQuestion,
        sequence: 0,
        dynamic: false,
        entityType: questionEntityType
      }
    : question

  const isNew = !question?.id

  const questionKeys = [
    ...Object.keys(currentQuestion),
    ...Object.keys(currentQuestion.entity ?? {})
  ]

  let initialValues: any = {}

  for (
    let indexQuestionKey = 0;
    indexQuestionKey < questionKeys.length;
    indexQuestionKey++
  ) {
    const questionKey = questionKeys[indexQuestionKey]
    if (
      ['id', ...questionFields.map(qField => qField.field)].includes(
        questionKey
      )
    ) {
      const currentValue =
        currentQuestion[questionKey] ?? currentQuestion?.entity?.[questionKey]

      if (typeof currentValue === 'object' && currentValue != null) {
        initialValues[questionKey] = JSON.stringify(currentValue)
      } else {
        initialValues[questionKey] = currentValue ?? undefined
      }
    }
  }

  // Formik
  const form = useFormik<{
    id: string
    sequence: string
    dynamic: boolean | string
    style: JSONStyles
    configData: QuestionGroupConfigData
  }>({
    initialValues,
    validationSchema: getValidationSchema(),
    enableReinitialize: true,
    onSubmit: async values => {
      upsertQuestionGroupEntityMapAndEntity({
        variables: {
          id: isNew ? undefined : question.id,
          entityType: isNew ? questionEntityType : question.entityType,
          sequence: parseInt(values.sequence),
          dynamic:
            typeof values.dynamic === 'string'
              ? values.dynamic === 'true'
              : values.dynamic,
          style: values.style,
          configData: values.configData,
          questionGroupId: question.questionGroupId,
          parentId: isNew && parentQuestion ? parentQuestion.id : undefined
        },
        refetchQueries: ['getQuestionGroupEntityMaps']
      })
    }
  })

  const validateAndSubmit = () => {
    form.validateForm().then(() => form.submitForm())
  }

  const enableSubmit = useMemo(() => {
    return form.isValid && form.dirty
  }, [form])

  return (
    <View style={{ flex: 1 }}>
      <FormikProvider value={form}>
        {isNew ? (
          <View style={{ flexDirection: 'row' }}>
            <Dropdown
              closeMenuOnSelect
              style={{
                minHeight: 0,
                marginBottom: 0
              }}
              value={questionEntityType ?? null}
              containerStyle={{ width: 150, height: 50, marginRight: 10 }}
              isLoading={isLoading}
              isDisabled={isLoading}
              options={questionEntityTypesOptions}
              controlStyle={{ minHeight: 0 }}
              menuPortalTarget={document?.body}
              onSelect={value => setQuestionEntityType(value)}
              name={'parentQuestionTypesSelector'}
              placeholder={t(
                'platformManagement:forms:menuItems:questions:selectorPlaceholder'
              )}
            />
            <Dropdown
              isClearable
              closeMenuOnSelect
              style={{
                minHeight: 0,
                marginBottom: 0
              }}
              value={parentQuestion?.id ?? null}
              containerStyle={{ width: 150, height: 50, marginRight: 10 }}
              isLoading={fetchLoading}
              isDisabled={fetchLoading}
              options={questionsOptions}
              controlStyle={{ minHeight: 0 }}
              menuPortalTarget={document?.body}
              onSelect={value => {
                const currentQuestion = questions.find(q => q.id === value)
                setParentQuestion(currentQuestion)
              }}
              name={'parentQuestionTypesSelector'}
              placeholder={t(
                'platformManagement:forms:menuItems:questions:selectorPlaceholder'
              )}
            />
          </View>
        ) : null}
        {questionFields.map(({ field }) => (
          <InputField
            type="forms"
            key={field}
            fieldName={field}
            isReadOnly={false}
          />
        ))}
        <Footer>
          <SaveButton
            disabled={!enableSubmit}
            onPress={validateAndSubmit}
            isProcessing={isLoading}
            title={t(`common:buttons:update`)}
          />
        </Footer>
      </FormikProvider>
    </View>
  )
}

const QuestionsAndGroups = (props: QuestionsAndGroupsProps) => {
  const { colors } = useTheme()
  const { question } = props
  const { questionGroupEntityMaps = [] } = useGetQuestionGroupEntityMaps(
    false,
    undefined,
    question.id,
    undefined
  )

  const [currentQuestion, setCurrentQuestion] = useState<
    QuestionGroupEntityMap | undefined
  >(questionGroupEntityMaps?.[0])

  return (
    <View
      style={{
        marginTop: 5,
        width: '100%',
        height: 500,
        flexDirection: 'row'
      }}
    >
      <View
        style={{
          width: '40%',
          height: '100%',
          borderColor: colors.tints.grays.g100,
          borderRightWidth: 1
        }}
      >
        {questionGroupEntityMaps.map(qgem => (
          <TouchableOpacity
            key={qgem.id}
            style={{
              width: '100%',
              marginBottom: 5,
              backgroundColor:
                qgem.id === currentQuestion?.id
                  ? colors.tableRowHover
                  : 'transparent'
            }}
            onPress={() => setCurrentQuestion(qgem)}
          >
            <AnimatedComponent
              hoverOpacity
              hoverColor={colors.tableRowHover}
              style={{
                width: '100%',
                minHeight: 40,
                marginVertical: 5,
                justifyContent: 'center',
                borderColor: colors.highlight,
                borderBottomWidth: 1.5
              }}
            >
              <Text styles={{ textAlign: 'center' }}>
                {`${qgem.sequence} - ${
                  qgem.entityType === EntityTypeEnum.QuestionGroup
                    ? qgem.entity.configData?.title ??
                      qgem.entity.configData?.blockLabel
                    : capitalize(qgem.entity?.questionText)
                }`}
              </Text>
            </AnimatedComponent>
          </TouchableOpacity>
        ))}
        <TouchableOpacity
          key={'addNewQuestionGroupEntityMapId'}
          style={{
            borderRadius: 50,
            width: '100%',
            marginTop: 20,
            backgroundColor: colors.cta.primary
          }}
          onPress={() =>
            setCurrentQuestion({
              sequence: 0,
              dynamic: false,
              style: {},
              configData: {},
              questionGroupId: question.id
            } as any)
          }
        >
          <AnimatedComponent
            hoverOpacity
            hoverColor={colors.tableRowHover}
            style={{
              width: '100%',
              height: 40,
              justifyContent: 'center'
            }}
          >
            <Text
              styles={{ textAlign: 'center', color: 'white' }}
            >{`Add New +`}</Text>
          </AnimatedComponent>
        </TouchableOpacity>
      </View>
      <View
        style={{
          width: '60%',
          height: '100%',
          paddingHorizontal: 10
        }}
      >
        {currentQuestion ? (
          <QuestionContent question={currentQuestion} />
        ) : null}
      </View>
    </View>
  )
}

export default QuestionsAndGroups
