import React, { FC, useCallback, useEffect, useState } from 'react'

import useTranslation from '../../hooks/useTranslation'
import useIsSmallScreen from '../../hooks/useIsSmallScreen'

import { ScreenContainerWithMenuHeader } from '../../components/layout/ScreenContainer'

import {
  ActionContainer,
  ScrollViewContent,
  SectionContainer,
  StyledViewContainer,
  TeamMembersView,
  Title as StyledTitle,
  UsersListContainer
} from './components/SharedStyledComponents'
import Button from '../../ui-library/Button'
import Icon from '../../components/icon'
import InviteDialog from './components/InviteDialog'

import useCompanyUsersQuery from './hooks/useCompanyUsersQuery'
import { ListMembersSectionProps, InviteUserProps } from './constants'
import { useTheme } from 'styled-components/native'
import { Text } from '../../components/common/Text'
import LoadingScreen from '../LoadingScreen'
import UserList from './components/UsersList'
import UserListHeader from './components/UserListHeader'
import { User } from '../../types'
import useCompanyQuery from '../../hooks/useCompanyQuery'
import { useIsFocused, useRoute } from '@react-navigation/native'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import { Title } from '../CallForSubmission/components/SharedComponents'
import { QueryHookOptions } from '@apollo/client'
import useCurrentUser, { CurrentUserHook } from '../../hooks/useCurrentUser'
import useFeatureFlag from '../../hooks/useFeatureFlag'

const InviteUserContainer: FC<InviteUserProps> = ({
  innovationCompany,
  containerTestId
}) => {
  const { t } = useTranslation()

  const [openDialog, setOpenDialog] = useState(false)

  const icon = <Icon name="addUser" width={16} height={16} />

  return innovationCompany?.canEdit ? (
    <>
      <ActionContainer testID={containerTestId}>
        <Button
          testID="invite_user_btn"
          type="solid"
          onPress={() => {
            setOpenDialog(true)
          }}
          title={t('settings:members:label:invite')}
          icon={icon}
        />
      </ActionContainer>

      <InviteDialog
        testID={'inviteModal'}
        company={innovationCompany}
        title={t('settings:members:label:inviteTeamMembers')}
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </>
  ) : null
}

const ListMembersSection = ({
  innovationCompany,
  users = [],
  titleKey,
  emptyLabelKey,
  style = {},
  isPending = false,
  loading,
  testID: containerTestID
}: ListMembersSectionProps) => {
  const { t } = useTranslation()
  const { sizes } = useTheme()
  const [[openDialog, formUser], setOpenDialog] = useState<
    [boolean, User | undefined]
  >([false, undefined])
  const { currentUserId }: CurrentUserHook = useCurrentUser()
  const rebrand = useFeatureFlag('innovationRebrandPlatform')

  useEffect(() => {
    if (formUser) {
      const updatedUser = users.find(u => u.id === formUser.id)

      if (updatedUser) {
        setOpenDialog([true, updatedUser])
      }
    }
  }, [formUser, users])

  const onEditToggle = useCallback(
    user => () => {
      setOpenDialog([true, user])
    },
    [setOpenDialog]
  )

  const canEditUser = (user): boolean =>
    innovationCompany?.canEdit || user.id === currentUserId

  const canDelete = (user): boolean => user.id !== currentUserId

  return (
    <SectionContainer testID={containerTestID} style={style}>
      <StyledTitle>{t(titleKey)}</StyledTitle>

      <UsersListContainer>
        {loading ? (
          <LoadingScreen
            style={{
              marginTop: sizes[5],
              marginBottom: sizes[5]
            }}
          />
        ) : users.length ? (
          <>
            {rebrand && <UserListHeader />}
            {users?.map((user, i) => (
              <UserList
                isPending={isPending}
                key={`pending-invite-${i}`}
                user={user}
                index={users.length - i}
                onEditToggle={onEditToggle}
                canEdit={canEditUser(user)}
                canDelete={canDelete(user)}
                i={i}
                testID={
                  isPending ? 'team-member-pending-invite' : 'team-member'
                }
              />
            ))}
          </>
        ) : (
          <Text styles={{ fontStyle: 'italic' }}>{t(emptyLabelKey)}</Text>
        )}
      </UsersListContainer>
      <InviteDialog
        user={formUser}
        company={innovationCompany}
        testID="edit_member_modal"
        title={t('settings:members:label:teamMembers')}
        isOpen={openDialog}
        isPending={isPending}
        onClose={() => setOpenDialog([false, undefined])}
      />
    </SectionContainer>
  )
}

const TeamMembers = () => {
  const { t } = useTranslation()
  const linkToScreen = useLinkToScreen()
  const route: any = useRoute()
  const companyId = route?.params?.companyId
  const isSmallScreen = useIsSmallScreen()
  const { space } = useTheme()

  const isFocused = useIsFocused()
  const commonOptions: QueryHookOptions<any, any> = {
    fetchPolicy: 'no-cache',
    skip: !companyId || !isFocused
  }
  const { company, loading: loadingCompany } = useCompanyQuery(
    companyId,
    commonOptions
  )
  const {
    users,
    pendingUsers,
    loading: loadingCompanyUsers
  } = useCompanyUsersQuery({ id: companyId }, commonOptions)

  return (
    <ScreenContainerWithMenuHeader screenCategory="home">
      <StyledViewContainer style={{ height: '100%' }}>
        <ScrollViewContent>
          {!loadingCompany && company ? (
            <Title
              text={`${t('innovatorDirectory:details:title')} - ${
                company.name
              }`}
              withBackArrow
              onBackArrow={() => {
                linkToScreen('InnovatorDirectoryProfile', {
                  companyId: companyId
                })
              }}
            />
          ) : null}
          <TeamMembersView padding={isSmallScreen ? space[3] : 44}>
            <ListMembersSection
              testID={'pending'}
              innovationCompany={company}
              titleKey={'settings:members:label:pendingInvites'}
              users={pendingUsers}
              emptyLabelKey={'settings:members:label:emptyPendingUsers'}
              loading={loadingCompanyUsers}
              isPending
            />
            <ListMembersSection
              testID={'teamMembers'}
              innovationCompany={company}
              style={{ zIndex: -1 }}
              titleKey={'settings:members:label:teamMembers'}
              users={users}
              emptyLabelKey={'settings:members:label:emptyUsers'}
              loading={loadingCompanyUsers}
            />
          </TeamMembersView>
        </ScrollViewContent>
        <InviteUserContainer
          containerTestId={'inviteBtnContainer'}
          innovationCompany={company}
        />
      </StyledViewContainer>
    </ScreenContainerWithMenuHeader>
  )
}

export default TeamMembers
