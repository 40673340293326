import React, { useEffect } from 'react'
import styled from 'styled-components/native'
import TextInput, { transformTextInputData } from './TextInput'
import {
  QuestionGroupTypeEnum,
  QuestionTypeEnum,
  EntityTypeEnum,
  QuestionGroup,
  Question,
  Entities,
  QuestionGroupEntityMap,
  PlatformRenderOptions,
  StepsRenderOptions,
  InnovationFormTypeRenderOptions
} from '../../types/form'
import CheckBox, { transformCheckBoxInputData } from './Checkbox'
import DropDown, { transformDropdownInputData } from './DropDown'
import Rating, { transformRatingData } from './Rating'
import Block from './groups/Block'
import DynamicBlock from './groups/DynamicBlock'
import Form from './groups/Form'
import FileDropzone, { transformFileDropzoneData } from './FileDropzone'
import MultiSelect, { transformMultiSelectData } from './MultiSelect'
import DateInput, { transformDateInputData } from './DateInput'
import TitleCard from '../../ui-library/TitleCard'
import { CallForSubmission } from '../../types'
import { AppIds } from '../../hooks/useMixpanel'
import useFeatureFlag from '../../hooks/useFeatureFlag'

let formGroupIndex = 0

const Description = styled.Text`
  ${({ theme: { colors, fontWeights, fontSizes, space } }) => `
    color: ${colors.text1};
    fontWeight: ${fontWeights.regular};
    fontSize: ${fontSizes[4]}px;
    margin-bottom: ${space[3]}px;
  `}
`

const Title = styled.Text`
  ${({ theme: { colors, fontWeights, fontSizes, space }, titleStyle }) => `
    color: ${titleStyle?.color || colors.text1};
    fontWeight: ${titleStyle?.fontWeight || fontWeights.bold};
    fontSize: ${titleStyle?.fontSize || fontSizes[4]}px;
    width: ${titleStyle?.width || 'auto'};
    margin-bottom: ${space[3]}px;
  `}
`

const textInputElementDict = {
  component: TextInput,
  getData: transformTextInputData
}

export const formGroupElementDict = {
  [QuestionGroupTypeEnum.Form]: Form,
  [QuestionGroupTypeEnum.Block]: Block,
  [QuestionGroupTypeEnum.DynamicBlock]: DynamicBlock,
  default: Block // Default to block for now
}

export const formElementDict = {
  [QuestionTypeEnum.Integer]: textInputElementDict,
  [QuestionTypeEnum.TextInput]: textInputElementDict,
  [QuestionTypeEnum.DateInput]: {
    component: DateInput,
    getData: transformDateInputData
  },
  [QuestionTypeEnum.Textarea]: textInputElementDict,
  [QuestionTypeEnum.Checkbox]: {
    component: CheckBox,
    getData: transformCheckBoxInputData
  },
  [QuestionTypeEnum.Dropdown]: {
    component: DropDown,
    getData: transformDropdownInputData
  },
  [QuestionTypeEnum.Rating]: {
    component: Rating,
    getData: transformRatingData
  },
  [QuestionTypeEnum.FileDropzone]: {
    component: FileDropzone,
    getData: transformFileDropzoneData
  },
  [QuestionTypeEnum.MultiSelect]: {
    component: MultiSelect,
    getData: transformMultiSelectData
  },
  default: null
}

const getFormElementFromType = type => {
  return formElementDict[type] ? formElementDict[type] : formElementDict.default
}

interface ComponentProps {
  questionGroup: QuestionGroup | null
  questions: Entities[] | null
  formSubmissionId: string | null
  isReadOnly?: boolean
  asPrintable?: boolean
  style?: any
  groupIndex: number
  rebrand?: boolean
}

const createFormGroupElement = (
  item: QuestionGroup,
  isReadOnly: boolean,
  asPrintable: boolean,
  formSubmissionId: string,
  formQuestions: Question[],
  callForSubmission?: CallForSubmission,
  groupIndex = 0,
  callbackAfterAction?: () => void,
  isInnovatorProduct?: boolean,
  rebrand?: boolean
) => {
  let shouldHide = false
  const type = item.type
  const dynamic = item.dynamic
  const Component =
    formGroupElementDict[dynamic ? QuestionGroupTypeEnum.DynamicBlock : type] ||
    formGroupElementDict['default']

  const currentStep = callForSubmission?.currentStep
  const configData = item?.configData
  const renderOptions = configData?.renderOptions ?? {}
  const platformRenderOptions = (renderOptions[AppIds.Innovation] ??
    {}) as PlatformRenderOptions
  const platformRenderSteps = (platformRenderOptions?.steps ??
    []) as StepsRenderOptions[]
  const platformRenderFormType = (platformRenderOptions?.innovationFormTypes ??
    []) as InnovationFormTypeRenderOptions[]

  const formTypeRenderOptionsIndex =
    platformRenderFormType.findIndex(ft => ft.formType === 'product') ?? -1

  const stepRenderOptionsIndex =
    platformRenderSteps.findIndex(sp => sp.name === currentStep?.name) ?? -1

  if (stepRenderOptionsIndex > -1) {
    shouldHide =
      platformRenderSteps[stepRenderOptionsIndex]?.shouldHide ?? false
  }

  if (formTypeRenderOptionsIndex > -1 && isInnovatorProduct && !shouldHide) {
    shouldHide =
      platformRenderFormType[formTypeRenderOptionsIndex]?.shouldHide ?? false
  }

  const props: ComponentProps = {
    questionGroup: null,
    questions: null,
    formSubmissionId: null,
    isReadOnly,
    asPrintable,
    style: item?.style,
    groupIndex,
    rebrand
  }

  if (dynamic) {
    props.questions = item.questionGroupEntityMaps.map(entity => entity.entity)
    props.formSubmissionId = formSubmissionId
    props.questionGroup = item
  }

  const getQuestion = (entity: QuestionGroupEntityMap) => {
    const question = formQuestions.find(q => q.id === entity.entity.id)

    return (dynamic
      ? {
          ...question,
          style: {
            ...(question?.style || {}),
            styles: {
              ...((question?.style?.styles as any) || {}),
              backgroundColor: 'white'
            }
          },
          isDynamicQuestion: true
        }
      : question) as Question
  }

  const Wrapper = ({ children }) => {
    const rebrand = useFeatureFlag('innovationRebrandPlatform')
    const { title = null, capitalized = true, titleStyle = undefined } =
      item?.configData || {}
    const testID =
      title === 'Presenters'
        ? 'presentersInfo'
        : title === 'Main Point Of Contact'
        ? 'mainPOC'
        : title === 'PRODUCT DETAILS'
        ? 'productDetails'
        : title === 'SUBMISSION QUESTIONS'
        ? 'submissionQuestions'
        : title === 'IMPACT QUESTIONS'
        ? 'impactQuestions'
        : title === 'Traction Statement'
        ? 'tractionStatement'
        : title === 'Clients'
        ? 'clients'
        : title === 'Funding'
        ? 'funding'
        : title === 'Competitors'
        ? 'competitors'
        : title === 'Pricing'
        ? 'pricing'
        : title === 'Technology & Information Security'
        ? 'techInfo'
        : title === 'Geographics'
        ? 'geographics'
        : null

    return title ? (
      <TitleCard
        testID={testID}
        title={title}
        titleStyle={titleStyle}
        capitalized={capitalized}
        zIndexLayer={groupIndex}
        asPrintable={asPrintable}
        isDynamic={dynamic}
        rebrand={rebrand}
      >
        {children}
      </TitleCard>
    ) : (
      children
    )
  }

  const { description = null } = item?.configData || {}
  const { title = null, capitalized = true, titleStyle = undefined } =
    item?.configData || {}

  const rebrandTitleStyle = rebrand
    ? { ...titleStyle, width: '100%' }
    : titleStyle

  return !shouldHide ? (
    <Wrapper key={`dynamic-formGroup-${item.id}`}>
      <Component key={`dynamic-${item.id}`} {...props}>
        {title && rebrand ? (
          <Title titleStyle={rebrandTitleStyle}>
            {capitalized ? title.toUpperCase() : title}
          </Title>
        ) : null}
        {description ? <Description>{description}</Description> : null}

        {item.questionGroupEntityMaps.map(entity => {
          if (entity.entityType === EntityTypeEnum.Question) {
            const question = getQuestion(entity)

            return createFormElement(
              question,
              isReadOnly,
              asPrintable,
              formSubmissionId,
              callForSubmission,
              callbackAfterAction,
              isInnovatorProduct
            )
          } else if (entity.entityType === EntityTypeEnum.QuestionGroup) {
            formGroupIndex++
            return createFormGroupElement(
              entity.entity as QuestionGroup,
              isReadOnly,
              asPrintable,
              formSubmissionId,
              formQuestions,
              callForSubmission,
              formGroupIndex,
              callbackAfterAction,
              isInnovatorProduct,
              rebrand
            )
          }
        })}
      </Component>
    </Wrapper>
  ) : null
}

type FormElement = Question
const createFormElement = (
  item: FormElement,
  isReadOnly: boolean,
  asPrintable: boolean,
  formSubmissionId: string,
  callForSubmission?: CallForSubmission,
  callbackAfterAction?: () => void,
  isInnovatorProduct?: boolean
) => {
  const question = item as Question
  const type = question?.type
  const configData = question?.configData
  let shouldHide = false
  const { component: Component, getData } = getFormElementFromType(type)

  if (
    configData?.renderOptions?.[AppIds.Innovation]?.innovationFormTypes &&
    isInnovatorProduct
  ) {
    const renderOptionsIndex = configData?.renderOptions?.[
      AppIds.Innovation
    ]?.innovationFormTypes?.findIndex(ift => ift.formType === 'product')
    shouldHide =
      configData?.renderOptions?.[AppIds.Innovation]?.innovationFormTypes[
        renderOptionsIndex
      ]?.shouldHide ?? false
  }

  return (
    Component &&
    !shouldHide && (
      <Component
        key={`dynamic-${item.id}`}
        data={getData(item, formSubmissionId, callForSubmission)}
        isReadOnly={isReadOnly}
        asPrintable={asPrintable}
        callbackAfterAction={callbackAfterAction}
      />
    )
  )
}

interface DynamicFormProps {
  formGroup: QuestionGroup
  isReadOnly?: boolean
  asPrintable?: boolean
  formSubmissionId: string
  callForSubmission?: CallForSubmission
  formQuestions: Question[]
  callbackAfterAction?: () => void
  isInnovatorProduct?: boolean
}

const DynamicForm = ({
  formGroup,
  isReadOnly = false,
  asPrintable = false,
  formSubmissionId,
  formQuestions,
  callForSubmission,
  callbackAfterAction,
  isInnovatorProduct
}: DynamicFormProps) => {
  useEffect(() => {
    formGroupIndex = 0
  }, [formGroup])

  const rebrand = useFeatureFlag('innovationRebrandPlatform')
  const formComponents = createFormGroupElement(
    formGroup,
    isReadOnly,
    asPrintable,
    formSubmissionId,
    formQuestions,
    callForSubmission,
    undefined,
    callbackAfterAction,
    isInnovatorProduct,
    rebrand
  )

  return <>{formComponents}</>
}

export default DynamicForm
