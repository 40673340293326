import React, { useEffect, useState, useRef } from 'react'
import { Text } from './Text'
import { View } from 'react-native'
import format from 'date-fns/format'
import subMonths from 'date-fns/subMonths'
import styled from 'styled-components/native'
import { CallForSubmission } from '../../types'
import useTranslation from '../../hooks/useTranslation'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInMonths from 'date-fns/differenceInMonths'

export interface SubmissionClosingInfoProps {
  cfs?: CallForSubmission
  onCountdownStop?: () => void
  keepCountdown?: boolean
}

const Container = styled(View)`
  ${({ theme }) => `
  padding-bottom: ${theme.space[1]};
  padding-top: ${theme.space[1]};
  `}
`

const CenteredText = styled(Text)`
  text-align: center;
  ${({ theme }) => `
  margin-bottom: ${theme.space[1]};
  margin-top: ${theme.space[1]};
  color: ${theme.colors.text2};
  `}
`

const RemainingTimeText = styled(CenteredText)`
  color: ${({ theme }) => theme.colors.danger};
`

const DangerBoldText = styled(CenteredText)`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: bold;
`

export function getRemainingTimeString([
  months,
  days,
  hours,
  minutes,
  seconds
]): string {
  let remainingText = ''
  if (months) {
    remainingText += months + ' months '
    remainingText += days + 'd'
  } else if (days) {
    remainingText += days + 'd '
    remainingText += hours + 'h'
  } else if (hours) {
    remainingText += hours + 'h '
    remainingText += minutes + 'm'
  } else {
    remainingText += minutes + 'm '
    remainingText += seconds + 's'
  }

  return remainingText
}

export const getSubmissionClosingDateText = function (
  closingDateString
): string {
  const closingDate = new Date(closingDateString)
  // Example: '01 Sep 24 5:00 GMT-8'
  return format(closingDate, 'dd MMM yy h:mm O')
}

export const useCountdown = (targetDateString, onCountdownStop?) => {
  const countDownDate = new Date(targetDateString).getTime()

  // make sure no negative countdown
  const initialCountdown = countDownDate - new Date().getTime()
  const [countDown, setCountDown] = useState(
    initialCountdown > 0 ? initialCountdown : 0
  )

  useEffect(() => {
    const interval = setInterval(() => {
      const newCountdown = countDownDate - new Date().getTime()
      if (newCountdown > 0) {
        setCountDown(newCountdown)
      } else {
        // if time is negative stop cloc and call callback
        clearInterval(interval)
        if (onCountdownStop) {
          onCountdownStop()
        }
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown, targetDateString)
}

const getReturnValues = (
  countDown,
  targetDateString
): [number, number, number, number, number] => {
  // avoid showing negative values
  if (countDown <= 1000 || !targetDateString) {
    return [0, 0, 0, 0, 0]
  }
  // calculate time left
  const months = differenceInMonths(new Date(targetDateString), new Date())
  const days = differenceInDays(
    subMonths(new Date(targetDateString), months),
    new Date()
  )
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return [months, days, hours, minutes, seconds]
}

export const ShowRemainingTime = ({ targetDate, onCountdownStop }) => {
  const { t } = useTranslation()
  const timeDeltas = useCountdown(targetDate, onCountdownStop)
  const [months, days, hours, minutes, seconds] = timeDeltas
  const remainingText = getRemainingTimeString(timeDeltas)
  const isSubmissionWindowClosed =
    months === 0 && days === 0 && hours === 0 && minutes === 0 && seconds === 0

  return (
    <>
      {isSubmissionWindowClosed && (
        <DangerBoldText>
          {t('submissions:countdownClock:closed')}
        </DangerBoldText>
      )}
      <RemainingTimeText>
        {t('submissions:countdownClock:remainingTime', {
          remainingTime: remainingText
        })}
      </RemainingTimeText>
    </>
  )
}

export const SubmissionClosingInfo = ({
  cfs,
  onCountdownStop,
  keepCountdown
}: SubmissionClosingInfoProps) => {
  const { t } = useTranslation()
  const submissionClosedAt = useRef('')
  if (!cfs) {
    return null
  }
  const currentStep = cfs.currentStep
  const isInSubmissionWindow = currentStep?.name === 'submission'
  // save submission windown end date in a ref to keep the value in memory once the current step switches to preCurate
  if (isInSubmissionWindow) {
    submissionClosedAt.current = currentStep.closeAt as string
  }
  // If is not in submission window hide component, or keep it if keepCountdown flag is ON
  if (!isInSubmissionWindow && !keepCountdown) {
    return null
  }
  // If we can't obtain closing info don't show component
  if (!submissionClosedAt.current) {
    return null
  }
  const closingDate = getSubmissionClosingDateText(submissionClosedAt.current)
  return (
    <Container style={{ justifyContent: 'center' }}>
      <CenteredText>{t('submissions:countdownClock:titleLabel')}</CenteredText>
      <CenteredText>{closingDate}</CenteredText>
      <ShowRemainingTime
        targetDate={submissionClosedAt.current}
        onCountdownStop={onCountdownStop}
      />
    </Container>
  )
}
