import React, { FC, useMemo, useState } from 'react'
import { View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

import {
  DEFAULT_TOAST_MESSAGE,
  Message
} from '../../../../recoil/toastMessageAtom'
import CheckBox from '../../../../ui-library/CheckBox'
import useTranslation from '../../../../hooks/useTranslation'
import Modal from '../../../common/Modal'
import { ModalTitle } from './SharedStyledComponents'
import TouchableIcon from '../../../icon/TouchableIcon'
import Button from '../../../../ui-library/Button'
import ModalToast from './ModalToast'

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`

interface ModalDialogProps {
  title: string
  isOpen: boolean
  onClose: () => void
  handleSubmit: (types: any) => void
  children?: JSX.Element | JSX.Element[]
  csvTypesMap: any[]
  isProcessing: boolean
}

const ModalDialog: FC<ModalDialogProps> = ({
  title,
  isOpen,
  onClose,
  handleSubmit,
  csvTypesMap,
  isProcessing
}) => {
  const { t } = useTranslation()
  const { colors, space } = useTheme()
  const [values, setValues] = useState<{}>(
    csvTypesMap.reduce(
      (acc, csvType) => ({ ...acc, [csvType.name]: false }),
      {}
    )
  )

  const ALL_CSV_TYPES = [...new Set(csvTypesMap)]

  const [toastMessage, setToastMessage] = useState<Message | null>(null)

  const handleClose = () => {
    setToastMessage(DEFAULT_TOAST_MESSAGE)
    onClose && onClose()
  }

  const isDisabled = useMemo(() => {
    return !Object.values(values).filter(value => value).length
  }, [values])

  const buttonPadding = {
    vertical: space[1] + space[2],
    horizontal: space[2] + space[3]
  }

  const btnContainerStyles = {
    height: space[4],
    marginTop: space[1] + space[3]
  }
  const btnStyles = {
    width: 'fit-content',
    paddingTop: buttonPadding.vertical,
    paddingBottom: buttonPadding.vertical,
    paddingLeft: buttonPadding.horizontal,
    paddingRight: buttonPadding.horizontal,
    height: space[4]
  }

  return (
    <Modal
      close={handleClose}
      open={isOpen}
      styles={{
        minWidth: '450px',
        padding: '15px 24px'
      }}
    >
      <ModalTitle>{title}</ModalTitle>
      <CloseIcon onPress={handleClose} />

      {toastMessage?.message && <ModalToast message={toastMessage} />}
      <View style={{ marginTop: space[3] }}>
        {ALL_CSV_TYPES.map(({ name }, i) => (
          <CheckBox
            key={`export-csv-type-${name}-${i}`}
            label={t(`settings:exportCSV:${name}`)}
            checked={values[name]}
            onPress={() => setValues({ ...values, [name]: !values[name] })}
          />
        ))}
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: space[3],
          justifyContent: 'flex-end'
        }}
      >
        <Button
          title={t('settings:members:form:cancel')}
          type="outline"
          buttonStyle={{
            ...btnStyles,
            borderColor: colors.buttonVariant
          }}
          titleStyle={{
            color: colors.buttonVariant
          }}
          containerStyle={{
            ...btnContainerStyles,
            marginRight: space[3]
          }}
          onPress={() => {
            handleClose()
          }}
        />
        <Button
          title={isProcessing ? '' : t('common:table:exportCSV')}
          buttonStyle={btnStyles}
          containerStyle={btnContainerStyles}
          onPress={() => handleSubmit(values)}
          disabled={isDisabled}
          isProcessing={isProcessing}
        />
      </View>
    </Modal>
  )
}

export default ModalDialog
