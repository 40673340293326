import React, { useEffect, useState } from 'react'
import { withTheme } from 'styled-components/native'
import TextInput from '../../ui-library/TextInput'

const CustomTextInput = props => {
  const { editable = true } = props
  const commonStyles = {
    ...props.style,
    outlineStyle: 'solid'
  }
  const [labelStyles, setLabelStyles] = useState(props.labelStyles)
  const [inputStyle, setInputStyle] = useState({
    ...commonStyles,
    borderWidth: 0,
    outlineColor: !props.editable && props.theme.colors.inputBorder
  })
  const onFocus = param => {
    if (props.onFocus) {
      props.onFocus(param)
    }
    if (!editable) return
    setLabelStyles({
      ...props.labelStyles,
      color: props.theme.colors.inputFocus
    })
    setInputStyle({
      ...commonStyles,
      borderWidth: 0,
      outlineColor: props.theme.colors.inputFocus
    })
  }

  const onBlur = param => {
    if (props.onBlur) {
      props.onBlur(param)
    }
    if (!editable) return
    setLabelStyles(props.labelStyles)
    setInputStyle({
      ...commonStyles,
      ...(props.hasError
        ? {
            outlineWidth: 0
          }
        : {
            borderWidth: 0,
            outlineColor: props.theme.colors.inputBorder
          })
    })
  }

  useEffect(() => {
    setInputStyle({
      ...commonStyles,
      outlineWidth: 0
    })
  }, [props.hasError])

  return (
    <TextInput
      {...props}
      onFocus={onFocus}
      onBlur={onBlur}
      labelStyles={labelStyles}
      style={inputStyle}
    />
  )
}

export default withTheme(CustomTextInput)
