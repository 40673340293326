import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Image } from 'react-native-web'
import styled, { useTheme } from 'styled-components/native'
import { View, ViewStyle, useWindowDimensions } from 'react-native'
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
// Hooks
import useTranslation from '../../../hooks/useTranslation'
// Components/Util
import Button from '../../../ui-library/Button'
import idToCloudinaryUrl from '../../../utils/idToCloudinaryUrl'
import { FileDropzoneFile } from '../../../components/common/FileDropzone'
// Types
import { acceptSignatures } from './types'

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  const minDimension = mediaWidth > mediaHeight ? 'height' : 'width'
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        [minDimension]: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

// Style components
const CropperContainer = styled.View`
  position: absolute;
  align-items: center;
  justify-content: center;
  ${({ theme: { colors }, height, width }) => `
    width: ${width}px;
    height: ${height}px;
    background-color: ${colors.overlay};
  `}
`

const ImagePreview = styled.Image`
  ${({ height, width }) => `
    width: ${width}px;
    height: ${height}px;
  `}
`

const StyledActivityIndicator = styled.ActivityIndicator.attrs(() => ({
  color: 'white',
  size: 'large'
}))`
  position: absolute;
`

const ProcessBar = styled.View`
  height: 5%;
  top: 0;
  left: 0;
  position: absolute;
  ${({ process, theme: { colors } }) => `
    width: ${process}%;
    background-color: ${colors.highlight}
  `}
`

const FooterContainer = styled.View`
  bottom: 0;
  width: 100%;
  height: 10vh;
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ theme: { colors, sizes } }) => `
    gap: ${sizes[3]}px;
    background-color: ${colors.appBackground};
  `};
`

const ProcessingError = styled.Text`
  color: white;
  max-width: 40%;
  align-self: center;
  text-align: center;
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[5]}px;
  `}
`

export interface CustomCrop extends Crop {
  aspect: number
}

export interface MediaI {
  file?: FileDropzoneFile
  cloudinaryId?: string
  uri?: string
}

interface ImageViewerProps {
  media?: MediaI
  isUploading?: boolean
  isUploadCompleted?: boolean
  cloudinaryProgress?: number
  onCancel?: () => void
  onSave?: (crop?: CustomCrop, isEditing?: boolean) => void
}

const ImageViewer: React.FC<ImageViewerProps> = ({
  media,
  isUploading,
  isUploadCompleted,
  cloudinaryProgress,
  onCancel,
  onSave
}) => {
  const aspect = 1
  const { t } = useTranslation()
  const { sizes, colors } = useTheme()
  const { height: windowHeight, width: windowWidth } = useWindowDimensions()

  const [crop, setCrop] = useState<Crop>()
  const [canvasDimension, setCanvasDimensions] = useState<{
    w: number
    h: number
  }>()
  const [error, setError] = useState<string | null>(null)
  const [loadingImage, setLoadingImage] = useState(true)

  const onSubmit = useCallback(() => {
    onSave?.({ ...(crop as Crop), aspect })
  }, [crop, aspect])

  const mediaSource = media?.cloudinaryId
    ? idToCloudinaryUrl(media.cloudinaryId)
    : media?.uri || null

  const onLoadImage = useCallback(() => {
    if (!mediaSource || !!error) return

    Image.getSize(mediaSource, (width, height) => {
      if (!width || !height || !!canvasDimension) return
      const maxHeight = windowHeight * 0.7
      const maxWidth = windowWidth * 0.7
      const possibleHeight = Math.min(maxHeight, height)
      const possibleWidth = Math.min(maxWidth, windowWidth)
      const heightRatio = possibleHeight / height
      const widthRatio = possibleWidth / width
      const minRatio = Math.min(heightRatio, widthRatio)
      const w = width * minRatio
      const h = height * minRatio

      setCanvasDimensions({ w, h })
      setCrop(centerAspectCrop(w, h, aspect))
      if (media?.uri) URL.revokeObjectURL(media.uri)
    })
  }, [mediaSource, error])

  const onLoadError = useCallback(event => {
    // A log left on purpose
    console.error({ event })
    setError(
      t('directoryProfile:companyLogoUploader:previewingCompanyLogoError', {
        types: acceptSignatures.join(', ')
      })
    )
    setLoadingImage(false)
  }, [])

  useEffect(() => {
    if (canvasDimension) {
      setLoadingImage(false)
    }
  }, [canvasDimension])

  const btnStyles: ViewStyle = {
    width: sizes[6],
    height: sizes[4] + sizes[2]
  }
  const btnContainerStyles: ViewStyle = { height: sizes[4] + sizes[2] }

  const currentProcess = useMemo(() => {
    return !isUploading
      ? 0
      : isUploadCompleted
      ? 100
      : cloudinaryProgress
      ? (cloudinaryProgress * 75) / 100
      : 0
  }, [isUploading, isUploadCompleted, cloudinaryProgress])

  return (
    <View>
      {media
        ? createPortal(
            <CropperContainer height={windowHeight} width={windowWidth}>
              {loadingImage && <StyledActivityIndicator />}
              {error && <ProcessingError>{error}</ProcessingError>}
              <ReactCrop
                crop={crop}
                aspect={aspect}
                style={{ alignSelf: 'center' }}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
              >
                <ImagePreview
                  source={
                    media?.cloudinaryId
                      ? idToCloudinaryUrl(media.cloudinaryId)
                      : media.uri
                  }
                  onLoad={onLoadImage}
                  onError={onLoadError}
                  width={canvasDimension?.w}
                  height={canvasDimension?.h}
                />
              </ReactCrop>
              <FooterContainer>
                {isUploading ? <ProcessBar process={currentProcess} /> : null}
                <Button
                  title={t('directoryProfile:button:cancelButton')}
                  type="outline"
                  buttonStyle={{
                    ...btnStyles,
                    borderColor: colors.buttonVariant
                  }}
                  titleStyle={{ color: colors.buttonVariant }}
                  containerStyle={btnContainerStyles}
                  onPress={onCancel}
                  disabled={isUploading}
                  isProcessing={isUploading}
                />
                <Button
                  title={t('directoryProfile:button:saveButton')}
                  buttonStyle={btnStyles}
                  containerStyle={btnContainerStyles}
                  onPress={onSubmit}
                  disabled={isUploading || !!error}
                  isProcessing={isUploading}
                />
              </FooterContainer>
            </CropperContainer>,
            document.body
          )
        : null}
    </View>
  )
}

export default ImageViewer
