import { ScrollView, Text, TouchableOpacity, View } from 'react-native'
import styled from 'styled-components/native'
import { Flex } from '../../../components/FlexBox'

export const ToastContainer = styled(Flex)`
  ${({ theme }) => `
    padding: ${theme.space[2]}px;
    border-radius: 3px;
    margin-bottom: ${theme.space[3]}px;
  `}
`
export const ToastText = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.primaryPalette.white};
    font-size: ${theme.sizes[3]}px;
  `}
`

export const UserListHeaderLabel = styled(Text)`
  ${({ theme }) => `
    width: fit-content;
    font-weight: ${theme.fontWeights.bold};
    overflow: auto;
    margin-right: ${theme.sizes[1]}px;
  `}
`

export const ScrollViewContent = styled(ScrollView)``

export const StyledViewContainer = styled(View)`
  ${({ theme: { space, colors, radii } }) => `
    padding: ${space[3]}px;
    background: ${colors.containerBackground};
    border-radius: ${radii[4]}px;
    box-shadow: #0000001a 0px 4px 5.65px;
  `}
`

export const Title = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.sizes[3] + theme.sizes[1]}px;
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: ${theme.sizes[2]};
  `}
`

export const TeamMembersView = styled(View)`
  flex: 1;
  ${({ padding }) => ` padding: ${padding || 0}px; `}
`

export const SectionContainer = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`
export const ActionContainer = styled(Flex)`
  ${({ theme }) => `
    margin: ${theme.space[1]}px ${theme.space[1]}px ${theme.space[1]}px
      ${theme.space[3]}px;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${theme.space[2]}px 0 ${theme.space[4]}px ${theme.space[4]}px;
  `}
`

export const UsersListContainer = styled(View)``

export const UserListContainer = styled(View)`
  max-width: 100%;
  display: flex;
  justify-content: center;
`
export const UserListView = styled(View)`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`

export const UserListContentVew = styled(View)`
  display: flex;
  flex-direction: row;
`
export const UserListTitleContent = styled(View)`
  display: flex;
  flex-direction: row;
  width: 92%;
`

export const UserListLabelContent = styled(View)`
  ${({ theme }) => `
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: ${theme.space[3]}px;
  `}
`

export const UserListRemoveButton = styled(TouchableOpacity)`
  justify-content: center;
  align-items: center;
  width: 4%;
  height: auto;
`

export const UserListEditButton = styled(TouchableOpacity)`
  justify-content: center;
  align-items: center;
  width: 4%;
  height: auto;
`
