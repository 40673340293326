import React, { Component } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import TextInput, { TextInputProps } from '../TextInput'
import { Text } from '../../components/common/Text'

export const Currency = styled(Text)`
  ${({ theme: { colors, fontSizes, fontWeights } }) => `
    color: ${colors.text2};
    fontSize: ${fontSizes[4]}px;
    fontWeight: ${fontWeights.light}px;
    position: absolute;
    top: 27px;
    left: 7px;
`}
`

interface CurrencyProps extends TextInputProps {
  symbol: string
  wrapperStyles?: any
  CustomInputComponent?: Component<TextInputProps>
}

const CurrencyInput = (props: CurrencyProps) => {
  const { symbol, style, wrapperStyles, CustomInputComponent } = props

  const styles = {
    // @ts-ignore
    ...(style || {}),
    ...{
      paddingLeft: 20,
      textAlign: 'right'
    }
  }
  const InputComponent = CustomInputComponent ?? TextInput

  return (
    <View style={wrapperStyles}>
      <InputComponent {...props} style={styles} />
      <Currency>{symbol}</Currency>
    </View>
  )
}

export default CurrencyInput
