import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import NewGradient from './NewGradient'
import useFeatureFlag from '../../../../hooks/useFeatureFlag'

const footerImage = require('../../../../../assets/images/footer.png')

const Container = styled(View)`
  ${({ width }) => `
    width: ${width || '100%'};
  `}
  flex: 1;
  height: 100%;
  background-color: #000;
  ${({ rebrand }) => `
    background-image: ${rebrand ? 'none' : `url(${footerImage})`}
  `}
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
`

export const InnovationBg = ({ children, width }: any) => {
  const rebrand = useFeatureFlag('innovationRebrandPlatform')
  return (
    <Container width={width} rebrand={rebrand}>
      <NewGradient>{children}</NewGradient>
    </Container>
  )
}

export default InnovationBg
